import { React, useState } from 'react'
import Logo from '../../../assests/onboarding/logo(1).svg'
import Logo1 from '../../../assests/logo-white.svg'
import pic_Step1 from '../../../assests/onboarding/step1.webp'
import pic_Step2 from '../../../assests/onboarding/step2.webp'
import Step2 from './step2'
import PropTypes from 'prop-types'

const Step4 = ({ setStep, setSecondAccount }) => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const handleValidation = () => {
    if (!email) {
      setEmailError('البريد الالكتروني مطلوب')
      return false // Return false to indicate validation failure
    } else {
      return true // Return true to indicate validation success
    }
  }

  const handleNextButtonClick = () => {
    const isValid = handleValidation()

    if (isValid) {
      // Save the email to the second account state
      setSecondAccount(prev => ({
        ...prev,
        email: email
      }))
      // Proceed to next step if validation is successful
      setStep(prev => prev + 1)
    }
  }
  return (
    <div className='flex flex-row h-full'>
      <div className='w-full lg:w-1/2 '>
        <div className='container py-6 lg:py-8 text-center inline-block lg:hidden'>
          <img
            alt='logo'
            loading='lazy'
            width='100'
            height='100'
            decoding='async'
            data-nimg='1'
            className='inline-flex'
            src={Logo}
            style={{ color: 'transparent' }}
          />
        </div>
        <div className='flex items-center justify-center px-4 lg:px-0 min-h-[80vh] lg:min-h-screen'>
          <div className='max-w-xl w-full flex justify-center'>
            <div className='rounded-lg text-card-foreground w-full shadow-none border-0 bg-inherit'>
              <div className='flex flex-col space-y-1.5 p-6'>
                <h3 className='text-2xl font-semibold leading-none tracking-tight'></h3>
                <h3 className='text-2xl font-semibold leading-none tracking-tight'></h3>
                <h3 className='text-2xl font-semibold leading-none tracking-tight'></h3>
                <h3 className='text-2xl font-semibold leading-none tracking-tight text-color2'>
                  أدخل البريد الإلكتروني
                </h3>
                <h3 className='text-2xl font-semibold leading-none tracking-tight'></h3>
                <h3 className='text-2xl font-semibold leading-none tracking-tight'></h3>
              </div>
              <div className='p-6 pt-0'>
                <form>
                  <div className='flex flex-col p-6 pt-0'>
                    <div className='space-y-2'>
                      <label
                        className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-color2'
                        htmlFor=':r5c:-form-item'>
                        أدخل البريد الإلكتروني
                      </label>
                      <input
                        className='flex h-12 w-full rounded-md border border-border bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 '
                        placeholder='sunshine28446@gmail.com'
                        id=':r5c:-form-item'
                        aria-describedby=':r5c:-form-item-description'
                        aria-invalid='false'
                        name='companyPosition'
                        onChange={e => {
                          setEmail(e.target.value)
                        }}
                      />
                    </div>
                    {emailError && <p className='text-red-700'>{emailError}</p>}
                  </div>
                  <div className='flex justify-center relative'>
                    <button
                      className='items-center justify-center rounded-full text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-teal-900 glow-teal h-12 px-10 py-4 md:py-6 absolute -left-10 -bottom-60 flex flex-row gap-2 text-white'
                      type='button'
                      onClick={() => setStep(prev => prev - 1)}>
                      العودة
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='w-5 h-5 text-white'>
                        <path d='m12 19-7-7 7-7'></path>
                        <path d='M19 12H5'></path>
                      </svg>
                    </button>
                    <button
                      className='inline-flex items-center justify-center md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-teal-900 glow-teal h-12 px-10 py-4 md:py-6 rounded-md relative text-lg w-4/5 text-white'
                      type='button'
                      onClick={handleNextButtonClick}>
                      {' '}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='absolute right-5 text-white'>
                        <path d='M5 12h14'></path>
                        <path d='m12 5 7 7-7 7'></path>
                      </svg>
                      التالي
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden lg:flex lg:w-1/2 min-h-screen bg-accent p-12 flex-col items-center relative'>
        <div>
          <img
            alt='logo'
            loading='lazy'
            width='100'
            height='100'
            decoding='async'
            data-nimg='1'
            src={Logo1}
            style={{ color: 'transparent' }}
          />
        </div>
        <div className='flex flex-col gap-2 items-end justify-center w-full h-4/5'>
          <div className='relative pl-[2vw]'>
            <img
              alt='logo'
              loading='lazy'
              width='400'
              height='400'
              decoding='async'
              data-nimg='1'
              className='z-20 relative w-[25vw]'
              src={pic_Step1}
              style={{ color: 'transparent', visibility: 'visible' }}
              data-xblocker='passed'
            />
            <img
              alt='logo'
              loading='lazy'
              width='400'
              height='400'
              decoding='async'
              data-nimg='1'
              className='absolute top-[10vw] left-[18vw] z-10 w-[25vw]'
              src={pic_Step2}
              style={{ color: 'transparent', visibility: 'visible' }}
              data-xblocker='passed'
            />
          </div>
        </div>
      </div>
    </div>
  )
}
Step4.propTypes = {
  setStep: PropTypes.func.isRequired,
  setSecondAccount: PropTypes.func.isRequired
}
export default Step4
