import React, { useState, useEffect } from 'react'

export const useAppDataStore = () => {
  const storeKey = 'openai-assistant-appdata'
  const objectid = localStorage.getItem('Userid')
  let fetchname = ''
  let name = ''
  let id = ''
  const data = localStorage.getItem(storeKey)
  if (data) {
    const parsedData = JSON.parse(data)
    name = parsedData.name
    id = parsedData.id
  }

  function setNameToLocalStorage(sname) {
    name = sname
    localStorage.setItem(storeKey, JSON.stringify({ name: sname, id }))
  }

  function setIdToLocalStorage(sid) {
    id = sid
    localStorage.setItem(storeKey, JSON.stringify({ name, id: sid }))
  }

  return { name, id, setNameToLocalStorage, setIdToLocalStorage }
}
