import React, { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Create a context
const UserContext = createContext()

// Create a provider component
const UserAccountProvider = ({ children }) => {
  const [hasUserAccount, setHasUserAccount] = useState(() => {
    return JSON.parse(localStorage.getItem('HasUserAccount')) || false
  }) // Default value is false

  useEffect(() => {
    localStorage.setItem('HasUserAccount', JSON.stringify(hasUserAccount))
  }, [hasUserAccount])

  return <UserContext.Provider value={{ hasUserAccount, setHasUserAccount }}>{children}</UserContext.Provider>
}
UserAccountProvider.propTypes = {
  children: PropTypes.node.isRequired
}
export { UserContext, UserAccountProvider }
