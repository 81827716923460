import { React, useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
const NavbarContext = createContext()

const NavbarProvider = ({ children }) => {
  const [navbarShow, setNavbarShow] = useState(false)
  return <NavbarContext.Provider value={{ navbarShow, setNavbarShow }}>{children}</NavbarContext.Provider>
}
export const useNavbar = () => {
  return useContext(NavbarContext)
}
NavbarProvider.propTypes = {
  children: PropTypes.node.isRequired
}
export { NavbarContext, NavbarProvider }
