import { useState, React, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Dashnavbar from '../../component/layout/dashnavbar'
import Logo_green from '../../assests/logo-green.svg'
import Workspace from '../../assests/workspace.svg'
import Laywer from '../../assests/Law_pictures/lawyer.png'
import lawyer from '../../assests/Law_pictures/download.png'
import Logo_primary from '../../assests/logo-primary.svg'
import { AuthContext } from '../../context/AuthContext'

const Asklawyer = () => {
  const navigate = useNavigate('')
  const [dashnavbarShow, setDashnavbarShow] = useState(false)
  const [isDash, setIsDash] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const toggleDash = () => {
    setIsDash(!isDash)
  }
  const { logout } = useContext(AuthContext)
  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }
  return (
    <div className='min-h-screen flex flex-row  bg-[#F6F6F6] md:overflow-hidden'>
      <div className=' hidden lg:flex flex-col justify-between   pt-8 pb-2 w-1/5 border-l border-gray-300 h-screen relative md:overflow-y-auto overflow-x-hidden scrollbar'>
        <div className='flex flex-col w-full items-center gap-6 px-2'>
          <button
            className='pb-5 self-center border-b w-full border-gray-300 flex place-content-center'
            onClick={() => {
              navigate('/')
            }}>
            <img
              alt='logo'
              loading='lazy'
              width='150'
              height='150'
              decoding='async'
              data-nimg='1'
              className='w-32'
              src={Logo_green}
              style={{ color: 'transparent', visibility: 'visible' }}
              data-xblocker='passed'
            />
          </button>
          <div className='flex flex-col gap-[2vh] items-start w-3/4 lg:w-full'>
            <button
              className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4'
              onClick={() => {
                navigate('/dashboard')
              }}>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-home'>
                  <path d='m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z'></path>
                  <polyline points='9 22 9 12 15 12 15 22'></polyline>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>الرئيسية</span>
            </button>
            <button
              className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4'
              onClick={() => {
                navigate('/search')
              }}>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-search'>
                  <circle cx='11' cy='11' r='8'></circle>
                  <path d='m21 21-4.3-4.3'></path>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>البحث </span>
            </button>
            <button
              className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4'
              onClick={() => {
                navigate('/adel')
              }}>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-bot'>
                  <path d='M12 8V4H8'></path>
                  <rect width='16' height='12' x='4' y='8' rx='2'></rect>
                  <path d='M2 14h2'></path>
                  <path d='M20 14h2'></path>
                  <path d='M15 13v2'></path>
                  <path d='M9 13v2'></path>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>اسأل عادل</span>
            </button>
            <button
              className='bg-accent shadow flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4'
              onClick={() => {
                navigate('/lawyer')
              }}>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-bot'>
                  <path d='M12 8V4H8'></path>
                  <rect width='16' height='12' x='4' y='8' rx='2'></rect>
                  <path d='M2 14h2'></path>
                  <path d='M20 14h2'></path>
                  <path d='M15 13v2'></path>
                  <path d='M9 13v2'></path>
                </svg>
              </span>
              <span className='text-white text-sm font-semibold whitespace-nowrap'>اطلب محاميا</span>
            </button>
            <button
              className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4'
              onClick={() => {
                navigate('/myfile')
              }}>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-file'>
                  <path d='M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z'></path>
                  <polyline points='14 2 14 8 20 8'></polyline>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>ملفاتي وتحديداتي</span>
            </button>
            <button
              className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4'
              onClick={() => {
                navigate('/comingsoon')
              }}>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-folder'>
                  <path d='M4 20h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.93a2 2 0 0 1-1.66-.9l-.82-1.2A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13c0 1.1.9 2 2 2Z'></path>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>الملفات القضائية</span>
            </button>
          </div>
          <div className='w-full border-t border-gray-300'></div>
          <div className='flex flex-col gap-2 w-full text-accent'>
            <div className='flex flex-row justify-between'>
              <div className='flex items-center gap-1 font-semibold'>
                <img alt='workspace-icon' loading='lazy' width='25' height='25' decoding='async' data-nimg='1' src={Workspace} style={{ color: 'transparent' }} />
                <font style={{ verticalAlign: 'inherit' }}>الافرقة</font>
              </div>
              <button
                type='button'
                className='inline-flex items-center justify-center text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none text-secondary focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-accent-foreground border-2 border-secondary border-accent-foreground bg-background h-9 rounded-md px-3 hover:bg-secondary hover:text-white'>
                <font style={{ verticalAlign: 'inherit' }}>اضافة</font>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-plus'>
                  <path d='M5 12h14'></path>
                  <path d='M12 5v14'></path>
                </svg>
              </button>
            </div>
            <ul className='list-disc flex flex-col py-2 gap-2 px-8'></ul>
          </div>
        </div>
        <div className='flex flex-col gap-2 items-center justify-center'>
          <div className='w-11/12 relative flex flex-col gap-2 bg-accent rounded-2xl p-4'>
            <img
              alt='circles'
              loading='lazy'
              width='500'
              height='500'
              decoding='async'
              data-nimg='1'
              className='absolute -right-16 z-0 h-36'
              src='/circles.svg'
              style={{ color: 'transparent', visibility: 'visible' }}
              data-xblocker='passed'
            />
            <div className='flex flex-col gap-2 text-white z-10'>
              <div className='rounded-md bg-white p-2 w-10 h-10'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='bg-accent rounded-full p-1'>
                  <path d='m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14'></path>
                </svg>
              </div>
              <div>
                <li className='text-right vertical-align-inherit list-none'>
                  <span className='align-middle'>اشتراكك الحالي:</span>
                </li>
                <span className='font-semibold'>مجاني</span>
              </div>
            </div>
            <div className='flex flex-col gap-2 w-full items-center justify-center z-10'>
              <button
                className='inline-flex items-center justify-center text-sm md:text-md h-12 px-10 py-4 md:py-6 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-white text-accent hover:bg-white/90 w-full font-semibold rounded-md'
                onClick={() => {
                  navigate('/pricing')
                }}>
                <font style={{ verticalAlign: 'inherit' }}>
                  <font style={{ verticalAlign: 'inherit' }}>الاشتراكات</font>
                </font>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>{dashnavbarShow && <Dashnavbar dashnavbarShow={dashnavbarShow} setDashnavbarShow={setDashnavbarShow} />}</div>
      <div className={`w-full h-screen flex flex-col gap-0 relative md:overflow-y-auto ${dashnavbarShow ? 'backdrop-blur-3xl' : ''}`}>
        <div dir='rtl' className='bg-accent lg:bg-transparent px-4 py-4 lg:py-10 lg:px-10'>
          <div className='flex flex-col gap-4'>
            <div className='w-full flex flex-row items-center justify-between'>
              <div className='hidden lg:flex flex-col lg:flex-row gap-2 items-center'>
                <h1 className='font-bold text-lg p-4 lg:p-0 md:text-2xl lg:text-3xl'>اسأل المحامي</h1>
                <p className='text-center text-sm lg:text-start lg:text-base'>اسأل المحامي</p>
              </div>
              <div className='lg:flex flex-row px-4 gap-8 justify-center items-center text-gray-400 hidden'>
                <button type='button' id='radix-:rg:' onClick={toggleVisibility}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='lucide lucide-user2'>
                    <circle cx='12' cy='8' r='5'></circle>
                    <path d='M20 21a8 8 0 1 0-16 0'></path>
                  </svg>
                </button>
                {isVisible && (
                  <div className='fixed left-[30px] top-[70px] text-popover-foregroun  bg-white z-20'>
                    <div
                      id='radix-:r1b:'
                      aria-labelledby='radix-:r1a:'
                      className='z-50 min-w-[8rem] overflow-hidden rounded-md border-border border bg-popover p-1 text-popover-foreground shadow-md w-56 forceRTL  "'
                      tabIndex='-1'
                      data-orientation='vertical'>
                      <div className='px-2 py-1.5 text-sm font-semibold '>
                        <font style={{ verticalAlign: 'inherit' }}>
                          <font style={{ verticalAlign: 'inherit' }}>حسابي</font>
                        </font>
                      </div>
                      <div role='separator' aria-orientation='horizontal' className='-mx-1 my-1 h-px bg-border'></div>
                      <div role='group'>
                        <a href='/dashboard'>
                          <div
                            role='menuitem'
                            className='relative cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:border focus:text-accent-foreground flex flex-row gap-2'
                            tabIndex='-1'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='ml-2 h-4 w-4'>
                              <rect width='20' height='14' x='2' y='5' rx='2'></rect>
                              <line x1='2' x2='22' y1='10' y2='10'></line>
                            </svg>
                            <span>
                              <font style={{ verticalAlign: 'inherit' }}>
                                <font style={{ verticalAlign: 'inherit' }}>لوحة القيادة</font>
                              </font>
                            </span>
                          </div>
                        </a>
                        <button
                          onClick={() => {
                            navigate('/setting/')
                          }}>
                          <div
                            role='menuitem'
                            className='relative cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:border focus:text-accent-foreground  flex flex-row gap-2'
                            tabIndex='-1'
                            data-orientation='vertical'
                            data-radix-collection-item=''>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='ml-2 h-4 w-4'>
                              <path d='M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z'></path>
                              <circle cx='12' cy='12' r='3'></circle>
                            </svg>
                            <span>
                              <font style={{ verticalAlign: 'inherit' }}>
                                <font style={{ verticalAlign: 'inherit' }}>الاعدادات</font>
                              </font>
                            </span>
                          </div>
                        </button>
                      </div>
                      <div role='separator' aria-orientation='horizontal' className='-mx-1 my-1 h-px bg-border'></div>
                      <div
                        role='menuitem'
                        className='relative cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:border focus:text-accent-foreground  flex flex-row gap-2'
                        tabIndex='-1'
                        onClick={logout}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='ml-2 h-4 w-4'>
                          <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4'></path>
                          <polyline points='16 17 21 12 16 7'></polyline>
                          <line x1='21' x2='9' y1='12' y2='12'></line>
                        </svg>
                        <span>
                          <font style={{ verticalAlign: 'inherit' }}>
                            <font style={{ verticalAlign: 'inherit' }}>تسجيل الخروج</font>
                          </font>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-bell'>
                  <path d='M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9'></path>
                  <path d='M10.3 21a1.94 1.94 0 0 0 3.4 0'></path>
                </svg>
              </div>
            </div>
          </div>
          <div className='w-full text-white lg:hidden flex flex-row items-center justify-between relative py-2'>
            <button
              type='button'
              onClick={() => {
                setDashnavbarShow(!dashnavbarShow)
              }}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                className='h-8 w-8'>
                <line x1='4' x2='20' y1='12' y2='12'></line>
                <line x1='4' x2='20' y1='6' y2='6'></line>
                <line x1='4' x2='20' y1='18' y2='18'></line>
              </svg>
            </button>
            <p className='text-center text-sm lg:text-start lg:text-base'>اسأل عادل</p>
            <button type='button'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                className='lg:hidden h-8 w-8'>
                <path d='M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z'></path>
              </svg>
            </button>
          </div>
        </div>
        <div className='h-full flex flex-row border-t border-gray-300  overflow-x-auto relative md:overflow-hidden '>
          <div className='w-full h-full flex flex-col p-2 self-center justify-between  h-full '>
            <div className='flex flex-col gap-12  items-center mt-[30px] lg:justify-center max-sm:justify-around h-full'>
              <div className='mt-[30px]'>
                <div className='flex flex-col gap-4 items-center justify-center'>
                  <img
                    alt='adel-bot'
                    loading='lazy'
                    width='120'
                    height='120'
                    decoding='async'
                    data-nimg='1'
                    src={Laywer}
                    style={{ color: 'transparent', visibility: 'visible' }}
                    data-xblocker='passed'
                  />
                  <img alt='adel-bot' loading='lazy' width='80' height='80' decoding='async' data-nimg='1' src={Logo_primary} style={{ color: 'transparent' }} />
                </div>
                <h1 className='text-xl font-bold text-accent'> وسوف أساعدك بقدر ما أستطيع</h1>
              </div>
              <div className=' lg:grid grid-cols-1 md:grid-cols-2 gap-8 pb-6 max-sm:space-y-8 max-sm:text-center'>
                <button className='rounded-md shadow text-sm lg:text-base p-2 text-secondary hover:shadow-md active:shadow'>اعطني نبذة عن نظام الشركات</button>
                <button className='rounded-md shadow text-sm lg:text-base p-2 text-secondary hover:shadow-md active:shadow'>ما هي الحقوق والواجبات الأساسية للمواطنين تحت قانون المرور؟</button>
                <button className='rounded-md shadow text-sm lg:text-base p-2 text-secondary hover:shadow-md active:shadow'>ماهي حقوق الموظف عند الاستقالة؟</button>
                <button className='rounded-md shadow text-sm lg:text-base p-2 text-secondary hover:shadow-md active:shadow'>ماهي اللائحة التنفيذية لنظام القياس والمعايرة؟</button>
              </div>
            </div>
            <div className='bg-[#F6F6F6] sticky -bottom-2 md:px-16 py-4 md:py-2 left-[5vw] z-10 w-full'>
              <div className='relative flex flex-row gap-2 items-center justify-center '>
                <div className='h-62 px-7 w-[900px] rounded-[12px] bg-white p-2  '>
                  <textarea
                    className='h-16 px-3 text-xl font-medium font-Sans py-1 mt-3 outline-none  border-gray-300 w-full resize-none border rounded-lg placeholder:text-sm scrollbar'
                    placeholder='أضف تعليقاتك هنا'></textarea>
                  <div className='flex justify-between mt-2 items-center'>
                    <p className='text-sm text-accent '>أدخل 100 حرفًا على الأقل</p>
                    <button className='h-8 w-[90px] bg-color2 text-sm text-white rounded-lg transition-all cursor-pointer hover:bg-secondary'>اسأل</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='hidden lg:flex flex-col gap-4 border-r border-gray-300 w-1/5 pt-6 h-full '>
            <div className='flex flex-col gap-4'>
              <div className='flex flex-col items-center gap-4 w-full'>
                <button className='text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-secondary/75 text-secondary-foreground hover:bg-teal-600 h-12 px-10 py-4 md:py-6 flex flex-row items-center text-center justify-center rounded-md mx-4 text-white'>
                  محادثة جديدة{' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='lucide lucide-plus'>
                    <path d='M5 12h14'></path>
                    <path d='M12 5v14'></path>
                  </svg>
                </button>
              </div>
              <div className='border-t border-b border-gray-300 text-xl py-4 text-center'>محادثاتك</div>
            </div>
            <div className='w-full flex flex-col gap-2 relative md:overflow-y-auto mb-2'></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Asklawyer
