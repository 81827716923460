import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import propTypes from 'prop-types';
import facebookWhite from '../../assests/Landing/facebook-white.png'
import twitterWhite from '../../assests/Landing/twitter-white.png'
import likedinWhite from '../../assests/Landing/likedin-white.png'
import close_icon from '../../assests/Icons/mobile_menu.svg'
import logo1 from '../../assests/Landing/logo1.png'
const Menubar = ({ showMenu, setShowMenu, user, logout }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        setShowMenu(false);
    };

    const handleLogin = () => {
        navigate('/login');
        setShowMenu(false);
    };

    const handleRegister = () => {
        navigate('/register');
        setShowMenu(false);
    };
    return (
        <>
            <div className={`sm:hidden w-full fixed duration-500 z-10 h-full bg-[#224B37] ${showMenu ? 'mr-[0px]' : 'mr-[-500px]'}`}>
                <div className='w-full '>
                    <button
                        onClick={() => {
                            setShowMenu(false)
                        }}>
                        <img src={close_icon} alt='close_icon' className='py-[30px] pr-[20px]' />
                    </button>
                </div>
                <div className='w-full flex justify-center items-center'>
                    <img src={logo1} alt='logo1' />
                </div>
                {user?.token ? (
                    <div className='w-full flex gap-[1rem] justify-center my-[60px]'>
                        <button
                            onClick={handleLogout}>
                            <div className='w-[112px] h-[42px] flex justify-center items-center text-white text-[12px] border border-[white] border-[2px] rounded-[33px] hover:bg-white hover:text-[#2E694C]'>تسجيل خروج </div>
                        </button>
                    </div>
                ): (
                    <div className='w-full flex gap-[1rem] justify-center my-[60px]'>
                        <button
                            onClick={handleLogin}>
                            <div className='w-[112px] h-[42px] flex justify-center items-center text-white text-[12px] border border-[white] border-[2px] rounded-[33px] hover:bg-white hover:text-[#2E694C]'>تسجيل الدخول </div>
                        </button>
                        <button
                            onClick={handleRegister}>
                            <div className='w-[112px] h-[42px] flex justify-center items-center text-white text-[12px] border border-[white] border-[2px] rounded-[33px] hover:bg-white hover:text-[#2E694C]'>يسجل </div>
                        </button>
                    </div>   
                )}
                
                <div className='w-[100%] flex text-white mt-[55px] text-[12px]'>
                    <div className='w-[100%] border-b border-white flex justify-start'>
                        <div className='pr-[30px] mb-1'>الرئيسة</div>
                    </div>
                </div>
                <div className='w-[100%] flex text-white mt-[55px] text-[12px]'>
                    <div className='w-[100%] flex justify-start border-b border-white'>
                        <div className='pr-[30px] mb-1'>الفريق</div>
                    </div>
                </div>
                <div className='w-[100%] flex text-white mt-[55px] text-[12px]'>
                    <div className='w-[100%] flex justify-start border-b border-white'>
                        <div className='pr-[30px] mb-1'>عن عدالة</div>
                    </div>
                </div>
                <div className='w-[100%] flex text-white mt-[55px] text-[12px]'>
                    <div className='w-[100%] flex justify-start border-b border-white'>
                        <div className='pr-[30px] mb-1'>الأخبار</div>
                    </div>
                </div>
                <div className='md:hidden flex flex-row  gap-[1rem] justify-center pt-4 items-center pb-[200px] mt-[80px]' dir='ltr'>
                    <button>
                        <img src={twitterWhite} alt='twitter' />
                    </button>
                    <button>
                        <img src={facebookWhite} alt='facebook' />
                    </button>
                    <button>
                        <img src={likedinWhite} alt='likedin' />
                    </button>
                </div>
            </div>
        </>
    )
};

Menubar.propTypes = {
    showMenu: propTypes.bool.isRequired,
    setShowMenu: propTypes.func.isRequired,
    user: propTypes.shape({
        token: propTypes.string
    }),
    logout: propTypes.func.isRequired,
};

export default Menubar;