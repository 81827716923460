import { React, useState, useEffect, createRef, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import useWindowSize from '../windowssize'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from '../layout/header'
import Footer from '../layout/footer'
import ReCAPTCHA from 'react-google-recaptcha'

import VerificationInput from 'react-verification-input'
import { AuthContext } from '../../context/AuthContext'
import Auth from '../../assests/Authentication/Ai_login.svg'
import { signup, sendOTP } from '../../api/index';

const Register = () => {
  const { width } = useWindowSize()
  const isMobile = width < 768
  const [isAlert, setIsAlert] = useState(false)
  const [user, setUser] = useState([])
  const [profile, setProfile] = useState([])
  const [captchaToken, setCaptchaToken] = useState('')
  const [code, setCode] = useState('')
  const navigate = useNavigate('');
  const [phonenumber, setPhonenumber] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [visible, setVisible] = useState(false)
  const recaptchaRef = createRef()
  const [verificationCode, setVerificationCode] = useState('')
  const { setIsAuthenticated } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)

  const onReCAPTCHASuccess = token => {
    setCaptchaToken(token);
    // Use the token for server-side validation or further processing
  }

  const handleEmail = event => { 
    setEmail(event.target.value);
  }
  const handleUsername = event => { 
    setUsername(event.target.value);
  }
  const handlePhoneNumber = event => {
    setPhonenumber(event.target.value);
  }
  const handlePassword = event => {
    setPassword(event.target.value);
  }

  const handleClick = async event => {
    event.preventDefault();
    setIsLoading(true);

    if (!email) {
      toast.error("Email must be inserted.");
    }
    
    if (!phonenumber.match(/^\d{10}$/)) {
      toast.error('Invalid phone number. Phone number must have 10 digits.');
      setIsLoading(false);
      return;
    }

    if (!password || password.length < 8) {
      toast.error('Password must be at least 8 characters long.');
      setIsLoading(false);
      return;
    }

    try {
      if (captchaToken) {
        const data = await sendOTP(email);
        setIsLoading(false);

        if (data) {
          setVisible(true)
        } else {
          if (data.status === 500) {
            toast.warning(data.message)
          }
        }
      } else {
       toast.error('Please verify the CAPTCHA.')
      }
    } catch (error) {
      toast.error('Internal server error.')
    }
  }

  const handleInputChange = value => {
    setVerificationCode(value);
  }

  const handleAutoSend = value => { 
    setVerificationCode(value);
    handleOTPVerification(value);
  }

  const handleOTPVerification = async (value) => {
    setIsLoading(true)
    try {
      const data = await signup(username, email, password, phonenumber, value);
      setIsLoading(false);
      if (data) {
        toast('Register successful!')
        navigate('/login')
      } else {
        if (data.status === 403) {
          toast.warning(data.message);
        } else {
          toast.warning(data.message);
        }
      }
    } catch (error) {
      console.log('Internal server error.')
    }
  }
  return (
    <main className='h-screen'>
      <div
        className={` ${
          !visible ? 'hidden' : ''
        } max-w-3xl fixed left-[50%] top-[50%] z-50 translate-x-[-50%] translate-y-[-50%] border-0  p-6 shadow-lg duration-200 md:w-full flex flex-col items-center justify-center gap-12 w-full bg-[#fafafa]`}
        tabIndex='-1'>
        <div className='flex flex-col space-y-1.5 text-center sm:text-left'>
          <h2 id='radix-:r5:' className='tracking-tight text-xl text-center font-medium'>
            لتأكيد تسجيل الدخول، الرجاء كتابة رمز ال <span className=' font-bold font-sans'>OTP</span> المرسل لك
          </h2>
        </div>
        <div
          data-input-otp-container='true'
          className='flex items-center gap-4'
          style={{
            position: 'relative',
            cursor: 'text',
            userSelect: 'none',
            '--root-height': '56px'
          }}>
          <VerificationInput
            classNames={{
              container: 'container',
              character: 'character',
              characterInactive: 'character--inactive',
              characterSelected: 'character--selected',
              characterFilled: 'character--filled'
            }}
            validChars='0-9'
            inputProps={{ inputMode: 'numberic' }}
            onChange={handleInputChange} // Assuming index is 0
            onComplete={handleAutoSend}
            value={verificationCode} // Pass the value as a prop
            length={6} // Set the length of the verification code
          />
        </div>
        <div className='w-full'>
          <div className='flex flex-row justify-between items-center'>
            <button onClick={handleClick} className='inline-flex items-center justify-center rounded-full text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-primary underline-offset-4 hover:underline h-12 md:py-6 p-0'>
              إعادة إرسال OTP
            </button>
            <div>
              <p>Didn&apos;t recieve code?</p>
            </div>
          </div>
          <button
            className='inline-flex items-center justify-center rounded-full text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#224B37] text-primary-foreground hover:bg-teal-900 glow-teal h-12 px-10 py-4 md:py-6 w-full text-white'
            onClick={handleClick}>
            {isLoading ? (
              <>
                <svg aria-hidden='true' role='status' className='inline w-6 h-6 me-2 text-white animate-spin' viewBox='0 0 100 101' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                    fill='#E5E7EB'
                  />
                  <path
                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                    fill='currentColor'
                  />
                </svg>
                تحميل...
              </>
            ) : (
              <span>تأكيد</span>
            )}
          </button>
        </div>
      </div>
      <ToastContainer
        className='custom-toast-container'
        toastClassName='custom-toast'
        bodyClassName='custom-toast-body'
        closeButtonClassName='custom-toast-close-button'
        progressClassName='custom-toast-progress-bar'
        position={width >= 768 ? 'top-right' : 'top-center'}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={`absolute bg-cover bg-center h-screen bg-no-repeat inset-0 backdrop-blur-sm transition-opacity duration-300 ${visible ? 'opacity-20 pointer-events-none' : 'opacity-100'} `}>
        <Header />

        <div className='flex flex-col lg:flex-row lg:min-h-[90vh] justify-evenly lg:justify-center container g:py-0 max-sm:pb-8 '>
          <div className='lg:w-[70%] flex flex-col justify-around items-center lg:items-start py-16 lg:py-8 relative'>
            <div className='relative'>
              <div className='z-10 text-black'>
                <h1 className='font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center lg:text-right'>انشاء حساب</h1>
                <p className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center lg:text-right'>البحث بضغطة زر</p>
              </div>
              <div className='hidden lg:inline box-shadow absolute h-[20vh] w-full top-0 z-0'></div>
            </div>
            <div>
              <div className='hidden lg:inline lg:h-[60vh] lg:bottom-0 right-8 lg:absolute'>
                <div className='dotlottie-container main' lang='en'>
                  <div data-name='data' role='figure' className='animation' style={{ position: 'relative', width: '100%', height: '100%', backgroundColor: 'transparent' }}>
                    <img src={Auth} className='w-[385px] h-[385px]'></img>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='flex-1 flex flex-col gap-5 text-black lg:justify-center items-center pt-24 pb-16 max-sm:pt-16 max-sm:pb-8'>
            <h2 className='font-bold text-lg sm:text-xl md:text-2xl '>
              <li className='vertical-align-inherit  list-none'>انشاء حساب</li>
            </h2>
            <form className='w-full flex flex-col gap-4 items-center'>
              <div className='flex flex-col gap-2 w-full'>
                <div className='space-y-2'>
                  <h2>اسم المستخدم</h2>
                  <input
                    className='flex h-12 w-full rounded-md border hover:border-[#e5e7eb] border-[#a9a9a9] bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 '
                    placeholder='Daniel An'
                    type='text'
                    name='username'
                    onChange={handleUsername}></input>
                </div>
              </div>
              <div className='flex flex-col gap-2 w-full'>
                <div className='space-y-2'>
                  <h2>بريد إلكتروني</h2>
                  <input
                    className='flex h-12 w-full rounded-md border hover:border-[#e5e7eb] border-[#a9a9a9] bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 '
                    placeholder='teamxxxx@gmail.com'
                    type='email'
                    name='Email'
                    onChange={handleEmail}></input>
                </div>
              </div>
              <div className='flex flex-col gap-2 w-full'>
                <div className='space-y-2'>
                  <h2>رقم التليفون</h2>
                  <input
                    className='flex h-12 w-full rounded-md border hover:border-[#e5e7eb] border-[#a9a9a9] bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 '
                    placeholder='0599xxxxxx'
                    type='text'
                    name='phoneNumber'
                    onChange={handlePhoneNumber}></input>
                </div>
              </div>
              <div className='flex flex-col gap-2 w-full'>
                <h2 className=''>كلمة المرور</h2>
                <div className='space-y-2'>
                  <input
                    className='flex h-12 w-full rounded-md border hover:border-[#e5e7eb] border-[#a9a9a9]  bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 max-sm:mb-[40px]'
                    placeholder='01234567'
                    type='password'
                    name='password'
                    onChange={handlePassword}></input>
                </div>
              </div>
              <div>
                <div>
                  <div style={{ width: '304px', height: '78px' }}>
                    <div>
                      <ReCAPTCHA ref={recaptchaRef} sitekey='6LdFjsopAAAAALL4yOuzNplEgDd-rbJuwjC2W9PH' onChange={onReCAPTCHASuccess} size='normal' />
                    </div>
                    <textarea
                      id='g-recaptcha-response-1'
                      name='g-recaptcha-response'
                      className='g-recaptcha-response'
                      style={{
                        width: '250px',
                        height: '40px',
                        border: '1px solid rgb(193, 193, 193)',
                        margin: '10px 25px',
                        padding: '0px',
                        resize: 'none',
                        display: 'none'
                      }}></textarea>
                  </div>
                  <iframe style={{ display: 'none' }}></iframe>
                </div>
              </div>
              <button
                className='inline-flex items-center justify-center rounded-full text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#224B37] text-primary-foreground hover:bg-[#224B37] hover:bg-opacity-95 glow-teal h-12 px-10 py-4 md:py-6 w-full text-color1'
                type='button'
                onClick={handleClick}>
                {isLoading ? (
                  <>
                    <svg aria-hidden='true' role='status' className='inline w-6 h-6 me-2 text-white animate-spin' viewBox='0 0 100 101' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                        fill='#E5E7EB'
                      />
                      <path
                        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                        fill='currentColor'
                      />
                    </svg>
                    <span className='text-white'>تحميل...</span>
                  </>
                ) : (
                  <span className='text-white'>انشاء حساب</span>
                )}
              </button>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    </main>
  )
}

export default Register
