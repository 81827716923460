import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Logo_green from '../../assests/logo-green.svg'
import Workspace from '../../assests/workspace.svg'
import Dashnavbar from '../layout/dashnavbar'
import PropTypes from 'prop-types'
import General from '../Account Settings/general'
import Coupon from '../Account Settings/coupons'
import Team from '../Account Settings/team'
import Subscription from '../Account Settings/subscriptions'
import { AuthContext } from '../../context/AuthContext'

const Settingindex = () => {
  const [activeComponent, setActiveComponent] = useState('General') // State to keep track of the active component
  const [dashnavbarShow, setDashnavbarShow] = useState(false)
  const [isDash, setIsDash] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const { logout } = useContext(AuthContext)

  // Function to toggle the visibility
  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }
  const toggleDash = () => {
    setIsDash(!isDash)
  }
  const handleNavigation = component => {
    setActiveComponent(component) // Update the active component
  }
  // Render the active component based on the state
  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'General':
        return <General />
      case 'Coupon':
        return <Coupon />
      case 'Team':
        return <Team />
      case 'Subscription':
        return <Subscription />
      default:
        return null
    }
  }
  return (
    <div className='min-h-screen flex flex-row  bg-[#F6F6F6] '>
      <div className='hidden lg:flex flex-col justify-between   pt-8 pb-2 w-1/5 border-l border-gray-300 h-screen md:overflow-y-auto overflow-x-hidden relative  scrollbar'>
        <div className='flex flex-col w-full items-center gap-6 px-2'>
          <a className='pb-5 self-center border-b w-full border-gray-300 flex place-content-center' href='/'>
            <img
              alt='logo'
              loading='lazy'
              width='150'
              height='150'
              decoding='async'
              data-nimg='1'
              className='w-32'
              src={Logo_green}
              style={{ color: 'transparent', visibility: 'visible' }}
              data-xblocker='passed'
            />
          </a>
          <div className='flex flex-col gap-[2vh] items-start w-3/4 lg:w-full'>
            <a className='bg-accent shadow flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4' href='/dashboard'>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-home'>
                  <path d='m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z'></path>
                  <polyline points='9 22 9 12 15 12 15 22'></polyline>
                </svg>
              </span>
              <span className='text-white text-sm font-semibold whitespace-nowrap'>الرئيسية</span>
            </a>
            <a className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4' href='/search'>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-search'>
                  <circle cx='11' cy='11' r='8'></circle>
                  <path d='m21 21-4.3-4.3'></path>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>البحث </span>
            </a>
            <a className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4' href='/adel'>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-bot'>
                  <path d='M12 8V4H8'></path>
                  <rect width='16' height='12' x='4' y='8' rx='2'></rect>
                  <path d='M2 14h2'></path>
                  <path d='M20 14h2'></path>
                  <path d='M15 13v2'></path>
                  <path d='M9 13v2'></path>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>اسأل عادل</span>
            </a>{' '}
            <a className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4' href='/lawyer'>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-bot'>
                  <path d='M12 8V4H8'></path>
                  <rect width='16' height='12' x='4' y='8' rx='2'></rect>
                  <path d='M2 14h2'></path>
                  <path d='M20 14h2'></path>
                  <path d='M15 13v2'></path>
                  <path d='M9 13v2'></path>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>اطلب محاميا</span>
            </a>
            <a className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4' href='/myfile'>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-file'>
                  <path d='M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z'></path>
                  <polyline points='14 2 14 8 20 8'></polyline>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>ملفاتي وتحديداتي</span>
            </a>
            <a className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4' href='/comingsoon'>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-folder'>
                  <path d='M4 20h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.93a2 2 0 0 1-1.66-.9l-.82-1.2A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13c0 1.1.9 2 2 2Z'></path>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>الملفات القضائية</span>
            </a>
          </div>
          <div className='w-full border-t border-gray-300'></div>
          <div className='flex flex-col gap-2 w-full text-accent'>
            <div className='flex flex-row justify-between'>
              <div className='flex items-center gap-1 font-semibold'>
                <img alt='workspace-icon' loading='lazy' width='25' height='25' decoding='async' data-nimg='1' src={Workspace} style={{ color: 'transparent' }} />

                <font style={{ verticalAlign: 'inherit' }}>الافرقة</font>
              </div>
              <button
                className='inline-flex items-center justify-center text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none text-secondary focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-accent-foreground border-2 border-secondary border-accent-foreground bg-background h-9 rounded-md px-3 hover:bg-secondary hover:text-white'
                type='button'
                onClick={() => handleNavigation('/')}>
                <font style={{ verticalAlign: 'inherit' }}>اضافة</font>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-plus'>
                  <path d='M5 12h14'></path>
                  <path d='M12 5v14'></path>
                </svg>
              </button>
            </div>
            <ul className='list-disc flex flex-col py-2 gap-2 px-8'></ul>
          </div>
        </div>
        <div className='flex flex-col gap-2 items-center justify-center'>
          <div className='w-11/12 relative flex flex-col gap-2 bg-accent rounded-2xl p-4'>
            <img
              alt='circles'
              loading='lazy'
              width='500'
              height='500'
              decoding='async'
              data-nimg='1'
              className='absolute -right-16 z-0 h-36'
              src='/circles.svg'
              style={{ color: 'transparent', visibility: 'visible' }}
              data-xblocker='passed'
            />
            <div className='flex flex-col gap-2 text-white z-10'>
              <div className='rounded-md bg-white p-2 w-10 h-10'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='bg-accent rounded-full p-1'>
                  <path d='m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14'></path>
                </svg>
              </div>
              <div>
                <li className='text-right vertical-align-inherit list-none'>
                  <span className='align-middle'>اشتراكك الحالي:</span>
                </li>
                <span className='font-semibold'>مجاني</span>
              </div>
            </div>
            <div className='flex flex-col gap-2 w-full items-center justify-center z-10'>
              <a
                className='inline-flex items-center justify-center text-sm md:text-md h-12 px-10 py-4 md:py-6 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-white text-accent hover:bg-white/90 w-full font-semibold rounded-md'
                href='/dashboard'>
                <font style={{ verticalAlign: 'inherit' }}>
                  <font style={{ verticalAlign: 'inherit' }}>وحة القيادة</font>
                </font>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>{dashnavbarShow && <Dashnavbar dashnavbarShow={dashnavbarShow} setDashnavbarShow={setDashnavbarShow} />}</div>
      <div className='w-full h-screen flex flex-col gap-0  relative md:overflow-y-auto scrollbar'>
        <div className='h-screen lg:pl-4'>
          <div dir='rtl' className='px-3 py-10 lg:px-10'>
            <div className='w-full flex flex-row items-center justify-between px-4 lg:hidden'>
              <button
                type='button'
                onClick={() => {
                  setDashnavbarShow(!dashnavbarShow)
                }}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='h-8 w-8'>
                  <line x1='4' x2='20' y1='12' y2='12'></line>
                  <line x1='4' x2='20' y1='6' y2='6'></line>
                  <line x1='4' x2='20' y1='18' y2='18'></line>
                </svg>
              </button>

              <div className='flex flex-row gap-8 text-gray-400'>
                <button type='button' id='radix-:r9:' onClick={toggleDash}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='lucide lucide-user2'>
                    <circle cx='12' cy='8' r='5'></circle>
                    <path d='M20 21a8 8 0 1 0-16 0'></path>
                  </svg>
                </button>
                {isDash && (
                  <div className='fixed left-[0px] top-[75px] text-popover-foregroun  bg-white'>
                    <div
                      id='radix-:r1b:'
                      aria-labelledby='radix-:r1a:'
                      className='z-50 min-w-[8rem] overflow-hidden rounded-md border-border border bg-popover p-1 text-popover-foreground shadow-md w-56 forceRTL  "'
                      tabIndex='-1'
                      data-orientation='vertical'>
                      <div className='px-2 py-1.5 text-sm font-semibold '>
                        <font style={{ verticalAlign: 'inherit' }}>
                          <font style={{ verticalAlign: 'inherit' }}>حسابي</font>
                        </font>
                      </div>
                      <div role='separator' aria-orientation='horizontal' className='-mx-1 my-1 h-px bg-border'></div>
                      <div role='group'>
                        <a href='/dashboard/'>
                          <div
                            role='menuitem'
                            className='relative cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:border focus:text-accent-foreground  flex flex-row gap-2'
                            tabIndex='-1'
                            data-orientation='vertical'
                            data-radix-collection-item=''>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='ml-2 h-4 w-4'>
                              <path d='M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z'></path>
                              <circle cx='12' cy='12' r='3'></circle>
                            </svg>
                            <span>
                              <font style={{ verticalAlign: 'inherit' }}>
                                <font style={{ verticalAlign: 'inherit' }}>لوحة القيادة</font>
                              </font>
                            </span>
                          </div>
                        </a>
                        <a href='/setting/'>
                          <div
                            role='menuitem'
                            className='relative cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:border focus:text-accent-foreground  flex flex-row gap-2'
                            tabIndex='-1'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='ml-2 h-4 w-4'>
                              <path d='M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z'></path>
                              <circle cx='12' cy='12' r='3'></circle>
                            </svg>
                            <span>
                              <font style={{ verticalAlign: 'inherit' }}>
                                <font style={{ verticalAlign: 'inherit' }}>الاعدادات</font>
                              </font>
                            </span>
                          </div>
                        </a>
                      </div>
                      <div role='separator' aria-orientation='horizontal' className='-mx-1 my-1 h-px bg-border'></div>
                      <div
                        role='menuitem'
                        className='relative cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:border focus:text-accent-foreground  flex flex-row gap-2'
                        tabIndex='-1'
                        onClick={logout}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='ml-2 h-4 w-4'>
                          <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4'></path>
                          <polyline points='16 17 21 12 16 7'></polyline>
                          <line x1='21' x2='9' y1='12' y2='12'></line>
                        </svg>
                        <span>
                          <font style={{ verticalAlign: 'inherit' }}>
                            <font style={{ verticalAlign: 'inherit' }}>تسجيل الخروج</font>
                          </font>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-bell'>
                  <path d='M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9'></path>
                  <path d='M10.3 21a1.94 1.94 0 0 0 3.4 0'></path>
                </svg>
              </div>
            </div>
            <div className='flex flex-col gap-4'>
              <div className='w-full flex flex-row justify-between'>
                <div className='flex flex-col gap-2'>
                  <h1 className='font-bold text-lg p-4 lg:p-0 md:text-2xl lg:text-3xl'>إعدادات الحساب</h1>
                  <h2 className='font-medium text-gray-400 text-base p-4 lg:p-0'>يمكنك إدارة جميع بياناتك الخاصة من هنا</h2>
                </div>
                <div className='lg:flex flex-row gap-8 justify-center items-center text-gray-400 hidden'>
                  <button type='button' id='radix-:rb:' onClick={toggleVisibility}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      className='lucide lucide-user2'>
                      <circle cx='12' cy='8' r='5'></circle>
                      <path d='M20 21a8 8 0 1 0-16 0'></path>
                    </svg>
                  </button>
                  {isVisible && (
                    <div className='fixed left-[30px] top-[95px] text-popover-foregroun  bg-white'>
                      <div
                        id='radix-:r1b:'
                        aria-labelledby='radix-:r1a:'
                        className='z-50 min-w-[8rem] overflow-hidden rounded-md border-border border bg-popover p-1 text-popover-foreground shadow-md w-56 forceRTL  "'
                        tabIndex='-1'
                        data-orientation='vertical'>
                        <div className='px-2 py-1.5 text-sm font-semibold '>
                          <font style={{ verticalAlign: 'inherit' }}>
                            <font style={{ verticalAlign: 'inherit' }}>حسابي</font>
                          </font>
                        </div>
                        <div role='separator' aria-orientation='horizontal' className='-mx-1 my-1 h-px bg-border'></div>
                        <div role='group'>
                          <a href='/dashboard'>
                            <div
                              role='menuitem'
                              className='relative cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:border focus:text-accent-foreground flex flex-row gap-2'
                              tabIndex='-1'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='ml-2 h-4 w-4'>
                                <rect width='20' height='14' x='2' y='5' rx='2'></rect>
                                <line x1='2' x2='22' y1='10' y2='10'></line>
                              </svg>
                              <span>
                                <font style={{ verticalAlign: 'inherit' }}>
                                  <font style={{ verticalAlign: 'inherit' }}>لوحة القيادة</font>
                                </font>
                              </span>
                            </div>
                          </a>
                          <a href='/setting/'>
                            <div
                              role='menuitem'
                              className='relative cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:border focus:text-accent-foreground  flex flex-row gap-2'
                              tabIndex='-1'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='ml-2 h-4 w-4'>
                                <path d='M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z'></path>
                                <circle cx='12' cy='12' r='3'></circle>
                              </svg>
                              <span>
                                <font style={{ verticalAlign: 'inherit' }}>
                                  <font style={{ verticalAlign: 'inherit' }}>الاعدادات</font>
                                </font>
                              </span>
                            </div>
                          </a>
                        </div>
                        <div role='separator' aria-orientation='horizontal' className='-mx-1 my-1 h-px bg-border'></div>
                        <div
                          role='menuitem'
                          className='relative cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:border focus:text-accent-foreground  flex flex-row gap-2'
                          tabIndex='-1'
                          onClick={logout}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='ml-2 h-4 w-4'>
                            <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4'></path>
                            <polyline points='16 17 21 12 16 7'></polyline>
                            <line x1='21' x2='9' y1='12' y2='12'></line>
                          </svg>
                          <span>
                            <font style={{ verticalAlign: 'inherit' }}>
                              <font style={{ verticalAlign: 'inherit' }}>تسجيل الخروج</font>
                            </font>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='lucide lucide-bell'>
                    <path d='M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9'></path>
                    <path d='M10.3 21a1.94 1.94 0 0 0 3.4 0'></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div dir='rtl' className='w-full'>
            <div className=' py-2 border-y border-[#abadaf] '>
              <div className='h-10 items-center z-0 justify-center p-1 bg-transparent flex lg:justify-start border-0 rounded-none border-gray-300 text-gray-400 w-full '>
                <button
                  type='button'
                  onClick={() => handleNavigation('General')}
                  className={`inline-flex items-center justify-center whitespace-nowrap font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 p-0 mx-[2vw] lg:mx-[4vw] border-accent ${
                    activeComponent === 'General' ? 'text-accent border-b' : ''
                  }`}
                  tabIndex='-1'>
                  عام
                </button>
                <button
                  type='button'
                  onClick={() => handleNavigation('Team')}
                  className={`inline-flex items-center justify-center whitespace-nowrap font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 p-0 mx-[2vw] lg:mx-[4vw] text-base data-[state=active]:text-accent data-[state=active]:shadow-none data-[state=active]:bg-transparent rounded-none  border-0 data-[state=active]:border-b border-accent ${
                    activeComponent === 'Team' ? 'text-accent border-b' : ''
                  }`}>
                  الافرقة
                </button>
                <button
                  type='button'
                  onClick={() => handleNavigation('Coupon')}
                  className={`inline-flex items-center justify-center whitespace-nowrap font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 p-0 mx-[2vw] lg:mx-[4vw] text-base data-[state=active]:text-accent data-[state=active]:shadow-none data-[state=active]:bg-transparent rounded-none border-0 data-[state=active]:border-b border-accent ${
                    activeComponent === 'Coupon' ? 'text-accent border-b' : ''
                  }`}>
                  كوبونات
                </button>
                <button
                  type='button'
                  onClick={() => handleNavigation('Subscription')}
                  className={`inline-flex items-center justify-center whitespace-nowrap font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 p-0 mx-[2vw] lg:mx-[4vw] text-base data-[state=active]:text-accent data-[state=active]:shadow-none data-[state=active]:bg-transparent rounded-none  border-0 data-[state=active]:border-b border-accent ${
                    activeComponent === 'Subscription' ? 'text-accent border-b' : ''
                  }`}
                  tabIndex='-1'>
                  الاشتراكات
                </button>
              </div>
            </div>
            {renderActiveComponent()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settingindex
