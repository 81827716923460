import { React, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../component/layout/header'
import Footer from '../component/layout/footer'
import { AuthContext } from '../context/AuthContext'
import lawyer from '../assests/Landing/Lawyer.png'
import lawyer_mobile from '../assests/Landing/Lawyer_mobile.png'
import person from '../assests/Landing/Person.png'
import person_mobile from '../assests/Landing/Person_mobile.png'
import Vector3 from '../assests/Landing/Vector3.png'
import Vector3_mobile from '../assests/Landing/Vector3.png'
import landing from '../assests/Landing/LandPage.png'
import logo2 from '../assests/Landing/logo2.png'
import Group_mobile from '../assests/Landing/Group_mobile.png'
import facebook from '../assests/Landing/facebook.png'
import twitter from '../assests/Landing/twitter.png'
import likedin from '../assests/Landing/likedin.png'
import headerMenu from '../assests/Landing/headerMenu.png'
import Menubar from '../component/layout/menubar'
const Total = () => {
  const navigate = useNavigate('')
  const [navbarShow, setNavbarShow] = useState(false)
  const { user, logout } = useContext(AuthContext)

  return (
    <>
      <Menubar showMenu={navbarShow} setShowMenu={setNavbarShow} user={user} logout={logout} />
      <div className='h-full relative overflow-x-hidden bg-white '>
        <div className='max-sm:hidden w-full relative h-[50%] flex flex-col bg-cover bg-center h-screen ' style={{ backgroundImage: `url(${landing})` }}>
          <div className='w-[100%] top-[0px] left-[0px] mx-auto flex items-end justify-between px-20'>
            {user?.token ? (
              <button
                onClick={logout}
                className='hover:text-gray-400 text-[18px] border border-white text-white py-2 px-2 rounded-[10px] hover:bg-white hover:text-green-900 transition min-w-[125px]'>
                تسجيل خروج
              </button>
            ) : (
              <div className='flex justify-center items-center text-white gap-[2rem] pb-3'>
                <button
                  onClick={() => {
                    navigate('/register')
                  }}
                  className='hover:text-gray-400 text-[18px] border border-white text-white py-2 px-2 rounded-[10px] hover:bg-white hover:text-green-900 transition min-w-[125px]'>
                  يسجل
                </button>
                <button
                  onClick={() => {
                    navigate('/login')
                  }}
                  className='hover:text-gray-400 text-[18px] border border-white text-white py-2 px-2 rounded-[10px] hover:bg-white hover:text-green-900 transition min-w-[125px]'>
                  تسجيل الدخول
                </button>
              </div>
            )}

            <div className='flex w-[70%] justify-center items-center pb-3'>
              <nav className='flex items-center gap-[6rem] text-white' dir='ltr'>
                <a href='#' className='hover:text-gray-400 text-[18px]'>
                  الرئيسية
                </a>
                <a href='#' className='hover:text-gray-400 text-[18px]'>
                  الفريق
                </a>
                <a href='#' className='hover:text-gray-400 text-[18px]'>
                  عن عدالة
                </a>
                <a href='#' className='hover:text-gray-400 text-[18px]'>
                  الأخبار
                </a>
              </nav>
            </div>
            <div className='pt-62'>
              <img src={logo2} alt='shape2' />
            </div>
          </div>
          <div className='flex flex-col absolute bottom-[25%] left-[22%]'>
            <span className='text-[#1A3834] text-[36px] font-[500] w-[420px]'>اول مساعد قانوني </span>
            <span className='text-[#1A3834] text-[36px] font-[500] w-[430px]'>مدعوم بالذكاء الاصطناعي </span>
            <button
              className='h-[47px] w-[205px] gradient-to-b rounded-[20px] text-white text-[24px] mt-7 ms-10'
              onClick={() => {
                navigate('/justice')
              }}>
              جرب المنصة
            </button>
          </div>
          <div className='flex flex-row absolute bottom-[7%] left-[5%] gap-[3rem]' dir='ltr'>
            <button className='w-[67px]'>
              <img src={twitter} alt='twitter' />
            </button>
            <button className='w-[67px]'>
              <img src={facebook} alt='facebook' />
            </button>
            <button className='w-[67px]'>
              <img src={likedin} alt='likedin' />
            </button>
          </div>
        </div>
        <div className='md:hidden h-[500px] w-[100%] bg-cover bg-no-repeat' style={{ backgroundImage: `url(${Group_mobile})` }}>
          <div className='h-[90px] w-full bg-white flex relative opacity-93'>
            <button
              className='h-[22px] w-[35px] absolute right-[20px] top-[32px]'
              onClick={() => {
                setNavbarShow(true)
              }}>
              <img src={headerMenu} alt='headerMenu' />
            </button>
            <div className='h-[72px] w-[144px] left-[2%] top-[10px] absolute '>
              <img src={logo2} alt='logo2' />
            </div>
          </div>
          <div className='w-full relative'>
            <div className='w-full h-full flex flex-col'>
              <span className='text-[#244D47] text-[22px] w-[180px] absolute left-[20px] top-[200px]'>اول مساعد قانوني </span>
              <span className='text-[#244D47] text-[22px] absolute left-[5px] top-[230px]'>مدعوم بالذكاء الاصطناعي </span>
            </div>
          </div>
          <button
            className='flex absolute justify-center items-center h-[31px] w-[138px] gradient-to-b rounded-[20px] text-white text-[15px] left-[40px] top-[370px]'
            onClick={() => {
              navigate('/justice')
            }}>
            جرب المنصة
          </button>
          <div className='flex flex-row absolute top-[430px] left-[40px] gap-[1rem]' dir='ltr'>
            <button>
              <img src={twitter} alt='twitter' className='w-[35px] h-[35px]' />
            </button>
            <button>
              <img src={facebook} alt='facebook' className='w-[35px] h-[35px]' />
            </button>
            <button>
              <img src={likedin} alt='likedin' className='w-[35px] h-[35px]' />
            </button>
          </div>
        </div>
        <div className='w-full relative h-[20%]'>
          <div className='flex flex-col gap-[4rem] justify-center items-center relative'>
            <div className='absolute max-sm:hidden left-[0px] w-[244px] h-[80%]' style={{ backgroundImage: `url(${Vector3})` }} />
            <div className='absolute md:hidden left-[0px] w-[244px] bg-no-repeat h-[70%] mt-[200px]' style={{ backgroundImage: `url(${Vector3_mobile})` }}></div>
            <div className='flex flex-col justify-center items-center mt-[70px]'>
              <span className='md:text-[36px] text-[22px] text-[#275840]'>الخدمات</span>
              <span className='md:text-[24px] text-[20px] text-[#ABABAB] text-center'>خدمات متنوعه للأفراد و المحامين</span>
            </div>
            <div className='relative md:hidden left-[0px] flex flex-row max-sm:grid gap-[3rem] mb-[100px]'>
              <button
                onClick={() => {
                  navigate('/person')
                }}>
                <div className='h-[157px] w-full' style={{ backgroundImage: `url(${person_mobile})` }}></div>
              </button>
              <div className='h-[157px] w-[300px]  ' style={{ backgroundImage: `url(${lawyer_mobile})` }}></div>
            </div>
            <div className='relative max-sm:hidden left-[0px] flex flex-row max-sm:grid gap-[6rem] mb-[150px]'>
              <button
                onClick={() => {
                  navigate('/person')
                }}>
                <div className='h-[307px] w-[586px] max-sm:w-[300px]' style={{ backgroundImage: `url(${person})` }}></div>
              </button>
              <button
                onClick={() => {
                  navigate()
                }}>
                <div className='h-[307px] w-[586px] ' style={{ backgroundImage: `url(${lawyer})` }}></div>
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
export default Total
