import axios from 'axios';
import APP_HOST from '../Route/envVariables';

const api = axios.create({
    baseURL: APP_HOST,
});

api.interceptors.request.use((config) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

api.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response && error.response.status == 401) {
        localStorage.removeItem('user');
        window.location.href = '/login';
    }
    return Promise.reject(error);
})

export default api;