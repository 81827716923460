import { React } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../component/layout/header'
import Footer from '../component/layout/footer'
import { useNavbar } from '../context/NavbarContext'
import lawyer from '../assests/Landing/Lawyer.png'
import person from '../assests/Landing/Person.png'
import Group from '../assests/Icons/Group.png'
import Speech_Bubble from '../assests/Icons/Speech_Bubble.png'
import Scales from '../assests/Icons/Scales.png'
import Record from '../assests/Icons/Record.png'

const Total = () => {
  const navigate = useNavigate('')
  const { navbarShow, setNavbarShow } = useNavbar()
  return (
    <div className='h-full overflow-x-hidden bg-[#f6f6f6]'>
      <Header />
      {!navbarShow && (
        <>
          <div className='container md:px-0'>
            <div className='w-full md:mt-[80px] mt-[30px] mb-[25px]'>
              <div className='flex justify-center items-center h-[25px] w-[85px] bg-[#224B37] rounded-[20px] text-white mb-[10px]'>رجوع</div>
              <div className='md:text-[32px] text-[18px] text-[#000000]'>الخدمات&gt; الأفراد</div>
            </div>
            <div className='w-full mx-auto flex flex-col'>
              <div className='flex md:gap-[5rem] gap-[1rem] justify-center items-center mb-[100px] mt-[20px] md:mt-[60px] max-sm:grid '>
                <div className='max-sm:pt-[60px] border border-[#777777] rounded-[20px] p-10 flex flex-col items-center justify-between w-[300px] h-[300px] pb-[120px] max-sm:h-[265px] max-sm:w-[265px] '>
                  <div className='flex text-green-900 text-4xl mb-2 items-center gap-[20px]'>
                    {/* Replace with your actual icon */}
                    <img src={Record} alt='Record' />
                    <div className='text-[18px] text-black'>السجل</div>
                  </div>
                  <button
                    className='border border-gray-500 py-2 px-4 rounded text-[18px] text-black'
                    onClick={() => {
                      navigate('/consultation')
                    }}>
                    قضاياي{' '}
                  </button>
                </div>
                <div className='max-sm:pt-[60px] border border-[#777777] rounded-[20px] p-10 flex flex-col items-center justify-between w-[300px] h-[300px] pb-[120px] max-sm:h-[265px] max-sm:w-[265px]'>
                  <div className='flex text-green-900 text-4xl mb-2 items-center gap-[20px]'>
                    {/* Replace with your actual icon */}
                    <img src={Scales} alt='scales' />
                    <div className='text-[18px] text-black'>المحامين</div>
                  </div>
                  <button
                    className='border border-gray-500 py-2 px-4 rounded text-[18px] text-black'
                    onClick={() => {
                      navigate('/lawservice')
                    }}>
                    طلب محامي
                  </button>
                </div>
                <div className='max-sm:pt-[60px] border border-[#777777] rounded-[20px] p-10 flex flex-col items-center justify-between w-[300px] h-[300px] pb-[120px] max-sm:h-[265px] max-sm:w-[265px]'>
                  <div className='flex text-green-900 text-4xl mb-2 items-center gap-[20px]'>
                    {/* Replace with your actual icon */}
                    <img src={Speech_Bubble} alt='Speech_Bubble' />
                    <div className='text-[18px] text-black'>ابدء محادثة</div>
                  </div>
                  <button
                    className='border border-gray-500 text-[18px] text-black py-2 px-4 rounded '
                    onClick={() => {
                      navigate('/chatbot')
                    }}>
                    عدالة Ai{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  )
}
export default Total
