import { useState, React, useContext } from 'react'
import Logo_green from '../../assests/logo-green.svg'
import { useNavigate } from 'react-router-dom'
import Workspace from '../../assests/workspace.svg'
import Dashnavbar from '../../component/layout/dashnavbar'
import { AuthContext } from '../../context/AuthContext'
import Search from './search'
import { Link } from 'react-router-dom'

const Dashboard = () => {
  const navigate = useNavigate('')
  const [dashnavbarShow, setDashnavbarShow] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [isDash, setIsDash] = useState(false)
  const { logout } = useContext(AuthContext)

  // Function to toggle the visibility
  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }
  const toggleDash = () => {
    setIsDash(!isDash)
  }
  return (
    <div className='min-h-screen flex flex-row bg-[#F6F6F6] md:overflow-hidden'>
      <div className=' hidden lg:flex flex-col justify-between   pt-8 pb-2 w-1/5 border-l border-gray-300 h-screen relative md:overflow-y-auto overflow-x-hidden scrollbar'>
        <div className='flex flex-col w-full items-center gap-6 px-2'>
          <button
            className='pb-5 self-center border-b w-full border-gray-300 flex place-content-center'
            onClick={() => {
              navigate('/')
            }}>
            <img
              alt='logo'
              loading='lazy'
              width='150'
              height='150'
              decoding='async'
              data-nimg='1'
              className='w-32'
              src={Logo_green}
              style={{ color: 'transparent', visibility: 'visible' }}
              data-xblocker='passed'
            />
          </button>
          <div className='flex flex-col gap-[2vh] items-start w-3/4 lg:w-full'>
            <button
              className='bg-accent shadow flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4'
              onClick={() => {
                navigate('/dashboard')
              }}>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-home'>
                  <path d='m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z'></path>
                  <polyline points='9 22 9 12 15 12 15 22'></polyline>
                </svg>
              </span>
              <span className='text-white text-sm font-semibold whitespace-nowrap'>الرئيسية</span>
            </button>
            <button
              className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4'
              onClick={() => {
                navigate('/search')
              }}>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-search'>
                  <circle cx='11' cy='11' r='8'></circle>
                  <path d='m21 21-4.3-4.3'></path>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>البحث </span>
            </button>
            <button
              className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4'
              onClick={() => {
                navigate('/adel')
              }}>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-bot'>
                  <path d='M12 8V4H8'></path>
                  <rect width='16' height='12' x='4' y='8' rx='2'></rect>
                  <path d='M2 14h2'></path>
                  <path d='M20 14h2'></path>
                  <path d='M15 13v2'></path>
                  <path d='M9 13v2'></path>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>اسأل عادل</span>
            </button>
            <button
              className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4'
              onClick={() => {
                navigate('/lawyer')
              }}>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-bot'>
                  <path d='M12 8V4H8'></path>
                  <rect width='16' height='12' x='4' y='8' rx='2'></rect>
                  <path d='M2 14h2'></path>
                  <path d='M20 14h2'></path>
                  <path d='M15 13v2'></path>
                  <path d='M9 13v2'></path>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>اطلب محاميا</span>
            </button>
            <button
              className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4'
              onClick={() => {
                navigate('/myfile')
              }}>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-file'>
                  <path d='M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z'></path>
                  <polyline points='14 2 14 8 20 8'></polyline>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>ملفاتي وتحديداتي</span>
            </button>
            <button
              className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4'
              onClick={() => {
                navigate('/comingsoon')
              }}>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-folder'>
                  <path d='M4 20h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.93a2 2 0 0 1-1.66-.9l-.82-1.2A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13c0 1.1.9 2 2 2Z'></path>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>الملفات القضائية</span>
            </button>
          </div>
          <div className='w-full border-t border-gray-300'></div>
          <div className='flex flex-col gap-2 w-full text-accent'>
            <div className='flex flex-row justify-between'>
              <div className='flex items-center gap-1 font-semibold'>
                <img alt='workspace-icon' loading='lazy' width='25' height='25' decoding='async' data-nimg='1' src={Workspace} style={{ color: 'transparent' }} />
                <font style={{ verticalAlign: 'inherit' }}>الافرقة</font>
              </div>
              <button
                type='button'
                className='inline-flex items-center justify-center text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none text-secondary focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-accent-foreground border-2 border-secondary border-accent-foreground bg-background h-9 rounded-md px-3 hover:bg-secondary hover:text-white'>
                <font style={{ verticalAlign: 'inherit' }}>اضافة</font>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-plus'>
                  <path d='M5 12h14'></path>
                  <path d='M12 5v14'></path>
                </svg>
              </button>
            </div>
            <ul className='list-disc flex flex-col py-2 gap-2 px-8'></ul>
          </div>
        </div>
        <div className='flex flex-col gap-2 items-center justify-center'>
          <div className='w-11/12 relative flex flex-col gap-2 bg-accent rounded-2xl p-4'>
            <img
              alt='circles'
              loading='lazy'
              width='500'
              height='500'
              decoding='async'
              data-nimg='1'
              className='absolute -right-16 z-0 h-36'
              src='/circles.svg'
              style={{ color: 'transparent', visibility: 'visible' }}
              data-xblocker='passed'
            />
            <div className='flex flex-col gap-2 text-white z-10'>
              <div className='rounded-md bg-white p-2 w-10 h-10'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='bg-accent rounded-full p-1'>
                  <path d='m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14'></path>
                </svg>
              </div>
              <div>
                <li className='text-right vertical-align-inherit list-none'>
                  <span className='align-middle'>اشتراكك الحالي:</span>
                </li>
                <span className='font-semibold'>مجاني</span>
              </div>
            </div>
            <div className='flex flex-col gap-2 w-full items-center justify-center z-10'>
              <button
                className='inline-flex items-center justify-center text-sm md:text-md h-12 px-10 py-4 md:py-6 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-white text-accent hover:bg-white/90 w-full font-semibold rounded-md'
                onClick={() => {
                  navigate('/pricing')
                }}>
                <font style={{ verticalAlign: 'inherit' }}>
                  <font style={{ verticalAlign: 'inherit' }}>الاشتراكات</font>
                </font>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>{dashnavbarShow && <Dashnavbar dashnavbarShow={dashnavbarShow} setDashnavbarShow={setDashnavbarShow} />}</div>
      <div className={`w-full h-screen flex flex-col gap-0 relative md:overflow-y-auto scrollbar ${dashnavbarShow ? 'backdrop-blur-3xl' : ''}`}>
        <div dir='rtl' className='px-3 pb-0 lg:pb-10 pt-10 lg:px-10 border-b border-gray-300'>
          <div className='w-full flex flex-row  items-center justify-between px-4 lg:hidden'>
            <button
              type='button'
              onClick={() => {
                setDashnavbarShow(!dashnavbarShow)
              }}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                className='h-8 w-8'>
                <line x1='4' x2='20' y1='12' y2='12'></line>
                <line x1='4' x2='20' y1='6' y2='6'></line>
                <line x1='4' x2='20' y1='18' y2='18'></line>
              </svg>
            </button>
            <div className='flex flex-row gap-8 text-gray-400'>
              <button type='button' id='radix-:r9:' onClick={toggleDash}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-user2'>
                  <circle cx='12' cy='8' r='5'></circle>
                  <path d='M20 21a8 8 0 1 0-16 0'></path>
                </svg>
              </button>
              {isDash && (
                <div className='fixed left-[0px] top-[70px] text-popover-foregroun  bg-white'>
                  <div
                    id='radix-:r1b:'
                    aria-labelledby='radix-:r1a:'
                    className='z-50 min-w-[8rem] overflow-hidden rounded-md border-border border bg-popover p-1 text-popover-foreground shadow-md w-56 forceRTL  "'
                    tabIndex='-1'
                    data-orientation='vertical'>
                    <div className='px-2 py-1.5 text-sm font-semibold '>
                      <font style={{ verticalAlign: 'inherit' }}>
                        <font style={{ verticalAlign: 'inherit' }}>حسابي</font>
                      </font>
                    </div>
                    <div role='separator' aria-orientation='horizontal' className='-mx-1 my-1 h-px bg-border'></div>
                    <div role='group'>
                      <button
                        onClick={() => {
                          navigate('/dashboard')
                        }}>
                        <div
                          role='menuitem'
                          className='relative cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:border focus:text-accent-foreground flex flex-row gap-2'
                          tabIndex='-1'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='ml-2 h-4 w-4'>
                            <rect width='20' height='14' x='2' y='5' rx='2'></rect>
                            <line x1='2' x2='22' y1='10' y2='10'></line>
                          </svg>
                          <span>
                            <font style={{ verticalAlign: 'inherit' }}>
                              <font style={{ verticalAlign: 'inherit' }}>لوحة القيادة</font>
                            </font>
                          </span>
                        </div>
                      </button>
                      <button
                        onClick={() => {
                          navigate('/setting/')
                        }}>
                        <div
                          role='menuitem'
                          className='relative cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:border focus:text-accent-foreground  flex flex-row gap-2'
                          tabIndex='-1'
                          data-orientation='vertical'
                          data-radix-collection-item=''>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='ml-2 h-4 w-4'>
                            <path d='M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z'></path>
                            <circle cx='12' cy='12' r='3'></circle>
                          </svg>
                          <span>
                            <font style={{ verticalAlign: 'inherit' }}>
                              <font style={{ verticalAlign: 'inherit' }}>الاعدادات</font>
                            </font>
                          </span>
                        </div>
                      </button>
                    </div>
                    <div role='separator' aria-orientation='horizontal' className='-mx-1 my-1 h-px bg-border'></div>
                    <div
                      role='menuitem'
                      className='relative cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:border focus:text-accent-foreground  flex flex-row gap-2'
                      tabIndex='-1'
                      onClick={logout}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='ml-2 h-4 w-4'>
                        <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4'></path>
                        <polyline points='16 17 21 12 16 7'></polyline>
                        <line x1='21' x2='9' y1='12' y2='12'></line>
                      </svg>
                      <span>
                        <font style={{ verticalAlign: 'inherit' }}>
                          <font style={{ verticalAlign: 'inherit' }}>تسجيل الخروج</font>
                        </font>
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                className='lucide lucide-bell'>
                <path d='M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9'></path>
                <path d='M10.3 21a1.94 1.94 0 0 0 3.4 0'></path>
              </svg>
            </div>
          </div>
          <div className='flex flex-col gap-4'>
            <div className='w-full flex flex-row justify-between'>
              <h1 className='font-bold text-lg p-4 lg:p-0 md:text-2xl lg:text-3xl'>
                <font style={{ verticalAlign: 'inherit' }}>
                  <font style={{ verticalAlign: 'inherit' }}>الرئيسية</font>
                </font>
              </h1>
              <div className='lg:flex flex-row gap-8 justify-center items-center text-gray-400  hidden'>
                <button type='button' id='radix-:rb:' onClick={toggleVisibility}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='lucide lucide-user2'>
                    <circle cx='12' cy='8' r='5'></circle>
                    <path d='M20 21a8 8 0 1 0-16 0'></path>
                  </svg>
                </button>
                {isVisible && (
                  <div className='fixed left-[30px] top-[70px] text-popover-foregroun  bg-white'>
                    <div
                      id='radix-:r1b:'
                      aria-labelledby='radix-:r1a:'
                      className='z-50 min-w-[8rem] overflow-hidden rounded-md border-border border bg-popover p-1 text-popover-foreground shadow-md w-56 forceRTL  "'
                      tabIndex='-1'
                      data-orientation='vertical'>
                      <div className='px-2 py-1.5 text-sm font-semibold '>
                        <font style={{ verticalAlign: 'inherit' }}>
                          <font style={{ verticalAlign: 'inherit' }}>حسابي</font>
                        </font>
                      </div>
                      <div role='separator' aria-orientation='horizontal' className='-mx-1 my-1 h-px bg-border'></div>
                      <div role='group'>
                        <button
                          onClick={() => {
                            navigate('/dashboard')
                          }}>
                          <div
                            role='menuitem'
                            className='relative cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:border focus:text-accent-foreground flex flex-row gap-2'
                            tabIndex='-1'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='ml-2 h-4 w-4'>
                              <rect width='20' height='14' x='2' y='5' rx='2'></rect>
                              <line x1='2' x2='22' y1='10' y2='10'></line>
                            </svg>
                            <span>
                              <font style={{ verticalAlign: 'inherit' }}>
                                <font style={{ verticalAlign: 'inherit' }}>لوحة القيادة</font>
                              </font>
                            </span>
                          </div>
                        </button>
                        <button
                          onClick={() => {
                            navigate('/setting/')
                          }}>
                          <div
                            role='menuitem'
                            className='relative cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:border focus:text-accent-foreground  flex flex-row gap-2'
                            tabIndex='-1'
                            data-orientation='vertical'
                            data-radix-collection-item=''>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='ml-2 h-4 w-4'>
                              <path d='M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z'></path>
                              <circle cx='12' cy='12' r='3'></circle>
                            </svg>
                            <span>
                              <font style={{ verticalAlign: 'inherit' }}>
                                <font style={{ verticalAlign: 'inherit' }}>الاعدادات</font>
                              </font>
                            </span>
                          </div>
                        </button>
                      </div>
                      <div role='separator' aria-orientation='horizontal' className='-mx-1 my-1 h-px bg-border'></div>
                      <div
                        role='menuitem'
                        className='relative cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:border focus:text-accent-foreground  flex flex-row gap-2'
                        tabIndex='-1'
                        onClick={logout}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='ml-2 h-4 w-4'>
                          <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4'></path>
                          <polyline points='16 17 21 12 16 7'></polyline>
                          <line x1='21' x2='9' y1='12' y2='12'></line>
                        </svg>
                        <span>
                          <font style={{ verticalAlign: 'inherit' }}>
                            <font style={{ verticalAlign: 'inherit' }}>تسجيل الخروج</font>
                          </font>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-bell'>
                  <path d='M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9'></path>
                  <path d='M10.3 21a1.94 1.94 0 0 0 3.4 0'></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <main dir='rtl'>
          <div className='container'>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 px-2 lg:pr-0 lg:pl-4 pt-8'>
              <div className='flex flex-col md:flex-row bg-background rounded-md shadow-sm'>
                <div className='bg-accent px-[2vw] text-white rounded-t-md md:rounded-s-md flex gap-1 py-8 md:py-0 items-center justify-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='h-10 w-10 md:h-16 md:w-16'>
                    <circle cx='11' cy='11' r='8'></circle>
                    <path d='m21 21-4.3-4.3'></path>
                  </svg>
                  <p className='text-primary font-semiBold inline text-center text-white md:hidden'>البحث في المستندات القانونية</p>
                </div>
                <div className='flex flex-col gap-2 py-4 px-8 justify-between'>
                  <div className='flex flex-col gap-2 text-foreground'>
                    <p className='font-bold hidden md:inline'>البحث في المستندات القانونية</p>
                    <div className='hidden md:inline border-b border-gray-300'></div>
                    <p className='font-medium text-xs lg:text-xs'>
                      ابحث في اكثر من ٤٠ الف مستند قانوني مُحدث ، باستخدام تطبيق أكثر من فلتر في نفس الوقت لتحسين دقة البحث وتحديد المستندات التي ترغب في العثور عليها ، قم بإدخال المعايير المطلوبة في
                      حقول البحث المناسبة وستتم عرض النتائج المناسبة وفقًا لتلك الفلاتر.
                    </p>
                  </div>
                  <div className='flex justify-end'>
                    <a href='/search'>
                      <button
                        className='text-sm md:text-md font-medium ring-offset-background text-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring 
                      focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-teal-900 glow-teal h-9 px-3 flex rounded-full flex-row gap-2 items-center justify-center'>
                        الانتقال للصفحة
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='h-5 w-5'>
                          <path d='m12 19-7-7 7-7'></path>
                          <path d='M19 12H5'></path>
                        </svg>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className='flex flex-col md:flex-row bg-background rounded-md shadow-sm'>
                <div className='bg-accent px-[2vw] text-white rounded-t-md md:rounded-s-md flex gap-1 py-8 md:py-0 items-center justify-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='h-10 w-10 md:h-16 md:w-16'>
                    <path d='M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z'></path>
                    <polyline points='14 2 14 8 20 8'></polyline>
                  </svg>
                  <p className='text-primary font-semiBold inline text-center text-white md:hidden'> ملفاتي وتحديداتي</p>
                </div>
                <div className='flex flex-col gap-2 py-4 px-8 justify-between'>
                  <div className='flex flex-col gap-2 text-foreground'>
                    <p className='font-bold hidden md:inline'>ملفاتي وتحديداتي</p>
                    <div className='hidden md:inline border-b border-gray-300'></div>
                    <p className='font-medium text-xs lg:text-xs'>امكانية إستيراد الملفات المختلفة في منصة عادل ، كما يتيح لك تنظيم و تخزين الملفات الخاصة بك وتحديد النصوص المهمة وتعديلها.</p>
                  </div>
                  <div className='flex justify-end'>
                    <a href='/myfile'>
                      <button className='text-sm md:text-md font-medium ring-offset-background  text-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-teal-900 glow-teal h-9 px-3 flex rounded-full flex-row gap-2 items-center justify-center'>
                        الانتقال للصفحة
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='h-5 w-5'>
                          <path d='m12 19-7-7 7-7'></path>
                          <path d='M19 12H5'></path>
                        </svg>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className='flex flex-col md:flex-row bg-background rounded-md shadow-sm'>
                <div className='bg-accent px-[2vw] text-white rounded-t-md md:rounded-s-md flex gap-1 py-8 md:py-0 items-center justify-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='h-10 w-10 md:h-16 md:w-16'>
                    <path d='M12 8V4H8'></path>
                    <rect width='16' height='12' x='4' y='8' rx='2'></rect>
                    <path d='M2 14h2'></path>
                    <path d='M20 14h2'></path>
                    <path d='M15 13v2'></path>
                    <path d='M9 13v2'></path>
                  </svg>
                  <p className='text-primary font-semiBold inline text-center text-white md:hidden'>اسأل عادل</p>
                </div>
                <div className='flex flex-col gap-2 py-4 px-8 justify-between'>
                  <div className='flex flex-col gap-2 text-foreground'>
                    <p className='font-bold hidden md:inline'>اسأل عادل</p>
                    <div className='hidden md:inline border-b border-gray-300'></div>
                    <p className='font-medium text-xs lg:text-xs'>
                      عادل سيساعدك لتحسين تجربة البحث وتقديم إجابات دقيقة وشاملة على جميع الأسئلة بسرعة ودقة عالية ، باستخدام“عادل“ يمكنك طرح أي سؤال قانوني واستعراض نتائج البحث المتعلقة بسهولة، سواء
                      كنت تحتاج إلى مستند قانوني محددأو ترغب في فهم مفهوم قانوني معين .
                    </p>
                  </div>
                  <div className='flex justify-end'>
                    <a href='/adel'>
                      <button className='text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2  text-white focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-teal-900 glow-teal h-9 px-3 flex rounded-full flex-row gap-2 items-center justify-center'>
                        الانتقال للصفحة
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='h-5 w-5'>
                          <path d='m12 19-7-7 7-7'></path>
                          <path d='M19 12H5'></path>
                        </svg>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className='flex flex-col md:flex-row bg-background rounded-md shadow-sm'>
                <div className='bg-accent px-[2vw] text-white rounded-t-md md:rounded-s-md flex gap-1 py-8 md:py-0 items-center justify-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='h-10 w-10 md:h-16 md:w-16'>
                    <path d='M4 20h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.93a2 2 0 0 1-1.66-.9l-.82-1.2A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13c0 1.1.9 2 2 2Z'></path>
                  </svg>
                  <p className='text-primary font-semiBold inline text-center text-white md:hidden'>الملفات القانونية</p>
                </div>
                <div className='flex flex-col gap-2 py-4 px-8 justify-between'>
                  <div className='flex flex-col gap-2 text-foreground'>
                    <p className='font-bold hidden md:inline'>الملفات القانونية</p>
                    <div className='hidden md:inline border-b border-gray-300'></div>
                    <p className='font-medium text-xs lg:text-xs'>
                      تعمل على تحسين دقة وجودة الملفات القانونية ، يمكن للمحامين تحميل ملفاتهم ، ومن ثم يتم تحليلها بشكل تلقائي للكشف عن الأخطاء الإملائية وتقديم تقييم شامل لإكتمالية الملف بناءً على
                      معايير محددة ، سنساعدك على تحسين جودة الوثائق القانونية وتقديمها بشكل متكامل.
                    </p>
                  </div>
                  <div className='flex justify-end'>
                    <a href='/comingsoon'>
                      <button className='text-sm md:text-md font-medium ring-offset-background text-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-teal-900 glow-teal h-9 px-3 flex rounded-full flex-row gap-2 items-center justify-center'>
                        الانتقال للصفحة
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='h-5 w-5'>
                          <path d='m12 19-7-7 7-7'></path>
                          <path d='M19 12H5'></path>
                        </svg>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <h3 className=' text-3xl font-bold pt-16'>الأخيرة</h3>
            <div className=' border-t border-gray-300 mt-4'>
              <a href='/document/zzxO4vxi08U5wukGY0Be'>
                <div className='rounded-lg border bg-card text-card-foreground w-full shadow-sm hover:shadow-lg py-2 my-4 border-gray-100 relative cursor-pointer' dir='rtl'>
                  <div className='flex flex-col space-y-1.5 p-6'>
                    <div className='text-2xl font-semibold leading-none tracking-tight flex flex-col md:flex-row gap-4 md:justify-between'>
                      <div>
                        <h1 className='text-xl'>القضية رقم ١٢٧٨٢ لعام ١٤٤٠ هـ</h1>
                      </div>
                      <div className='flex flex-row gap-2'>
                        <div className='text-accent text-center text-xs bg-[#A5E0D7] p-2 rounded-md '>احكام قرارات</div>
                      </div>
                    </div>
                    <p className='text-sm text-foreground line-clamp-3'></p>
                  </div>
                  <div className='flex flex-row gap-2 px-6 flex-wrap'></div>
                </div>
              </a>
              <a href='/document/zzxO4vxi08U5wukGY0Be'>
                <div className='rounded-lg border bg-card text-card-foreground w-full shadow-sm hover:shadow-lg py-2 my-4 border-gray-100 relative cursor-pointer' dir='rtl'>
                  <div className='flex flex-col space-y-1.5 p-6'>
                    <div className='text-2xl font-semibold leading-none tracking-tight flex flex-col md:flex-row gap-4 md:justify-between'>
                      <h1 className='text-xl'>القضية رقم ١٢٧٨٢ لعام ١٤٤٠ هـ</h1>
                      <div className='flex flex-row gap-2'>
                        <div className='text-accent text-center text-xs bg-[#A5E0D7] p-2 rounded-md'>احكام قرارات</div>
                      </div>
                    </div>
                    <p className='text-sm text-foreground line-clamp-3'></p>
                  </div>
                  <div className='flex flex-row gap-2 px-6 flex-wrap'></div>
                </div>
              </a>
              <a href='/document/zzxO4vxi08U5wukGY0Be'>
                <div className='rounded-lg border bg-card text-card-foreground w-full shadow-sm hover:shadow-lg py-2 my-4 border-gray-100 relative cursor-pointer' dir='rtl'>
                  <div className='flex flex-col space-y-1.5 p-6'>
                    <div className='text-2xl font-semibold leading-none tracking-tight flex flex-col md:flex-row gap-4 md:justify-between'>
                      <h1 className='text-xl'>القضية رقم ١٢٧٨٢ لعام ١٤٤٠ هـ</h1>
                      <div className='flex flex-row gap-2'>
                        <div className='text-accent text-center text-xs bg-[#A5E0D7] p-2 rounded-md'>احكام قرارات</div>
                      </div>
                    </div>
                    <p className='text-sm text-foreground line-clamp-3'></p>
                  </div>
                  <div className='flex flex-row gap-2 px-6 flex-wrap'></div>
                </div>
              </a>
              {/* Lists */}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
export default Dashboard
