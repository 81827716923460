import React from 'react'
import Logo_white from '../../assests/logo-white.svg'
import { useNavigate } from 'react-router-dom'

const Comingsoon = () => {
  const navigate = useNavigate('')
  return (
    <div className='flex flex-col  min-h-screen  relative overflow-hidden '>
      <div className='box-shadow-secondary-1 w-[40rem] absolute h-[20vh] -top-0 -left-[16rem] bg-secondary1  '></div>
      <div className='box-shadow-secondary-1 w-[40rem] absolute h-[20vh] -bottom-0 -right-[16rem] bg-secondary1 '></div>
      <div className='flex items-center justify-center bg-teal-900 p-7 relative z-10'>
        <button
          className='text-white flex flex-row items-center justify-center absolute top-5 left-5 md:left-10'
          onClick={() => {
            navigate('/dashboard')
          }}>
          <div className='text-lg text-inherit font-semibold'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='40'
              height='40'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='lucide lucide-arrow-left'>
              <path d='m12 19-7-7 7-7'></path>
              <path d='M19 12H5'></path>
            </svg>
          </div>
        </button>
        <a className='logo-link flex items-center justify-center' href='/'>
          <img alt='logo' loading='lazy' width='100' height='100' decoding='async' src={Logo_white} />
        </a>
      </div>
      <div className='h-[90vh] w-full flex flex-col items-center justify-center gap-8 '>
        <h1 className='z-10 font-semibold text-4xl md:text-5xl lg:text-7xl text-center flex flex-row gap-1 items-center justify-center'>
          قريبا....
        </h1>
        <button
          className='inline-flex items-center justify-center rounded-md text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-accent text-secondary-foreground hover:bg-accent/60 h-12 px-10 py-4 md:py-6'
          onClick={() => {
            navigate('/')
          }}>
          العودة إلى الصفحة الرئيسية
        </button>
      </div>
    </div>
  )
}
export default Comingsoon
