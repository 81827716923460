// import Logo from '../../assests/logo-white.svg'
import { React, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useNavbar } from '../../context/NavbarContext'
import { AuthContext } from '../../context/AuthContext'
import { UserContext } from '../../context/UserContext'
import { onboarding } from '../../Route/APIRoute'
import mobile_menu from './mobile_menu'
import logo1 from '../../assests/Landing/logo1.png'
import headerMenu_mobile from '../../assests/Landing/headerMenu_mobile.png'
import facebook from '../../assests/Landing/facebook.png'
import twitter from '../../assests/Landing/twitter.png'
import likedin from '../../assests/Landing/likedin.png'
import close_icon from '../../assests/Icons/mobile_menu.svg'
import Menubar from './menubar'
function Header() {
  const { navbarShow, setNavbarShow } = useNavbar()
  const { user, logout } = useContext(AuthContext)
  const navigate = useNavigate('')
  return (
    <>
      {/* navbar */}
      <Menubar showMenu={navbarShow} setShowMenu={setNavbarShow} user={user} logout={logout} />
      <header className='w-full flex grid-cols-3 bg-[#224B37] '>
        <div className='container px-0'>
          <div className='relative max-sm:hidden mx-auto flex items-center justify-between'>
            {user?.token ? (
              <button
                className='border border-white w-[100px] flex items-center justify-center h-[42px] text-white px-2 rounded-[10px] hover:bg-white hover:text-green-900 transition pb-[5px]'
                onClick={logout}>
                تسجيل خروج
              </button>
            ) : (
              <div>
                <button
                  className='text-[18px] border border-white min-w-[125px] text-white py-2 px-2 rounded-[10px] hover:bg-white hover:text-green-900 transition'
                  onClick={() => {
                    navigate('/register')
                  }}>
                  يسجل
                </button>
                <button
                  className='text-[18px] border border-white min-w-[125px] text-white py-2 px-2 rounded-[10px] hover:bg-white hover:text-green-900 transition mr-[30px]'
                  onClick={() => {
                    navigate('/login')
                  }}>
                  تسجيل الدخول
                </button>
              </div>
            )}
            <div className='flex w-[65%] justify-center items-center'>
              <nav className='flex items-center gap-[6rem] text-white' dir='ltr'>
                <button
                  className='hover:text-gray-400 text-[18px]'
                  onClick={() => {
                    navigate('/')
                  }}>
                  الرئيسية
                </button>

                <a href='#' className='hover:text-gray-400 text-[18px]'>
                  الفريق
                </a>
                <a href='#' className='hover:text-gray-400 text-[18px]'>
                  عن عدالة
                </a>
                <a href='#' className='hover:text-gray-400 text-[18px]'>
                  الأخبار
                </a>
              </nav>
            </div>
            <button
              className='py-[25px]'
              onClick={() => {
                navigate('/')
              }}>
              <img src={logo1} alt='logo1' />
            </button>
          </div>
          <div className='h-[90px] md:hidden w-full flex relative bg-[#224B37]'>
            <button
              className='h-[22px] w-[35px] absolute right-[20px] top-[32px]'
              onClick={() => {
                setNavbarShow(true)
              }}>
              <img src={headerMenu_mobile} alt='headerMenu_mobile' />
            </button>
            <button
              className='h-[72px] w-[144px] left-[2%] top-[10px] absolute'
              onClick={() => {
                navigate('/')
              }}>
              <img src={logo1} alt='logo1' />
            </button>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
