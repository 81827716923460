import React from 'react'
const Coupon = () => {
  return (
    <div>
      <div
        tabIndex='0'
        className='ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 flex flex-col items-center gap-4 py-4 md:px-6 m-0'
        dir='rtl'
        style={{ animationDuration: '0s' }}>
        <div className='w-[90%] md:w-full flex flex-row rounded-lg gap-4 bg-accent  bg-repeat bg-cover'>
          <div className='flex w-full flex-col gap-2 items-center justify-center p-4'>
            <div className='text-white font-bold'>الكوبونات</div>
            <input className='flex h-12 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 w-full' />
            <button className='inline-flex items-center justify-center text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 border-2 border-accent-foreground  hover:bg-accent hover:text-accent-foreground h-12 px-10 py-4 md:py-6 text-secondary rounded-md bg-white'>
              تفعيل
            </button>
          </div>
          <div className='border-l-2 border-dashed border-gray-300'></div>
          <div className='w-1/4 flex items-center justify-center text-white text-7xl'>%</div>
        </div>
        <div className='py-4 px-6 w-full h-full'>
          <div className='flex flex-col gap-4 w-full'>
            <div className='rounded-3xl p-4 bg-white overflow-hidden'>
              <div className='relative w-full overflow-auto'>
                <table className='w-full caption-bottom text-sm' dir='rtl'>
                  <thead className='[&amp;_tr]:border-b'>
                    <tr className='border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted'>
                      <th className='h-12 align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0 text-center p-4'>
                        <div className='font-bold text-base'>رقم</div>
                      </th>
                      <th className='h-12 align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0 text-center p-4'>
                        <div className='font-bold text-base'>رمز الكوبون</div>
                      </th>
                      <th className='h-12 align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0 text-center p-4'>
                        <div className='font-bold text-base'>تاريخ الاستخدام</div>
                      </th>
                      <th className='h-12 align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0 text-center p-4'>
                        <div className='font-bold text-base'>مدة الاستخدام</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='[&amp;_tr:last-child]:border-0 text-sm text-center scroll'>
                    <tr className='border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted'>
                      <td className='p-4 align-middle [&amp;:has([role=checkbox])]:pr-0 h-24 text-center'></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='w-full'>
              <div className='w-full flex flex-wrap gap-4 items-center justify-center md:justify-between'>
                <div className='flex flex-row flex-wrap justify-center items-center gap-4'>
                  <div className='flex items-center gap-2'>
                    <button
                      className='inline-flex items-center justify-center rounded-full text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-accent-foreground border-2 border-accent-foreground bg-background hover:bg-accent hover:text-accent-foreground md:py-6 h-10 w-10 p-0'
                      disabled=''>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='lucide lucide-chevron-right'>
                        <path d='m9 18 6-6-6-6'></path>
                      </svg>
                    </button>
                    {/* Include the rest of your buttons */}
                  </div>
                  <div className='flex items-center justify-center text-sm font-medium gap-2'>
                    <div>صفحة 1 ل 0</div>
                  </div>
                </div>
                <div className='text-center md:text-right text-sm text-accent'>
                  <div>0 ل 0 الصف (الصفوف) المحدد.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Coupon
