import APP_HOST from './envVariables'

export const loginRoute = `${APP_HOST}/api-login`
export const registerRoute = `${APP_HOST}/signup`
export const userAccount = `${APP_HOST}/api-userAccount`
export const phonefetch = `${APP_HOST}/api-phonefetch`
export const userfetch = `${APP_HOST}/api-userfetch`
export const otpRoute = `${APP_HOST}/send-otp`
export const onboarding = `${APP_HOST}/api-onboardibg`
export const updateAccount = `${APP_HOST}/api-updateAccount`
export const updateUser = `${APP_HOST}/api-updateUser`
export const username = `${APP_HOST}/api-username`
export const adal_stream = `${APP_HOST}/api/stream`
export const fileupload = `${APP_HOST}/api-upload`
export const fetchpassword = `${APP_HOST}/api-fetchpassword`
