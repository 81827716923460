import { React, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import useWindowSize from '../component/windowssize'
import Header from '../component/layout/header'
import Footer from '../component/layout/footer'
import { AuthContext } from '../context/AuthContext'
import { useNavbar } from '../context/NavbarContext'
import Frame from '../assests/AI/Frame.png'
import Mobile_background from '../assests/AI/Mobile_background.png'
import Ai_vector from '../assests/AI/Ai_vector.png'
import Ai_mobile from '../assests/AI/Ai_mobile.png'
import Menubar from '../component/layout/menubar'
import logo1 from '../assests/Landing/logo1.png'
import headerMenu_mobile from '../assests/Landing/headerMenu_mobile.png'
const Lawyer = () => {
  const { width } = useWindowSize()
  const isMobile = width < 768
  const navigate = useNavigate('')
  const { navbarShow, setNavbarShow } = useNavbar()
  const { logout } = useContext(AuthContext)
  return (
    <div className='h-[100%] w-full overflow-x-hidden bg-white scrollbar'>
      {/* Header */}
      <div className='sm:hidden'>
        <Header />
      </div>
      {!navbarShow && (
        <div className='relative bg-cover bg-center h-screen bg-no-repeat' style={{ backgroundImage: `url(${isMobile ? Mobile_background : Frame})`, height: isMobile ? '630px' : '100vh' }}>
          <div className='hidden md:flex w-full top-0 left-0 mx-auto items-end justify-between px-20'>
            <div className='flex justify-center items-center text-white gap-[2rem] pb-5'>
              <button onClick={logout} className='hover:text-gray-400 text-[18px] border border-white text-white py-2 px-2 rounded-[10px] hover:bg-white hover:text-green-900 transition min-w-[125px]'>
                تسجيل خروج
              </button>
            </div>
            <div className='flex w-[70%] justify-center items-center pb-5'>
              <nav className='flex items-center gap-[6rem] text-white' dir='ltr'>
                <button
                  className='hover:text-gray-400 text-[18px]'
                  onClick={() => {
                    navigate('/')
                  }}>
                  الرئيسية
                </button>
                <a href='#' className='hover:text-gray-400 text-[18px]'>
                  الفريق
                </a>
                <a href='#' className='hover:text-gray-400 text-[18px]'>
                  عن عدالة
                </a>
                <a href='#' className='hover:text-gray-400 text-[18px]'>
                  الأخبار
                </a>
              </nav>
            </div>
            <div className='pt-62'>
              <button
                onClick={() => {
                  navigate('/')
                }}>
                <img src={logo1} alt='logo1' />
              </button>
            </div>
          </div>

          {/* desktop container */}
          <div className='relative max-sm:hidden w-full h-[50%] flex flex-col mt-[180px]'>
            <div className='w-full h-full justify-center flex'>
              <div className='w-[80%] flex justify-between items-center'>
                <div>
                  <div className='text-96 text-transparent bg-clip-text from-[#55C18D] to-[#156896] gradient-ai font-bold'>عدالة AI</div>
                  <div className='text-[64px] text-[#FFFFFF]'>ابدء تجربة جديدة مع</div>
                  <div className='text-[24px] text-[#B9B9B9] mt-[10px]'>ل مساعد قانوني مدعوم بالذكاء الإصطناعي</div>
                  <button
                    className='w-[320px] h-[56px] bg-[#FFFFFF] rounded-[15px] flex justify-center items-center text-[24px] mt-[30px]'
                    onClick={() => {
                      navigate('/chatbot')
                    }}>
                    <span className='h-[32px]'>ابدء الأن</span>
                  </button>
                  <div className='w-[320px] flex justify-center items-center'>
                    <button className='w-[130px] h-[40px] bg-[#224B37] text-[20px] text-[white] mt-[20px] rounded-[20px] flex justify-center items-center'>رجوع</button>
                  </div>
                </div>
                <div className='bg-cover bg-center h-screen bg-no-repeat' style={{ backgroundImage: `url(${Ai_vector})`, height: '320px', width: '320px' }}></div>
              </div>
            </div>
          </div>
          {/* mobile container */}
          <div className='sm:hidden'>
            <div className='flex flex-col h-full justify-center items-center pt-[50px]'>
              <div className='relative bg-cover bg-center h-screen bg-no-repeat' style={{ backgroundImage: `url(${Ai_mobile})`, height: '112px', width: '112px' }}></div>
              <div className='text-[40px] text-transparent font-bold bg-clip-text bg-gradient-to-r from-[#55C18D] to-[#156896] mt-[20px]'>عدالة AI</div>
              <div className='flex flex-col justify-center items-center mt-[25px]'>
                <div className='text-[24px] text-white'> ابدء تجربة جديدة مع</div>
                <div className='text-[16px] text-[#B9B9B9]'>اول مساعد قانوني مدعوم بالذكاء</div>
                <div className='text-[16px] text-[#B9B9B9]'>بالذكاء الإصطناعي</div>
              </div>
              <button
                className='w-[192px] h-[34px] rounded-[8px] bg-[#FFFFFF] text-[#323232] flex justify-center items-center mt-[50px]'
                onClick={() => {
                  navigate('/chatbot')
                }}>
                ابدء الأن
              </button>
              <div className='w-[84px] h-[25px] text-[11px] bg-[#224B37] text-white rounded-[20px] flex justify-center items-center mt-[20px]'>رجوع</div>
            </div>
          </div>
        </div>
      )}
      {!navbarShow && <Footer />}
    </div>
  )
}
export default Lawyer
