import { useState, useEffect, React } from 'react'
import PropTypes from 'prop-types'
import Logo_green from '../../assests/logo-green.svg'
import Workspace from '../../assests/workspace.svg'

const Dashnavbar = ({ dashnavbarShow, setDashnavbarShow }) => {
  const [animateOut, setAnimateOut] = useState(false)

  useEffect(() => {
    if (!dashnavbarShow && animateOut) {
      const timer = setTimeout(() => {
        setAnimateOut(false)
      }, 2000) // Adjust this duration to match your fadeOutRight animation duration
      return () => clearTimeout(timer)
    }
  }, [dashnavbarShow, animateOut])
  const handleClose = () => {
    setAnimateOut(true)
    setTimeout(() => {
      setDashnavbarShow(false)
    }, 500) // Adjust this duration to match your fadeOutRight animation duration
  }
  return (
    <>
      {/* {(dashnavbarShow || animateOut) && ( */}
      <div
        role='dialog'
        id='radix-:r17:'
        className={`fixed z-50 gap-4 bg-background p-6 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500 inset-y-0 right-0 h-full data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm w-full flex flex-col justify-between py-8 border-0 overflow-y-auto bg-white scrollbar  ${
          animateOut ? 'animate-fadeOutRight' : 'animate-fadeInRight'
        }`}>
        <div className='flex flex-col w-full items-center gap-6 px-2'>
          <a className='pb-5 self-center border-b w-full border-gray-300 flex place-content-center' href='/'>
            <img
              alt='logo'
              loading='lazy'
              width='150'
              height='150'
              decoding='async'
              data-nimg='1'
              className='w-32'
              src={Logo_green}
              style={{ color: 'transparent', visibility: 'visible' }}
              data-xblocker='passed'
            />
          </a>
          <div className='flex flex-col gap-[2vh] items-start w-3/4 lg:w-full'>
            <a className='bg-accent shadow flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4' href='/dashboard'>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-home'>
                  <path d='m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z'></path>
                  <polyline points='9 22 9 12 15 12 15 22'></polyline>
                </svg>
              </span>
              <span className='text-white text-sm font-semibold whitespace-nowrap'>الرئيسية</span>
            </a>
            <a className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4' href='/search'>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-search'>
                  <circle cx='11' cy='11' r='8'></circle>
                  <path d='m21 21-4.3-4.3'></path>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>البحث </span>
            </a>
            <a className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4' href='/adel'>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-bot'>
                  <path d='M12 8V4H8'></path>
                  <rect width='16' height='12' x='4' y='8' rx='2'></rect>
                  <path d='M2 14h2'></path>
                  <path d='M20 14h2'></path>
                  <path d='M15 13v2'></path>
                  <path d='M9 13v2'></path>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>اسأل عادل</span>
            </a>
            <a className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4' href='/lawyer'>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-bot'>
                  <path d='M12 8V4H8'></path>
                  <rect width='16' height='12' x='4' y='8' rx='2'></rect>
                  <path d='M2 14h2'></path>
                  <path d='M20 14h2'></path>
                  <path d='M15 13v2'></path>
                  <path d='M9 13v2'></path>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>اطلب محاميا</span>
            </a>
            <a className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4' href='/myfile'>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-file'>
                  <path d='M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z'></path>
                  <polyline points='14 2 14 8 20 8'></polyline>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>ملفاتي وتحديداتي</span>
            </a>
            <a className='text-gray-400 hover:bg-accent group hover:shadow-lg flex flex-row gap-4 items-center text-center rounded-2xl py-2 w-full px-4' href='/comingsoon'>
              <span className='bg-white text-accent rounded-2xl px-2 py-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-folder'>
                  <path d='M4 20h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.93a2 2 0 0 1-1.66-.9l-.82-1.2A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13c0 1.1.9 2 2 2Z'></path>
                </svg>
              </span>
              <span className='text-accent group-hover:text-white text-sm font-semibold whitespace-nowrap'>الملفات القضائية</span>
            </a>
          </div>
          <div className='w-full border-t border-gray-300'></div>
          <div className='flex flex-col gap-2 w-full text-accent'>
            <div className='flex flex-row justify-between'>
              <div className='flex items-center gap-1 font-semibold'>
                <img alt='workspace-icon' loading='lazy' width='25' height='25' decoding='async' data-nimg='1' src={Workspace} style={{ color: 'transparent' }} />
                <font style={{ verticalAlign: 'inherit' }}>الافرقة</font>
              </div>
              <button
                type='button'
                className='inline-flex items-center justify-center text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none text-secondary focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-accent-foreground border-2 border-secondary border-accent-foreground bg-background h-9 rounded-md px-3 hover:bg-secondary hover:text-white'>
                <font style={{ verticalAlign: 'inherit' }}>اضافة</font>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-plus'>
                  <path d='M5 12h14'></path>
                  <path d='M12 5v14'></path>
                </svg>
              </button>
            </div>
            <ul className='list-disc flex flex-col py-2 gap-2 px-8'></ul>
          </div>
        </div>
        <div className='flex flex-col gap-2 items-center justify-center'>
          <div className='w-11/12 relative flex flex-col gap-2 bg-accent rounded-2xl p-4'>
            <img
              alt='circles'
              loading='lazy'
              width='500'
              height='500'
              decoding='async'
              data-nimg='1'
              className='absolute -right-16 z-0 h-36'
              src='/circles.svg'
              style={{ color: 'transparent', visibility: 'visible' }}
              data-xblocker='passed'
            />
            <div className='flex flex-col gap-2 text-white z-10'>
              <div className='rounded-md bg-white p-2 w-10 h-10'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='bg-accent rounded-full p-1'>
                  <path d='m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14'></path>
                </svg>
              </div>
              <div>
                <li className='text-right vertical-align-inherit list-none'>
                  <span className='align-middle text'>اشتراكك الحالي:مجاني</span>
                </li>
              </div>
            </div>
            <div className='flex flex-col gap-2 w-full items-center justify-center z-10'>
              <a
                className='inline-flex items-center justify-center text-sm md:text-md h-12 px-10 py-4 md:py-6 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-white text-accent hover:bg-white/90 w-full font-semibold rounded-md'
                href='/pricing'>
                <font style={{ verticalAlign: 'inherit' }}>
                  <font style={{ verticalAlign: 'inherit' }}>الاشتراكات</font>
                </font>
              </a>
            </div>
          </div>
        </div>
        <button
          type='button'
          className='absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary'
          onClick={handleClose}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='h-4 w-4'>
            <path d='M18 6 6 18'></path>
            <path d='m6 6 12 12'></path>
          </svg>
          <span className='sr-only'>Close</span>
        </button>
      </div>
      {/* )} */}
    </>
  )
}
Dashnavbar.propTypes = {
  dashnavbarShow: PropTypes.any.isRequired,
  setDashnavbarShow: PropTypes.func.isRequired
}
export default Dashnavbar
