import { React, useState } from 'react'
import Logo from '../../../assests/logo-white.svg'
import Logo1 from '../../../assests/onboarding/logo(1).svg'
import Onboarding1 from '../../../assests/onboarding/onboarding1.webp'
import Onboarding2 from '../../../assests/onboarding/onboarding2.webp'
import Step2 from './step2'
import PropTypes from 'prop-types'

const step1 = ({ selectedUsertype, setSelectedUsertype, setStep, selectedAccountType, setSecondAccount }) => {
  const [userTypeError, setUserTypeError] = useState('')
  const handleValidation = () => {
    if (!selectedUsertype) {
      setUserTypeError('نوع المستخدم مطلوب') // Set error message if user type is not selected
      return false // Return false to indicate validation failure
    } else {
      setUserTypeError('') // Clear error message if user type is selected
      return true // Return true to indicate validation success
    }
  }

  const handleNextButtonClick = () => {
    const isValid = handleValidation()

    if (isValid) {
      setSecondAccount(prev => ({
        ...prev,
        selectedUsertype: selectedUsertype
      }))
      // Proceed to next step if validation is successful
      setStep(prev => prev + 1)
    }
  }
  return (
    <>
      <div className='flex flex-row'>
        <div className='w-full lg:w-1/2'>
          <div className='container py-6 lg:py-8 text-center inline-block lg:hidden'>
            <img
              alt='logo'
              loading='lazy'
              width='100'
              height='100'
              decoding='async'
              data-nimg='1'
              className='inline-flex'
              src={Logo}
              style={{ color: 'transparent' }}
            />
          </div>
          <div className='flex items-center justify-center px-4 lg:px-0 min-h-[80vh] lg:min-h-screen'>
            <div className='max-w-xl w-full flex justify-center'>
              <div className='rounded-lg text-card-foreground w-full shadow-none border-0 bg-inherit'>
                <div className='flex flex-col space-y-1.5 p-6'>
                  <h3 className='text-2xl font-semibold leading-none tracking-tight text-color2 py-8'>نوع المستخدم</h3>
                </div>
                <div className='p-6 pt-0'>
                  <form>
                    <div className='flex flex-col p-6 pt-0'>
                      <div className='space-y-2'>
                        <span
                          className={`text-sm font-medium   leading-none ${
                            userTypeError ? 'text-red-700' : 'text-color2'
                          }`}
                          htmlFor=':r3:-form-item'>
                          نوع المستخدم
                        </span>
                        <label>
                          {selectedAccountType === 'individual' ? (
                            <select
                              defaultValue=''
                              className='flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50
                              position: absolute; border: 0px; width: 1px; height: 1px; padding: 0px; margin: -3px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); white-space: nowrap; overflow-wrap: normal;  text-color2 my-[7px] '
                              onChange={e => {
                                setSelectedUsertype(e.target.value)
                              }}>
                              <option value='' disabled className='text-color2 font-bold'>
                                نوع المستخدم
                              </option>
                              <option value='Trainee Lawyer' className='text-color2'>
                                محامي متدرب
                              </option>
                              <option value='Licensed Lawyer' className=' text-color2 '>
                                محامي مرخص
                              </option>
                              <option value='Student' className=' text-color2 '>
                                طالب
                              </option>
                              <option value='Legal Consultant' className=' text-color2 '>
                                مستشار قانوني
                              </option>
                              <option value='Academic' className=' text-color2 '>
                                اكاديمي
                              </option>
                              <option value='HR Manager' className=' text-color2 '>
                                مسؤول موارد بشرية
                              </option>
                              <option value='Others' className=' text-color2 '>
                                اخرى
                              </option>
                            </select>
                          ) : (
                            <select
                              className='flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50
                    position: absolute; border: 0px; width: 1px; height: 1pfx; padding: 0px; margin: -1px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); white-space: nowrap; overflow-wrap: normal;  text-color2 '
                              onChange={e => {
                                setSelectedUsertype(e.target.value)
                              }}>
                              <option value='' disabled className=' text-color2 text-bold '>
                                <b>نوع المستخدم</b>
                              </option>
                              <option value='Law Firm' className='text-color2'>
                                مكتب محاماة
                              </option>
                              <option value='Legal Department' className=' text-color2 '>
                                ادارة قانونية
                              </option>
                              <option value='Company Owner/Manager' className=' text-color2 '>
                                مالك مدير شركة
                              </option>
                            </select>
                          )}
                        </label>
                      </div>
                      {userTypeError && <p className='text-red-700'>{userTypeError}</p>}
                    </div>
                    <div className='flex justify-center relative'>
                      <button
                        className='items-center justify-center rounded-full text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-teal-900 glow-teal h-12 px-10 py-4 md:py-6 absolute -left-10 -bottom-60 flex flex-row gap-2 text-white'
                        type='button'
                        onClick={() => setStep(prev => prev - 1)}>
                        العودة
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='w-5 h-5 text-white'>
                          <path d='m12 19-7-7 7-7'></path>
                          <path d='M19 12H5'></path>
                        </svg>
                      </button>
                      <button
                        className='inline-flex items-center justify-center md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-teal-900 glow-teal h-12 px-10 py-4 md:py-6 rounded-md relative text-lg w-4/5 text-white'
                        type='button'
                        onClick={handleNextButtonClick}>
                        التالي
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='absolute right-5 text-white'>
                          <path d='M5 12h14'></path>
                          <path d='m12 5 7 7-7 7'></path>
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='hidden lg:flex lg:w-1/2 min-h-screen bg-accent p-12  flex-col items-center relative'>
          <div className=' '>
            <img
              alt='logo'
              loading='lazy'
              width='100'
              height='100'
              decoding='async'
              data-nimg='1'
              src={Logo1}
              style={{ color: 'transparent' }}
            />
          </div>
          <div className='flex flex-col gap-2 items-end  justify-center w-full h-4/5'>
            <div className=' relative  pl-[2vw]'>
              <img
                alt='logo'
                loading='lazy'
                width='400'
                height='400'
                decoding='async'
                data-nimg='1'
                className=' z-20 relative w-[25vw]'
                srcSet={`${Onboarding2}?w=640&q=75 1x, ${Onboarding2}?w=828&q=75 2x`}
                src={Onboarding2}
                style={{ color: 'transparent', visibility: 'visible' }}
                data-xblocker='passed'
              />
              <img
                alt='logo'
                loading='lazy'
                width='400'
                height='400'
                decoding='async'
                data-nimg='1'
                className=' absolute top-[10vw] left-[18vw] z-10 w-[25vw]'
                srcSet={`${Onboarding1}?w=640&q=75 1x, ${Onboarding1}?w=828&q=75 2x`}
                src={Onboarding1}
                style={{ color: 'transparent', visibility: 'visible' }}
                data-xblocker='passed'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

step1.propTypes = {
  selectedUsertype: PropTypes.any.isRequired,
  setSelectedUsertype: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  selectedAccountType: PropTypes.any.isRequired,
  setSecondAccount: PropTypes.func.isRequired
}

export default step1
