import React from 'react';
import Logo from '../../assests/Landing/logo2.png'
const Loading = () => {
    return (
        <div className='min-h-[100vh] flex justify-center items-center'>
            <div className="outer-circle">
                <div className="inner-circle">
                    <div className='flex justify-center items-center w-full h-full'>
                        <img src={Logo} className='w-[150px]'/>
                    </div>
                </div>
            </div>
        </div>
    )
}   

export default Loading