import React, { useEffect, useState } from 'react'
import personal_picture from '../../assests/personal_picture.webp'
import VerificationInput from 'react-verification-input'
import useWindowSize from '../windowssize'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { updateAccount, updateUser, userfetch } from '../../Route/APIRoute'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { fileupload, fetchpassword, otpRoute } from '../../Route/APIRoute'

const general = () => {
  const [visible, setVisible] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [avatarUrl, setAvatarUrl] = useState('')
  const [avatar, setAvatar] = useState('')
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [nickname, setNickname] = useState('')
  const [email, setEmail] = useState('')
  const [imageurl, setImageurl] = useState('')
  const [phonenumber, setPhonenumber] = useState('')
  const [oldpassword, setOldpassword] = useState('')
  const [newpassword, setNewpassword] = useState('')
  const [file, setFile] = useState(null)
  const [isUploaded, setIsUploaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [placeholder, setPlaceholder] = useState('')
  const [phoneOTP, setPhoneOTP] = useState(false)
  const [user, setUser] = useState({
    userid: '',
    email: '',
    nickname: '',
    name: '',
    phonenumber: '',
    password: ''
  })
  const { width } = useWindowSize()
  const toastStyles = {
    success: {
      background: 'green'
    },
    error: {
      background: 'red'
    }
  }
  const handleInputChange = value => {
    // Update the verification code state
    setVerificationCode(value)
    // You can perform additional actions if needed
    console.log(`Verification code changed: ${value}`)
  }
  const userid = localStorage.getItem('Userid')
  useEffect(() => {
    const fetchData = async () => {
      try {
        const accountResponse = await fetch(userfetch, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userid })
        })

        const accountData = await accountResponse.json()
        setNickname(accountData.user.nickname)
        setName(accountData.user.name)
        setUser(accountData.user)
        const storedDataURL = localStorage.getItem('avatarDataURL')
        if (storedDataURL) {
          setAvatarUrl(storedDataURL)
          setIsUploaded(true)
        }
      } catch (error) {
        console.log('Error:', error)
      }
    }

    fetchData()
  }, [])

  const handleNameUpdate = async () => {
    // Gather data from input fields
    const updatedName = name
    const updatedNickName = nickname
    // Update user object with new data
    const updatedUser = { ...user, name: updatedName, nickname: updatedNickName }
    try {
      // Send updated user data to the server
      const response = await axios.post(updateAccount, updatedUser)
      if (response.status === 200) {
        const data = response.data
        setNickname(data.nickname)
        setName(data.name)
        // Handle server response if needed
        toast.success('تم تحديث معلومات المستخدم بشكل صحيح.')
      }
    } catch (error) {
      console.error('Error updating user:', error)
    }
  }
  const handleEmailPasswordUpdate = async () => {
    let flag = 0
    try {
      const response = await fetch(fetchpassword, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ oldpassword: oldpassword })
      })

      if (response.status === 200) {
        flag = 1
        console.log(flag)

        // If old password is confirmed, proceed with updating email and password
        if (flag === 1) {
          try {
            // Send updatedUser data to the server
            const updatedUser = { ...user, email: email, password: newpassword }
            const response = await axios.post(updateUser, updatedUser)

            // Handle server response if needed
            toast.success('تم تحديث معلومات المستخدم بشكل صحيح.')
          } catch (error) {
            console.error('Error updating email and password:', error)
          }
        }
      } else if (response.status === 404) {
        // Display warning toast for incorrect old password
        toast.warning('يرجى التأكد من إدخال كلمات المرور القديمة، ثم إدخال كلمات المرور الجديدة وعناوين البريد الإلكتروني.')
      } else {
        // Handle other response statuses if needed
        console.error('Unexpected response status:', response.status)
      }
    } catch (error) {
      toast.error('خطأ في الخادم الداخلي.')
    }
  }
  const handleOTPVerification = async () => {
    try {
      const response = await fetch(otpRoute, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ verificationCode })
      })
      const data = await response.json()
      if (response.status === 200) {
        toast('التحقق الخاص بك هو النجاح.')
        setVisible(false)
        setPhoneOTP(true)
      } else {
        if (response.status === 403) {
          toast.warning('Maximum OTP verification attempts exceeded. Please re-register..')
        } else {
          toast.warning('OTP is incorrect. Please enter correct OTP.')
          const responseData = await response.json()
        }
      }
    } catch (error) {
      console.log('Internal server error.')
    }
  }
  // Function to handle updating phone number
  const handlePhoneNumberUpdate = async () => {
    const updatedPhonenumber = phonenumber
    const updatedUser = { ...user, phonenumber: updatedPhonenumber }
    try {
      if (phoneOTP) {
        // Send updated phone number data to the server
        const response = await axios.post(updateUser, updatedUser)

        // Handle server response if needed
        toast.success('تم تحديث معلومات المستخدم بشكل صحيح.')
      } else {
        setVisible(true)
      }
    } catch (error) {
      console.error('Error updating phone number:', error)
    }
  }
  const handleFileChange = event => {
    const selectedFile = event.target.files[0]
    setFile(selectedFile)
    // const avatarUrl =
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (!file) {
      toast.warning('.اختر ملفا، رجاءا')
      return
    }

    const formData = new FormData()
    formData.append('file', file)
    formData.append('userid', userid)

    try {
      const response = await axios.post(fileupload, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        body: JSON.stringify({ userid })
      })
      if (response.status === 200) {
        const avatarUrl = response.data.AvatarUrl

        localStorage.setItem('avatarDataURL', avatarUrl)
        toast.success('تم رفع الملف بنجاح.')
      } else {
        // Handle upload failure
        toast.warning('تم رفع الملف بنجاح.')
        alert('التحميل فشل. حاول مرة اخرى.')
      }
    } catch (error) {
      console.error('Error uploading file:', error)
      alert('خطأ في تحميل الملف. حاول مرة اخرى.')
    }
  }
  return (
    <div>
      <ToastContainer
        className='custom-toast-container'
        toastClassName='custom-toast'
        bodyClassName='custom-toast-body'
        closeButtonClassName='custom-toast-close-button'
        progressClassName='custom-toast-progress-bar'
        position={width >= 768 ? 'top-right' : 'top-center'}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        role='dialog'
        id='radix-:r4:'
        className={` ${
          !visible ? 'hidden' : ''
        } max-w-3xl fixed left-[50%] top-[50%] z-50 translate-x-[-50%] translate-y-[-50%] border-0  p-6 shadow-lg duration-200 md:w-full flex flex-col items-center justify-center gap-12 w-full bg-[#fafafa]`}
        tabIndex='-1'>
        <div className='flex flex-col space-y-1.5 text-center sm:text-left'>
          <h2 id='radix-:r5:' className='tracking-tight text-xl text-center font-medium'>
            لتأكيد تسجيل الدخول، الرجاء كتابة رمز ال <span className=' font-bold font-sans'>OTP</span> المرسل لك
          </h2>
        </div>
        <div
          data-input-otp-container='true'
          className='flex items-center gap-4'
          style={{
            position: 'relative',
            cursor: 'text',
            userSelect: 'none',
            '--root-height': '56px'
          }}>
          <VerificationInput
            classNames={{
              container: 'container',
              character: 'character',
              characterInactive: 'character--inactive',
              characterSelected: 'character--selected',
              characterFilled: 'character--filled'
            }}
            validChars='0-9'
            inputProps={{ inputMode: 'numeric' }}
            onChange={handleInputChange} // Assuming index is 0
            value={verificationCode} // Pass the value as a prop
            length={6} // Set the length of the verification code
          />
        </div>
        <div className='w-full'>
          <div className='flex flex-row justify-between items-center'>
            <button className='inline-flex items-center justify-center rounded-full text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-primary underline-offset-4 hover:underline h-12 md:py-6 p-0'>
              إعادة إرسال OTP
            </button>
            <div>
              <p>Didn&apos;t recieve code?</p>
            </div>
          </div>
          <button
            className='inline-flex items-center justify-center rounded-full text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-teal-900 glow-teal h-12 px-10 py-4 md:py-6 w-full text-white'
            onClick={handleOTPVerification}>
            تأكيد
          </button>
        </div>
      </div>
      <div
        className={'mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 p-4 text-white ' + (visible ? 'opacity-20' : '')}
        dir='rtl'>
        <div className='p-8 flex flex-col gap-8 lg:gap-14 bg-white shadow rounded-md'>
          <div className='p-4 flex  text-black flex-col gap-2'>
            <h1 className='font-bold text-center lg:text-right'>معلوماتك الشخصية</h1>
            <p className='text-gray-400 text-center lg:text-right'>تحديث البيانات الشخصية</p>
            <form className='px-2 md:px-4 lg:px-8 pt-2 flex flex-col gap-4 text-foreground'>
              <div className='flex flex-row gap-16 justify-around flex-wrap lg:flex-nowrap'>
                <div className='space-y-2 w-full'>
                  <label className='text-sm font-bold '>اسم</label>
                  <input
                    className='flex h-12 rounded-md border border-[#abadaf] bg-background px-3 py-2 tring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 w-full text-black'
                    placeholder={name}
                    type='text'
                    name='firstName'
                    onChange={event => setName(event.target.value)}
                  />
                </div>
                <div className='space-y-2 w-full'>
                  <label className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>كنية</label>
                  <input
                    className='flex h-12 rounded-md border border-[#abadaf] bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 w-full text-black'
                    placeholder={nickname}
                    id=':rj:-form-item'
                    type='text'
                    name='nickname'
                    onChange={event => setNickname(event.target.value)}
                  />
                </div>
              </div>
              <div className='border-b border-gray-300 w-full'></div>
              <div className='flex flex-row flex-wrap gap-2 justify-center lg:justify-end'>
                <button
                  id='1'
                  className='inline-flex items-center justify-center text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-secondary text-secondary-foreground hover:bg-teal-600 h-9 px-3 rounded-md text-white'
                  type='button'
                  onClick={handleNameUpdate}>
                  حفظ
                </button>
              </div>
            </form>
          </div>
          <div className='border-b-2 border-gray-300 w-full'></div>
          <div className='p-4 flex flex-col gap-2'>
            <div className='flex flex-row gap-1 justify-center lg:justify-start items-center flex-wrap'>
              <h1 className='font-bold  text-black text-center lg:text-right'>معلومات الحساب</h1>
            </div>
            <p className='text-gray-400 text-center lg:text-right'>تحديث كلمة المرور</p>
            <form className='px-2 md:px-4 lg:px-8 pt-2 flex flex-col gap-4 text-foreground'>
              <div className='flex flex-row flex-wrap lg:flex-nowrap gap-8 justify-around'>
                <div className='space-y-2 w-full'>
                  <label
                    className='text-sm font-bold
                    font-bold leading-none text-black  peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
                    البريد الإلكتروني
                  </label>
                  <input
                    className='flex h-12 rounded-md border border-[#abadaf] 
                     bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 w-full text-black'
                    type='email'
                    placeholder='sunshine1111@gmail.com'
                    name='email'
                    onChange={event => setEmail(event.target.value)}
                  />
                </div>
                <div className='space-y-2 w-full'>
                  <label className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>كلمة المرور القديمة</label>
                  <input
                    className='flex h-12 rounded-md border border-[#abadaf] bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 w-full text-black'
                    placeholder='أدخل كلمة المرور القديمة'
                    type='password'
                    onChange={event => setOldpassword(event.target.value)}
                    name='oldPassword'
                  />
                </div>
                <div className='space-y-2 w-full'>
                  <label className='text-sm font-medium leading-none '>كلمة المرور الجديدة</label>
                  <input
                    className='flex h-12 rounded-md border border-[#abadaf] bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 w-full text-black'
                    placeholder='أدخل كلمة المرور الجديدة'
                    onChange={event => setNewpassword(event.target.value)}
                    type='password'
                    name='newPassword'
                  />
                </div>
              </div>

              <div className='border-b border-gray-300 w-full'></div>
              <div className='flex flex-row flex-wrap gap-2 justify-center lg:justify-end'>
                <button
                  id='2'
                  className='inline-flex items-center justify-center text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-secondary text-secondary-foreground hover:bg-teal-600 h-9 px-3 rounded-md'
                  type='button'
                  onClick={handleEmailPasswordUpdate}>
                  حفظ
                </button>
              </div>
            </form>
          </div>
          <div className='border-b-2 border-gray-300 w-full'></div>
          <div className='p-4 flex flex-col gap-2'>
            <h1 className='font-bold text-black text-center lg:text-right'>رقم الجوال</h1>
            <p className='text-gray-400 text-center lg:text-right'>تحديث رقم الجوال</p>
            <form className='px-2 md:px-4 lg:px-8 pt-2 flex flex-col gap-4 text-foreground'>
              <div className='space-y-2 w-full'>
                <input
                  className='flex h-12 w-full rounded-md border border-[#abadaf]bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 text-black'
                  placeholder='0599xxxxxx'
                  type='text'
                  name='phoneNumber'
                  onChange={event => setPhonenumber(event.target.value)}
                />
              </div>
              <div className='border-b border-gray-300 w-full'></div>
              <div className='flex flex-row flex-wrap gap-2 justify-center lg:justify-end'>
                <button
                  id='1'
                  className='inline-flex items-center justify-center text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-secondary text-secondary-foreground hover:bg-teal-600 h-9 px-3 rounded-md text-white'
                  type='button'
                  onClick={handlePhoneNumberUpdate}>
                  حفظ
                </button>
              </div>
            </form>
          </div>
          <div className='border-b-2 border-gray-300 w-full'></div>
          <div className='p-4 flex flex-col gap-2'>
            <div className='flex flex-row gap-2 items-center '>
              {isUploaded ? (
                <img
                  width='1000'
                  height='1000'
                  decoding='async'
                  data-nimg='1'
                  className='bg-cover h-16 w-16 rounded-full'
                  src={file ? URL.createObjectURL(file) : avatarUrl} // Convert the File object to a data URL
                  alt='Selected file' // Add an alt attribute for accessibility
                />
              ) : (
                <img
                  width='1000'
                  height='1000'
                  decoding='async'
                  data-nimg='1'
                  className='bg-cover h-16 w-16 rounded-full'
                  src={file ? URL.createObjectURL(file) : personal_picture}
                  style={{ color: 'transparent' }}
                />
              )}
              <div className='  '>
                <h1 className='font-bold  text-black text-center lg:text-right'>الصورة الشخ صية</h1>
                <p className='text-gray-400 text-center lg:text-right'>تحديث الصورة الشخصية</p>
              </div>
            </div>
            <form className='px-2 md:px-4 lg:px-8 pt-2 flex flex-col gap-4 text-foreground'>
              <div className='flex flex-row gap-16 justify-around'>
                <div className='space-y-2 w-full'>
                  <label className='text-sm font-bold leading-none  text-black peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>الصورة الشخصية</label>
                  <input
                    className='flex h-12 rounded-md border border-[#abadaf] bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 w-full cursor-pointer text-black'
                    placeholder='ارفق الصورة الشخصية'
                    accept='image/*'
                    id=':ro:-form-item'
                    aria-describedby=':ro:-form-item-description'
                    aria-invalid='false'
                    type='file'
                    onChange={handleFileChange}
                    name='profilePicture'
                  />
                </div>
              </div>
              <div className='border-b border-gray-300 w-full'></div>
              <div className='flex flex-row flex-wrap gap-2 justify-center lg:justify-end'>
                <button
                  id='4'
                  className='inline-flex items-center justify-center text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-secondary text-secondary-foreground hover:bg-teal-600 h-9 px-3 rounded-md'
                  type='button'
                  onClick={handleSubmit}>
                  حفظ
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default general
