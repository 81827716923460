import { React } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../../component/layout/header'
import Footer from '../../component/layout/footer'
import { useNavbar } from '../../context/NavbarContext'
import logo from '../../assests/Icons/logo.png'
import vector4 from '../../assests/Icons/Vector4.png'
import Group1 from '../../assests/Icons/Group1.png'
import Group2 from '../../assests/Icons/Group2.png'
import Group3 from '../../assests/Icons/Group3.png'
import Group4 from '../../assests/Icons/Group4.png'
import Group5 from '../../assests/Icons/Group5.png'
import Group6 from '../../assests/Icons/Group6.png'
import Record from '../../assests/Icons/Record.png'
import LogoMobile from '../../assests/Landing/logo1_3.png'
import upload_icon from '../../assests/Icons/upload_icon.png'
const Lawyer = () => {
  const { navbarShow, setNavbarShow } = useNavbar()
  const navigate = useNavigate('')
  return (
    <div className='h-[100%] overflow-x-hidden bg-[#f6f6f6f] scrollbar'>
      <Header />
      {/* desktop */}
      <div className='container md:px-0'>
        <div className='w-full md:mt-[80px] mt-[30px] mb-[25px]'>
          <button
            className='flex justify-center items-center h-[25px] w-[85px] bg-[#224B37] rounded-[20px] text-white mb-[10px]'
            onClick={() => {
              navigate('/')
            }}>
            رجوع
          </button>
          <div className='md:text-[32px] text-[18px] text-[#000000]'>الخدمات&gt; طلب محامي </div>
        </div>
        <div className=' flex justify-center items-center mb-[30px]'>
          <img src={logo} alt='logo' className='h-[201px] w-[402px] max-sm:hidden' />
          <img src={LogoMobile} alt='logo_mobile' className='md:hidden' />
        </div>

        <div className='md:flex md:flex-row items-center gap-[2rem] md:mb-[40px]'>
          <div className='max-sm:mb-[10px] relative md:w-1/2 w-full border-[0.5px]  border-[#C7C7C7] rounded-[7px] p-10 flex flex-col items-center justify-center md:h-[210px] h-[122px] bg-gradient-to-r from-[#26AB7B] to-[#006C3E]'>
            <div className='absolute right-[20px] top-[20px] w-[56px] md:w-[90px] h-[40px] md:h-[63px] bg-[#E7FFF3] flex justify-center items-center rounded-[5px]'>
              <img src={Group3} alt='Group3' className='md:h-[55px] h-[33px] w-[33px] md:w-[55px]' />
            </div>
            <span className='md:text-[24px] text-[16px] text-[white] font-medium'>طلب استشارة</span>
          </div>
          <div className='max-sm:mb-[10px] relative md:w-1/2 w-full border-[0.5px] border-[#C7C7C7] text-[#717171] rounded-[7px] p-10 flex flex-col items-center justify-center bg-white md:h-[210px] h-[122px] hover:bg-gradient-to-r from-[#26AB7B] to-[#006C3E] hover:text-white transition-all duration-300 cursor-pointer'>
            <div className='absolute right-[20px] rounded-[7px] top-[20px] w-[56px] md:w-[90px] h-[40px] md:h-[53px] bg-[#E7FFF3] flex justify-center items-center'>
              <img src={Group4} alt='Group4' className='md:h-[55px] h-[33px] w-[33px] md:w-[55px]' />
            </div>
            <span className='md:text-[24px] text-[16px] font-medium'>طلب كتابة عقود</span>
          </div>
        </div>

        <div className='md:flex md:flex-row justify-center items-center gap-[2rem]'>
          <div className='max-sm:mb-[10px] md:w-1/2 w-full relative border-[0.5px] border-[#C7C7C7] text-[#717171] rounded-[7px] p-10 flex flex-col items-center justify-center w-1/2 bg-white md:h-[210px] h-[122px] hover:bg-gradient-to-r from-[#26AB7B] to-[#006C3E] hover:text-white transition-all duration-300 cursor-pointer'>
            <div className='absolute right-[20px] top-[20px] rounded-[7px] md:w-[95px] h-[40px] w-[56px] md:h-[65px] bg-[#E7FFF3] flex justify-center items-center'>
              <img src={Group6} alt='Group6' className='md:h-[55px] h-[33px] w-[33px] md:w-[55px]' />
            </div>
            <span className='md:text-[24px] text-[16px] font-medium'>طلب كتابة مذكرات</span>
          </div>
          <div className='max-sm:mb-[10px] md:w-1/2 w-full relative border-[0.5px] border-[#C7C7C7] text-[#717171] rounded-[7px] p-10 flex flex-col items-center justify-center w-1/2 bg-white md:h-[210px] h-[122px] hover:bg-gradient-to-r from-[#26AB7B] to-[#006C3E] hover:text-white transition-all duration-300 cursor-pointer'>
            <div className='absolute right-[20px] top-[20px] rounded-[7px] md:w-[95px] h-[40px] w-[56px] md:h-[65px] bg-[#E7FFF3] flex justify-center items-center'>
              <img src={Group5} alt='Group5' className='md:h-[55px] h-[33px] w-[33px] md:w-[55px]' />
            </div>
            <span className='md:text-[24px] text-[16px] font-medium'>طلب توكيل محامي</span>
          </div>
        </div>

        <div className='w-full text-[10px] md:text-[28px] md:mt-[80px]'>
          <input
            className='w-full px-3 py-2 border border-[#C7C7C7] rounded-[7px] md:rounded-[13px] h-[37px] md:h-[85px] focus:outline-none focus:border-green-500 mb-[10px] md:mb-[30px]'
            id='category'
            type='text'
            placeholder='التصنيف'
          />
          <input
            className='w-full px-3 py-2 border border-[#C7C7C7] rounded-[7px] md:rounded-[13px] h-[37px] md:h-[85px] focus:outline-none focus:border-green-500 mb-[10px] md:mb-[30px]'
            id='title'
            type='text'
            placeholder='العنوان'
          />
          <div className='flex justify-center items-center bg-white cursor-pointer border border-[#C7C7C7] rounded-[7px] md:rounded-[13px] h-[37px] md:h-[85px] focus:outline-none focus:border-green-500 mb-[10px] md:mb-[30px]'>
            <img src={upload_icon} alt='upload_icon' className='max-sm:w-[18px]' />
            <span className='text-[10px] md:text-[28px] text-[#3C9977] mr-[10px] md:mr-[20px]'>تحميل الملفات</span>
          </div>
          <div className='w-full'>
            <textarea
              className='h-[57px] md:h-[146px] w-full px-3 rounded-[7px] md:rounded-[13px] py-2 border border-[#C7C7C7] rounded focus:outline-none focus:border-green-500'
              id='details'
              placeholder='التفاصيل'
              rows='4'></textarea>
            <p className='text-right text-[#4DA05F] md:text-[#484848] text-[10px] md:text-[20px] xs mt-1'>الحد الأدنى 100 حرف</p>
          </div>
          <div className='text-center'>
            <button
              type='submit'
              className='md:w-[460px] w-[160px] mb-[20px] md:mb-[120px] text-[14px] md:text-[24px] md:mt-[60px] mt-[30px] h-[36px] md:h-[84px] py-2 px-4 text-white rounded-[7px] btn-gradient focus:outline-none'>
              رفع طلب خدمة
            </button>
            <div className='flex justify-center w-full md:hidden mb-[50px]'>
              <div className='flex justify-center items-center h-[25px] w-[85px] bg-[#224B37] rounded-[20px] text-white mb-[10px]'>رجوع</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default Lawyer
