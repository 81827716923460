import React, { useContext } from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import PropTypes from 'prop-types'
import Loader from '../component/layout/loading'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return <Loader />;
  }

  return user ? <Outlet {...rest} /> : <Navigate to="/login" />;
};
ProtectedRoute.propTypes = {
  component: PropTypes.node.isRequired
}
export default ProtectedRoute;
