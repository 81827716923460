import { React, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../../component/layout/header'
import Footer from '../../component/layout/footer'
import { useNavbar } from '../../context/NavbarContext'
import Arrow1 from '../../assests/Icons/Arrow1.png'
import ArrowDown from '../../assests/Landing/allow-down.png'

const Consultation_requests = () => {
  const [open, setOpen] = useState({})
  const { navbarShow, setNavbarShow } = useNavbar()
  const navigate = useNavigate('')
  return (
    <div className='h-[100%] overflow-x-hidden bg-[#f6f6f6] scrollbar'>
      <Header />
      {!navbarShow && (
        <>
          <div className='container md:px-0 mb-[80px]'>
            <div className='mt-[80px] md:mb-[75px] mb-[25px] max-sm:mt-[50px]'>
              <button
                className='flex justify-center items-center h-[25px] focus:text-green-700 max-sm:h-[18px] w-[85px] max-sm:w-[60px] text-[11px] bg-[#224B37] rounded-[20px] text-white mb-[10px]'
                onClick={() => {
                  navigate('/register')
                }}>
                رجوع
              </button>
              <div className='text-[32px] max-md:text-[20px] md:text-[#232323] sm:text-[#000000]'>الخدمات&gt; الافراد &gt;السجل</div>
            </div>
            <div key={1} className={`rounded-[10px] overflow-hidden mb-[25px] ${open[1] ? 'gradient-border' : ''}`}>
              <div className='md:py-[40px] py-[15px] md:px-[25px] px-[15px] bg-white w-full rounded-[10px]' dir='ltr'>
                <div className={`header-custom flex items-center justify-between ${open[1] ? '' : 'expanded'}`}>
                  <div className='flex items-center justify-between md:w-[350px] w-[120px]'>
                    <button
                      onClick={() => {
                        setOpen(values => ({
                          ...values,
                          1: values['1'] ? false : true
                        }))
                      }}>
                      <img src={Arrow1} alt='Arrow1' />
                    </button>
                    <div className='flex items-center max-sm:flex-col-reverse'>
                      <span className='bg-[#B08D0E] text-[#FFBA0A] rounded-full px-6 py-1 max-sm:text-[10px] md:mr-[25px] text-[20px]'>جديد</span>
                      <span className='text-[#939393] text-[18px] md:ml-2 max-sm:text-[10px] max-sm:mb-2'>الحالة</span>
                    </div>
                  </div>
                  <div className='flex items-center max-sm:flex-col'>
                    <span className='text-[18px] md:mr-[25px] md:text-[#2E694C] text-black max-sm:text-[10px] max-sm:mb-2'>عبد الرحمن</span>
                    <span className='text-[18px] text-[#909090] max-sm:text-[10px] max-sm:h-[23px] max-sm:pt-[3px]'>المحامي</span>
                  </div>
                  <div className='flex items-center max-sm:flex-col'>
                    <span className=' text-[18px] md:mr-[25px] md:text-[#2E694C] text-black max-sm:text-[10px] max-sm:mb-2'>استشارة</span>
                    <span className='text-[18px] text-[#909090] max-sm:text-[10px] max-sm:h-[23px] max-sm:pt-[3px]'>التفاصيل</span>
                  </div>
                </div>
                <div className={`content toggle-section ${open[1] ? 'expanded' : ''}`}>
                  <div className='flex relative items-center justify-between mb-4' dir='ltr'>
                    <div className='flex items-center justify-between w-[120px] md:w-[300px]'>
                      <button
                        className='max-sm:w-[31px]'
                        onClick={() => {
                          setOpen(values => ({
                            ...values,
                            1: values['1'] ? false : true
                          }))
                        }}>
                        <img src={ArrowDown} alt='ArrowDown' />
                      </button>
                      <div className='flex flex-col items-center'>
                        <span className='text-[#939393] md:text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>الحالة</span>
                        <span className='bg-[#B08D0E] text-[#FFBA0A] text-[18px] rounded-full px-6 py-1 max-sm:text-[10px]'>جديد</span>
                      </div>
                    </div>

                    <div className='flex flex-col text-right w-[70px] md:w-[300px]'>
                      <span className='text-[#909090] md:text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>المحامي</span>
                      <span className=' md:text-[#909090] text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px]'>عبد الرحمن</span>
                    </div>

                    <div className='flex flex-col text-right w-[70px] md:w-[300px]'>
                      <span className='text-[#868686] md:text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>التفاصيل</span>
                      <span className='text-[18px] md:text-[#909090] text-black max-sm:text-[10px] max-sm:h-[23px]'>استشارة</span>
                    </div>
                  </div>
                  <div className='flex items-center justify-between md:mt-[50px] mt-[20px]'>
                    <div className='flex md:justify-end md:w-[300px] w-[90px] max-sm:bg-[#F2FFEF] max-sm:p-[10px] max-sm:rounded-[10px]'>
                      <div className='text-center'>
                        <div className='text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>المدعي علية</div>
                        <div className='md:text-[#909090] text-[18px] text-[#868686] max-sm:text-[10px] max-sm:h-[23px]'>فلان</div>
                      </div>
                    </div>

                    <div className='flex flex-col md:text-right text-center w-[90px] md:w-[300px] max-sm:bg-[#F2FFEF] max-sm:p-[10px] max-sm:rounded-[10px]'>
                      <span className='text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>المدعي</span>
                      <span className=' md:text-[#909090] text-[18px] text-[#939393] max-sm:text-[10px] max-sm:h-[23px]'>فلان</span>
                    </div>

                    <div className='flex flex-col md:text-right text-center w-[90px] md:w-[300px] max-sm:bg-[#F2FFEF] max-sm:p-[10px] max-sm:rounded-[10px]'>
                      <span className='text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>المحكمة</span>
                      <span className='text-[18px] md:text-[#909090] text-[#868686] max-sm:text-[10px] max-sm:h-[23px]'>محكمة المدينة</span>
                    </div>
                  </div>

                  <div className='flex items-center justify-between md:mt-[50px] mt-[20px]'>
                    <div className='flex md:justify-end w-[90px] md:w-[300px]'>
                      <div className='text-center'>
                        <div className='text-[#2E694C] text-[18px] ml-2 md:mb-[10px] mb-2 max-sm:text-[10px]'>تواصل مع محامي</div>
                        <div className='rounded-[12px] overflow-hidden gradient-border'>
                          <div className='md:px-[30px] py-[7px] rounded-[12px] md:text-[#909090] bg-white text-[16px] max-sm:text-[10px]'>ابدء محادثة</div>
                        </div>
                      </div>
                    </div>

                    <div className='flex flex-col text-center md:text-right w-[90px] md:w-[300px] max-sm:bg-[#F2FFEF] max-sm:p-[10px] max-sm:rounded-[10px]'>
                      <span className='text-[#2E694C] text-[18px] ml-2 md:mb-[10px] mb-2 max-sm:text-[10px]'>الجلسة القادمة</span>
                      <span className='h-[38px] text-[#909090] text-[18px] max-sm:text-[10px] max-sm:h-[23px]'>2024\00\00</span>
                    </div>

                    <div className='flex flex-col text-center md:text-right w-[90px] md:w-[300px] max-sm:bg-[#F2FFEF] max-sm:p-[10px] max-sm:rounded-[10px]'>
                      <span className='text-[#2E694C] text-[18px] ml-2 md:mb-[10px] mb-2 max-sm:text-[10px]'>الدائرة القضائية</span>
                      <span className='h-[38px] text-[18px] text-[#909090] max-sm:text-[10px] max-sm:h-[23px]'>النص</span>
                    </div>
                  </div>
                  <div className='w-full text-right md:mt-[50px] mt-[30px]'>
                    <span className='text-[#2E694C] text-[24px] max-sm:text-[12px]'>تفاصيل القضية</span>
                    <textarea
                      className='md:h-[147px] h-[63px] text-[18px] w-full pt-[10px] px-[15px] bg-[#f6f6f6] rounded-[5px] text-right mt-[10px] max-sm:text-[12px]'
                      placeholder='التفاصيل...'></textarea>
                  </div>
                  <div className='w-full text-right md:mt-[50px] mt-[20px]'>
                    <div className='md:text-[24px] text-[#2E694C] text-[12px]'>الأحداث</div>
                    <div className='md:py-[30px] py-[15px] bg-[#f6f6f6] rounded-[6px] mt-[10px] md:px-[30px] px-[15px]'>
                      <div className='text-black md:text-[16px] text-[10px] mb-[10px]'>موضوع الحدث</div>
                      <textarea
                        disabled={true}
                        className='md:h-[70px] h-[35px] text-[10px] md:text-[14px] py-[10px] px-[10px] md:py-[20px] md:px-[20px] w-full bg-white rounded-[6px] text-right'
                        placeholder='التفاصيل...'></textarea>
                      <div className='text-left md:text-[16px] text-[#868686] md:mt-[10px] text-[10px]'>2024\00\00</div>
                    </div>
                    <div className='md:py-[30px] py-[15px] bg-[#f6f6f6] rounded-[6px] mt-[10px] md:px-[30px] px-[15px]'>
                      <div className='text-black md:text-[16px] text-[10px] mb-[10px]'>موضوع الحدث</div>
                      <textarea
                        disabled={true}
                        className='md:h-[70px] h-[35px] text-[10px] md:text-[14px] py-[10px] px-[10px] md:py-[20px] md:px-[20px] w-full bg-white rounded-[6px] text-right'
                        placeholder='التفاصيل...'></textarea>
                      <div className='text-left md:text-[16px] text-[#868686] md:mt-[10px] text-[10px]'>2024\00\00</div>
                    </div>
                    <div className='w-full text-left'>
                      <div className='text-left md:text-[18px] text-[10px] text-white bg-[#B08D0E] rounded-[20px] mt-[20px] md:mt-[50px] md:w-[165px] w-[89px] flex justify-center items-center h-[22px] md:h-[40px]'>
                        العروض المقدمة
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div key={2} className={`rounded-[10px] overflow-hidden mb-[25px] ${open[2] ? 'gradient-border' : ''}`}>
              <div className='md:py-[40px] py-[15px] md:px-[25px] px-[15px] bg-white w-full rounded-[10px]' dir='ltr'>
                <div className={`header-custom flex items-center justify-between ${open[2] ? '' : 'expanded'}`}>
                  <div className='flex items-center justify-between md:w-[350px] w-[120px]'>
                    <button
                      onClick={() => {
                        setOpen(values => ({
                          ...values,
                          2: values['2'] ? false : true
                        }))
                      }}>
                      <img src={Arrow1} alt='Arrow1' />
                    </button>
                    <div className='flex items-center max-sm:flex-col-reverse'>
                      <span className='bg-[#B08D0E] text-[#FFBA0A] rounded-full px-6 py-1 max-sm:text-[10px] md:mr-[25px] text-[20px]'>جديد</span>
                      <span className='text-[#939393] text-[18px] md:ml-2 max-sm:text-[10px] max-sm:mb-2'>الحالة</span>
                    </div>
                  </div>
                  <div className='flex items-center max-sm:flex-col'>
                    <span className='text-[18px] md:mr-[25px] md:text-[#2E694C] text-black max-sm:text-[10px] max-sm:mb-2'>عبد الرحمن</span>
                    <span className='text-[18px] text-[#909090] max-sm:text-[10px] max-sm:h-[23px] max-sm:pt-[3px]'>المحامي</span>
                  </div>
                  <div className='flex items-center max-sm:flex-col'>
                    <span className=' text-[18px] md:mr-[25px] md:text-[#2E694C] text-black max-sm:text-[10px] max-sm:mb-2'>استشارة</span>
                    <span className='text-[18px] text-[#909090] max-sm:text-[10px] max-sm:h-[23px] max-sm:pt-[3px]'>التفاصيل</span>
                  </div>
                </div>
                <div className={`content toggle-section ${open[2] ? 'expanded' : ''}`}>
                  <div className='flex relative items-center justify-between mb-4' dir='ltr'>
                    <div className='flex items-center justify-between w-[120px] md:w-[300px]'>
                      <button
                        className='max-sm:w-[31px]'
                        onClick={() => {
                          setOpen(values => ({
                            ...values,
                            2: values['2'] ? false : true
                          }))
                        }}>
                        <img src={ArrowDown} alt='ArrowDown' />
                      </button>
                      <div className='flex flex-col items-center'>
                        <span className='text-[#939393] md:text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>الحالة</span>
                        <span className='bg-[#B08D0E] text-[#FFBA0A] text-[18px] rounded-full px-6 py-1 max-sm:text-[10px]'>جديد</span>
                      </div>
                    </div>

                    <div className='flex flex-col text-right w-[70px] md:w-[300px]'>
                      <span className='text-[#909090] md:text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>المحامي</span>
                      <span className=' md:text-[#909090] text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px]'>عبد الرحمن</span>
                    </div>

                    <div className='flex flex-col text-right w-[70px] md:w-[300px]'>
                      <span className='text-[#868686] md:text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>التفاصيل</span>
                      <span className='text-[18px] md:text-[#909090] text-black max-sm:text-[10px] max-sm:h-[23px]'>استشارة</span>
                    </div>
                  </div>
                  <div className='flex items-center justify-between md:mt-[50px] mt-[20px]'>
                    <div className='flex md:justify-end md:w-[300px] w-[90px] max-sm:bg-[#F2FFEF] max-sm:p-[10px] max-sm:rounded-[10px]'>
                      <div className='text-center'>
                        <div className='text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>المدعي علية</div>
                        <div className='md:text-[#909090] text-[18px] text-[#868686] max-sm:text-[10px] max-sm:h-[23px]'>فلان</div>
                      </div>
                    </div>

                    <div className='flex flex-col md:text-right text-center w-[90px] md:w-[300px] max-sm:bg-[#F2FFEF] max-sm:p-[10px] max-sm:rounded-[10px]'>
                      <span className='text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>المدعي</span>
                      <span className=' md:text-[#909090] text-[18px] text-[#939393] max-sm:text-[10px] max-sm:h-[23px]'>فلان</span>
                    </div>

                    <div className='flex flex-col md:text-right text-center w-[90px] md:w-[300px] max-sm:bg-[#F2FFEF] max-sm:p-[10px] max-sm:rounded-[10px]'>
                      <span className='text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>المحكمة</span>
                      <span className='text-[18px] md:text-[#909090] text-[#868686] max-sm:text-[10px] max-sm:h-[23px]'>محكمة المدينة</span>
                    </div>
                  </div>

                  <div className='flex items-center justify-between md:mt-[50px] mt-[20px]'>
                    <div className='flex md:justify-end w-[90px] md:w-[300px]'>
                      <div className='text-center'>
                        <div className='text-[#2E694C] text-[18px] ml-2 md:mb-[10px] mb-2 max-sm:text-[10px]'>تواصل مع محامي</div>
                        <div className='rounded-[12px] overflow-hidden gradient-border'>
                          <div className='md:px-[30px] py-[7px] rounded-[12px] md:text-[#909090] bg-white text-[16px] max-sm:text-[10px]'>ابدء محادثة</div>
                        </div>
                      </div>
                    </div>

                    <div className='flex flex-col text-center md:text-right w-[90px] md:w-[300px] max-sm:bg-[#F2FFEF] max-sm:p-[10px] max-sm:rounded-[10px]'>
                      <span className='text-[#2E694C] text-[18px] ml-2 md:mb-[10px] mb-2 max-sm:text-[10px]'>الجلسة القادمة</span>
                      <span className='h-[38px] text-[#909090] text-[18px] max-sm:text-[10px] max-sm:h-[23px]'>2024\00\00</span>
                    </div>

                    <div className='flex flex-col text-center md:text-right w-[90px] md:w-[300px] max-sm:bg-[#F2FFEF] max-sm:p-[10px] max-sm:rounded-[10px]'>
                      <span className='text-[#2E694C] text-[18px] ml-2 md:mb-[10px] mb-2 max-sm:text-[10px]'>الدائرة القضائية</span>
                      <span className='h-[38px] text-[18px] text-[#909090] max-sm:text-[10px] max-sm:h-[23px]'>النص</span>
                    </div>
                  </div>
                  <div className='w-full text-right md:mt-[50px] mt-[30px]'>
                    <span className='text-[#2E694C] text-[24px] max-sm:text-[12px]'>تفاصيل القضية</span>
                    <textarea
                      className='md:h-[147px] h-[63px] text-[18px] w-full pt-[10px] px-[15px] bg-[#f6f6f6] rounded-[5px] text-right mt-[10px] max-sm:text-[12px]'
                      placeholder='التفاصيل...'></textarea>
                  </div>
                  <div className='w-full text-right md:mt-[50px] mt-[20px]'>
                    <div className='md:text-[24px] text-[#2E694C] text-[12px]'>الأحداث</div>
                    <div className='md:py-[30px] py-[15px] bg-[#f6f6f6] rounded-[6px] mt-[10px] md:px-[30px] px-[15px]'>
                      <div className='text-black md:text-[16px] text-[10px] mb-[10px]'>موضوع الحدث</div>
                      <textarea
                        disabled={true}
                        className='md:h-[70px] h-[35px] text-[10px] md:text-[14px] py-[10px] px-[10px] md:py-[20px] md:px-[20px] w-full bg-white rounded-[6px] text-right'
                        placeholder='التفاصيل...'></textarea>
                      <div className='text-left md:text-[16px] text-[#868686] md:mt-[10px] text-[10px]'>2024\00\00</div>
                    </div>
                    <div className='md:py-[30px] py-[15px] bg-[#f6f6f6] rounded-[6px] mt-[10px] md:px-[30px] px-[15px]'>
                      <div className='text-black md:text-[16px] text-[10px] mb-[10px]'>موضوع الحدث</div>
                      <textarea
                        disabled={true}
                        className='md:h-[70px] h-[35px] text-[10px] md:text-[14px] py-[10px] px-[10px] md:py-[20px] md:px-[20px] w-full bg-white rounded-[6px] text-right'
                        placeholder='التفاصيل...'></textarea>
                      <div className='text-left md:text-[16px] text-[#868686] md:mt-[10px] text-[10px]'>2024\00\00</div>
                    </div>
                    <div className='w-full text-left'>
                      <div className='text-left md:text-[18px] text-[10px] text-white bg-[#B08D0E] rounded-[20px] mt-[20px] md:mt-[50px] md:w-[165px] w-[89px] flex justify-center items-center h-[22px] md:h-[40px]'>
                        العروض المقدمة
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div key={3} className={`rounded-[10px] overflow-hidden mb-[25px] ${open[3] ? 'gradient-border' : ''}`}>
              <div className='md:py-[40px] py-[15px] md:px-[25px] px-[15px] bg-white w-full rounded-[10px]' dir='ltr'>
                <div className={`header-custom flex items-center justify-between ${open[3] ? '' : 'expanded'}`}>
                  <div className='flex items-center justify-between md:w-[350px] w-[120px]'>
                    <button
                      onClick={() => {
                        setOpen(values => ({
                          ...values,
                          3: values['3'] ? false : true
                        }))
                      }}>
                      <img src={Arrow1} alt='Arrow1' />
                    </button>
                    <div className='flex items-center max-sm:flex-col-reverse'>
                      <span className='bg-[#B08D0E] text-[#FFBA0A] rounded-full px-6 py-1 max-sm:text-[10px] md:mr-[25px] text-[20px]'>جديد</span>
                      <span className='text-[#939393] text-[18px] md:ml-2 max-sm:text-[10px] max-sm:mb-2'>الحالة</span>
                    </div>
                  </div>
                  <div className='flex items-center max-sm:flex-col'>
                    <span className='text-[18px] md:mr-[25px] md:text-[#2E694C] text-black max-sm:text-[10px] max-sm:mb-2'>عبد الرحمن</span>
                    <span className='text-[18px] text-[#909090] max-sm:text-[10px] max-sm:h-[23px] max-sm:pt-[3px]'>المحامي</span>
                  </div>
                  <div className='flex items-center max-sm:flex-col'>
                    <span className=' text-[18px] md:mr-[25px] md:text-[#2E694C] text-black max-sm:text-[10px] max-sm:mb-2'>استشارة</span>
                    <span className='text-[18px] text-[#909090] max-sm:text-[10px] max-sm:h-[23px] max-sm:pt-[3px]'>التفاصيل</span>
                  </div>
                </div>
                <div className={`content toggle-section ${open[3] ? 'expanded' : ''}`}>
                  <div className='flex relative items-center justify-between mb-4' dir='ltr'>
                    <div className='flex items-center justify-between w-[120px] md:w-[300px]'>
                      <button
                        className='max-sm:w-[31px]'
                        onClick={() => {
                          setOpen(values => ({
                            ...values,
                            3: values['3'] ? false : true
                          }))
                        }}>
                        <img src={ArrowDown} alt='ArrowDown' />
                      </button>
                      <div className='flex flex-col items-center'>
                        <span className='text-[#939393] md:text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>الحالة</span>
                        <span className='bg-[#B08D0E] text-[#FFBA0A] text-[18px] rounded-full px-6 py-1 max-sm:text-[10px]'>جديد</span>
                      </div>
                    </div>

                    <div className='flex flex-col text-right w-[70px] md:w-[300px]'>
                      <span className='text-[#909090] md:text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>المحامي</span>
                      <span className=' md:text-[#909090] text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px]'>عبد الرحمن</span>
                    </div>

                    <div className='flex flex-col text-right w-[70px] md:w-[300px]'>
                      <span className='text-[#868686] md:text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>التفاصيل</span>
                      <span className='text-[18px] md:text-[#909090] text-black max-sm:text-[10px] max-sm:h-[23px]'>استشارة</span>
                    </div>
                  </div>
                  <div className='flex items-center justify-between md:mt-[50px] mt-[20px]'>
                    <div className='flex md:justify-end md:w-[300px] w-[90px] max-sm:bg-[#F2FFEF] max-sm:p-[10px] max-sm:rounded-[10px]'>
                      <div className='text-center'>
                        <div className='text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>المدعي علية</div>
                        <div className='md:text-[#909090] text-[18px] text-[#868686] max-sm:text-[10px] max-sm:h-[23px]'>فلان</div>
                      </div>
                    </div>

                    <div className='flex flex-col md:text-right text-center w-[90px] md:w-[300px] max-sm:bg-[#F2FFEF] max-sm:p-[10px] max-sm:rounded-[10px]'>
                      <span className='text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>المدعي</span>
                      <span className=' md:text-[#909090] text-[18px] text-[#939393] max-sm:text-[10px] max-sm:h-[23px]'>فلان</span>
                    </div>

                    <div className='flex flex-col md:text-right text-center w-[90px] md:w-[300px] max-sm:bg-[#F2FFEF] max-sm:p-[10px] max-sm:rounded-[10px]'>
                      <span className='text-[#2E694C] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>المحكمة</span>
                      <span className='text-[18px] md:text-[#909090] text-[#868686] max-sm:text-[10px] max-sm:h-[23px]'>محكمة المدينة</span>
                    </div>
                  </div>

                  <div className='flex items-center justify-between md:mt-[50px] mt-[20px]'>
                    <div className='flex md:justify-end w-[90px] md:w-[300px]'>
                      <div className='text-center'>
                        <div className='text-[#2E694C] text-[18px] ml-2 md:mb-[10px] mb-2 max-sm:text-[10px]'>تواصل مع محامي</div>
                        <div className='rounded-[12px] overflow-hidden gradient-border'>
                          <div className='md:px-[30px] py-[7px] rounded-[12px] md:text-[#909090] bg-white text-[16px] max-sm:text-[10px]'>ابدء محادثة</div>
                        </div>
                      </div>
                    </div>

                    <div className='flex flex-col text-center md:text-right w-[90px] md:w-[300px] max-sm:bg-[#F2FFEF] max-sm:p-[10px] max-sm:rounded-[10px]'>
                      <span className='text-[#2E694C] text-[18px] ml-2 md:mb-[10px] mb-2 max-sm:text-[10px]'>الجلسة القادمة</span>
                      <span className='h-[38px] text-[#909090] text-[18px] max-sm:text-[10px] max-sm:h-[23px]'>2024\00\00</span>
                    </div>

                    <div className='flex flex-col text-center md:text-right w-[90px] md:w-[300px] max-sm:bg-[#F2FFEF] max-sm:p-[10px] max-sm:rounded-[10px]'>
                      <span className='text-[#2E694C] text-[18px] ml-2 md:mb-[10px] mb-2 max-sm:text-[10px]'>الدائرة القضائية</span>
                      <span className='h-[38px] text-[18px] text-[#909090] max-sm:text-[10px] max-sm:h-[23px]'>النص</span>
                    </div>
                  </div>
                  <div className='w-full text-right md:mt-[50px] mt-[30px]'>
                    <span className='text-[#2E694C] text-[24px] max-sm:text-[12px]'>تفاصيل القضية</span>
                    <textarea
                      className='md:h-[147px] h-[63px] text-[18px] w-full pt-[10px] px-[15px] bg-[#f6f6f6] rounded-[5px] text-right mt-[10px] max-sm:text-[12px]'
                      placeholder='التفاصيل...'></textarea>
                  </div>
                  <div className='w-full text-right md:mt-[50px] mt-[20px]'>
                    <div className='md:text-[24px] text-[#2E694C] text-[12px]'>الأحداث</div>
                    <div className='md:py-[30px] py-[15px] bg-[#f6f6f6] rounded-[6px] mt-[10px] md:px-[30px] px-[15px]'>
                      <div className='text-black md:text-[16px] text-[10px] mb-[10px]'>موضوع الحدث</div>
                      <textarea
                        disabled={true}
                        className='md:h-[70px] h-[35px] text-[10px] md:text-[14px] py-[10px] px-[10px] md:py-[20px] md:px-[20px] w-full bg-white rounded-[6px] text-right'
                        placeholder='التفاصيل...'></textarea>
                      <div className='text-left md:text-[16px] text-[#868686] md:mt-[10px] text-[10px]'>2024\00\00</div>
                    </div>
                    <div className='md:py-[30px] py-[15px] bg-[#f6f6f6] rounded-[6px] mt-[10px] md:px-[30px] px-[15px]'>
                      <div className='text-black md:text-[16px] text-[10px] mb-[10px]'>موضوع الحدث</div>
                      <textarea
                        disabled={true}
                        className='md:h-[70px] h-[35px] text-[10px] md:text-[14px] py-[10px] px-[10px] md:py-[20px] md:px-[20px] w-full bg-white rounded-[6px] text-right'
                        placeholder='التفاصيل...'></textarea>
                      <div className='text-left md:text-[16px] text-[#868686] md:mt-[10px] text-[10px]'>2024\00\00</div>
                    </div>
                    <div className='w-full text-left'>
                      <div className='text-left md:text-[18px] text-[10px] text-white bg-[#B08D0E] rounded-[20px] mt-[20px] md:mt-[50px] md:w-[165px] w-[89px] flex justify-center items-center h-[22px] md:h-[40px]'>
                        العروض المقدمة
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  )
}
export default Consultation_requests
