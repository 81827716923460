import { React, useState, useEffect, createRef, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import useWindowSize from '../windowssize'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from '../layout/header'
import Footer from '../layout/footer'
import ReCAPTCHA from 'react-google-recaptcha'
import Auth from '../../assests/Authentication/Ai_login.svg'
import { signin } from '../../api/index';
import { AuthContext } from '../../context/AuthContext'
const clientId = '996728922482-30dme90oufqb494gir2t87a2ti7senaa.apps.googleusercontent.com'

const Login = () => {
  const { width } = useWindowSize()
  const isMobile = width < 768
  const [profile, setProfile] = useState([])
  const [phonenumber, setPhonenumber] = useState('')
  const [password, setPassword] = useState('')
  const [captchaToken, setCaptchaToken] = useState('')
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate('')
  const [isLoading, setIsLoading] = useState(false)
  const recaptchaRef = createRef()
  const { setUser } = useContext(AuthContext);

  const toastStyles = {
    success: {
      background: 'green'
    },
    error: {
      background: 'red'
    }
  }

  const handlephonenumberChange = event => {
    setPhonenumber(event.target.value)
  }
  const handlepasswordChange = event => {
    setPassword(event.target.value)
  }
  const onReCAPTCHASuccess = token => {
    setCaptchaToken(token)
  }

  const handleclick = async event => {
    setIsLoading(true)
    event.preventDefault()
    try {
      if (captchaToken) {
        const data = await signin(phonenumber, password);
        setIsLoading(false)
        if (data) {
          setVisible(true);
          const userObject = {
            token: data.token,
            username: data.username,
            email: data.email,
            phone: data.phone,
            avatar: data.avatar,
            id: data.id
          }
          localStorage.setItem('user', JSON.stringify(userObject));
          setUser(userObject);
          navigate('/');
        } else {
          toast.warning('User not found. Please enter correct information.')
        }
      } else {
        setIsLoading(false)
        toast.error('Capture failed. Please try again later.')
      }
    } catch (error) {
      toast.error('Internal server error.')
    }
  }
  return (
    <main className='bg-cover bg-center h-screen bg-no-repeat '>
      <Header />
      <ToastContainer
        className='custom-toast-container'
        toastClassName='custom-toast'
        bodyClassName='custom-toast-body'
        closeButtonClassName='custom-toast-close-button'
        progressClassName='custom-toast-progress-bar'
        position={width >= 768 ? 'top-right' : 'top-center'}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className='bg-cover bg-center h-screen bg-no-repeat'>
        <div className={`flex flex-col lg:flex-row lg:min-h-[90vh] justify-evenly lg:justify-center container py-16 lg:py-0`}>
          <div className='lg:w-[70%] flex flex-col justify-around items-center text-[white] lg:items-start py-16 lg:py-8 relative'>
            <div className='relative'>
              <div className='z-10 text-black'>
                <h1 className='font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center lg:text-right'>
                  <li className='vertical-align-inherit list-none'>
                    <span className='align-middle text-transparent bg-clip-text bg-gradient-to-r from-[#55C18D] to-[#156896]'> عدالة AI</span>
                  </li>
                </h1>
                <p className='text-2xl sm:text-xl md:text-2xl lg:text-3xl text-center lg:text-right'>
                  <li className='vertical-align-inherit list-none'>
                    <span className='align-middle'>المساعد القانوني الأول</span>
                  </li>
                  <li className='vertical-align-inherit list-none'>
                    <span className='align-middle'>بالاعتماد بالذكاء الاصطناعي </span>
                  </li>
                </p>
              </div>
              <div className='hidden lg:inline box-shadow absolute h-[20vh] w-full top-0 z-0'></div>
            </div>
            <div>
              <div className='hidden lg:inline lg:h-[60vh] lg:bottom-0 right-8 lg:absolute'>
                <div className='dotlottie-container main' lang='en'>
                  <div data-name='data' role='figure' className='animation pt-[30px]' style={{ position: 'relative', width: '100%', height: '100%', backgroundColor: 'transparent' }}>
                    <img src={Auth} className='w-[385px] h-[385px]'></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex-1 flex flex-col gap-5 lg:justify-center items-center text-black pt-24 pb-16 max-sm:pt-8 max-sm:pb-0'>
            <h2 className='font-bold text-lg sm:text-xl md:text-2xl '>
              <li className='vertical-align-inherit  list-none'>تسجيل الدخول</li>
            </h2>
            <form className='w-full flex flex-col gap-4 items-center'>
              <div className='flex flex-col gap-2 w-full'>
                <div className='space-y-2'>
                  <h2>رقم التليفون</h2>
                  <input
                    className='flex h-12 w-full rounded-md border hover:border-[#e5e7eb] border-[#a9a9a9] bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 '
                    placeholder='0599xxxxxx'
                    onChange={handlephonenumberChange}
                    type='text'
                    name='phoneNumber'
                  />
                  {/* <p id=':r3:-form-item-message' className='text-sm font-medium text-destructive'>
                  <li className='vertical-align-inherit list-none text-destructive'>الرجاء ادخال صحيحرقم جوال </li>
                </p> */}
                </div>
              </div>
              <div className='flex flex-col gap-2 w-full max-sm:mb-[40px]'>
                <div className='space-y-2'>
                  <h2>كلمة المرور</h2>
                  <input
                    className='flex h-12 w-full rounded-md border hover:border-[#e5e7eb] border-[#a9a9a9] bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2  '
                    placeholder='0123456'
                    onChange={handlepasswordChange}
                    type='password'
                    name='phoneNumber'
                  />
                </div>
              </div>
              <div>
                <div>
                  <div style={{ width: '304px', height: '78px' }}>
                    <div>
                      <ReCAPTCHA ref={recaptchaRef} sitekey='6LdFjsopAAAAALL4yOuzNplEgDd-rbJuwjC2W9PH' onChange={onReCAPTCHASuccess} size='normal' />
                    </div>
                    <textarea
                      name='g-recaptcha-response'
                      className='g-recaptcha-response'
                      style={{
                        width: '250px',
                        height: '40px',
                        border: '1px solid rgb(193, 193, 193)',
                        margin: '10px 25px',
                        padding: '0px',
                        resize: 'none',
                        display: 'none'
                      }}></textarea>
                  </div>
                  <iframe style={{ display: 'none' }}></iframe>
                </div>
              </div>

              <button
                className='inline-flex items-center justify-center rounded-full text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#224B37] text-primary-foreground hover:bg-[#224B37] hover:bg-opacity-95 glow-teal h-12 px-10 py-4 md:py-6 w-full'
                type='button'
                onClick={handleclick}>
                {isLoading ? (
                  <>
                    <svg aria-hidden='true' role='status' className='inline w-6 h-6 me-2 text-white animate-spin' viewBox='0 0 100 101' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                        fill='#E5E7EB'
                      />
                      <path
                        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                        fill='currentColor'
                      />
                    </svg>
                    <span className='text-white text-[16px]'>تحميل...</span>
                  </>
                ) : (
                  <li className='vertical-align-inherit list-none text-white'>تسجيل الدخول</li>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default Login
