import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Preloader from './loading';

const PageWithLoader = ({ Component }) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const loadAssets = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };
    setLoading(true);
    loadAssets();
  }, [location.pathname]);

  return <>{loading ? <Preloader /> : <Component />}</>;
};

PageWithLoader.propTypes = {
  Component: PropTypes.elementType.isRequired,
};

PageWithLoader.displayName = 'PageWithLoader';

export default PageWithLoader;