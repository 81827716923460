import api from '../services/api';

export const signin = async (phonenumber, password) => {
    try {
        const response = await api.post('/api/signin', {
            phonenumber,
            password,
        });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : new Error('An error occurred');
    }
};

export const signup = async (username, email, password, phonenumber, otp) => {
    try {
        const response = await api.post('/api/signup', {
            username,
            email,
            password,
            phonenumber,
            otp
        });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : new Error('An error occurred');
    }
};

export const sendOTP = async (email) => {
    try {
        const response = await api.post('/api/send-otp', {
            email
        });
        return response.data;
    } catch (error) { 
        throw error.response ? error.response.data : new Error('An error occurred');
    }
};

export const sendStream = async (message) => {
    try {
        const response = await api.post('/api/stream', {
            message
        });
        return response.data
    } catch (error) {
        throw error.response ? error.response.data : new Error('An error occurred');
    }
};

export const getChatHistory = async (userId) => {
    console.log(userId);
    try {
        const response = await api.post('/api/chat-history', {
            userId
        })
        return response.data
    } catch (error) { 
        throw error.response ? error.response.data : new Error('An error occurred');
    }
};
