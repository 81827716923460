import { React, useState } from 'react'
import Logo from '../../../assests/logo-white.svg'
import Logo1 from '../../../assests/onboarding/logo(1).svg'
import Onboarding1 from '../../../assests/onboarding/onboarding1.webp'
import Onboarding2 from '../../../assests/onboarding/onboarding2.webp'
import Step1 from './step1'
import PropTypes from 'prop-types'
const Step0 = ({ selectedAccountType, setSelectedAccountType, setStep }) => {
  //   const [selectedAccountType, setSelectedAccountType] = useState(null)
  const handleSelectAccountType = type => {
    setSelectedAccountType(type)
  }
  const handleNextButtonClick = () => {
    setStep(prev => prev + 1)
  }
  return (
    <main className='min-h-screen'>
      <div className='flex flex-row'>
        <div className='w-full lg:w-1/2 '>
          <div className='py-6 lg:py-8  w-full text-center inline-block lg:hidden'>
            <img
              alt='logo'
              loading='lazy'
              width='100'
              height='100'
              decoding='async'
              data-nimg='1'
              className='inline-flex'
              src={Logo1}
              style={{ color: 'transparent' }}
            />
          </div>
          <div className='flex items-center justify-center px-4 lg:px-0 min-h-[80vh] lg:min-h-screen'>
            <div className='max-w-xl w-full flex justify-center'>
              <div className='rounded-lg text-card-foreground w-full shadow-none border-0 bg-inherit'>
                <div className='flex flex-col space-y-1.5 p-6'>
                  <h3 className='text-2xl font-semibold leading-none text-color2 tracking-tight'>نوع المستخدم</h3>
                  <h3 className='text-2xl font-semibold leading-none tracking-tight'></h3>
                  <h3 className='text-2xl font-semibold leading-none tracking-tight'></h3>
                  <h3 className='text-2xl font-semibold leading-none tracking-tight'></h3>
                  <h3 className='text-2xl font-semibold leading-none tracking-tight'></h3>
                  <h3 className='text-2xl font-semibold leading-none tracking-tight'></h3>
                </div>
                <div className='p-6 pt-0'>
                  <form>
                    <div className='flex flex-col gap-2 py-4 px-2'>
                      <h3 className='text-gray-500'>لتبدأ رحلتك، حدد نوع الحساب المرجو إنشائه</h3>
                      <ul className='space-y-2 mt-2'>
                        <li
                          className={`${
                            selectedAccountType === 'individual'
                              ? 'bg-emerald-50 shadow-none border-primary border-2 py-4 px-2 rounded-md cursor-pointer'
                              : 'bg-emerald-50 shadow-none  border-2 py-4 px-2 rounded-md cursor-pointer'
                          }`}
                          onClick={() => handleSelectAccountType('individual')}>
                          <label className='flex flex-row gap-2 items-center cursor-pointer' htmlFor='individual'>
                            <div
                              className={`${
                                selectedAccountType === 'individual'
                                  ? 'bg-primary py-2 px-2 rounded-full border-2 border-primary'
                                  : 'bg-white py-2 px-2 rounded-full border-2 border-primary'
                              }`}
                              onClick={() => handleSelectAccountType('individual')}>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className={`${selectedAccountType === 'individual' ? 'text-white' : 'text-primary'}`}
                                onClick={() => handleSelectAccountType('individual')}>
                                <path d='M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2'></path>
                                <circle cx='12' cy='7' r='4'></circle>
                              </svg>
                            </div>
                            <div className='flex flex-col gap-2'>
                              <h4 className='text-primary font-semibold'>فرد</h4>
                              <p className='text-gray-400 font-medium'>
                                حساب للإستخدام الشخصي، يُمكن من خلال شخص واحد فقط استخدامه
                              </p>
                            </div>
                          </label>
                        </li>
                        <li
                          className={`${
                            selectedAccountType === 'company'
                              ? 'bg-emerald-50 shadow-none border-primary border-2 py-4 px-2 rounded-md cursor-pointer '
                              : 'shadow-md border-white bg-white border-2 py-4 px-2 rounded-md cursor-pointer'
                          }`}
                          onClick={() => handleSelectAccountType('company')}>
                          <label className='flex flex-row gap-2 items-center cursor-pointer' htmlFor='company'>
                            <div
                              className={`${
                                selectedAccountType === 'company'
                                  ? 'bg-primary py-2 px-2 rounded-full border-2 border-primary'
                                  : 'bg-white py-2 px-2 rounded-full border-2 border-primary'
                              }`}
                              onClick={() => handleSelectAccountType('company')}>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className={`${selectedAccountType === 'company' ? 'text-white' : 'text-primary'}`}
                                onClick={() => handleSelectAccountType('company')}>
                                <rect width='20' height='14' x='2' y='7' rx='2' ry='2'></rect>
                                <path d='M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16'></path>
                              </svg>
                            </div>
                            <div className='flex flex-col gap-2'>
                              <h4 className='text-primary font-semibold'>شركة</h4>
                              <p className='text-gray-400 font-medium'>
                                حساب خاص للشركة، يمكن للشركة انشاء عدد الحسابات التي تحدده الشركة (قريبا...){' '}
                              </p>
                            </div>
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div className='flex justify-center items-center '>
                      <button
                        className='inline-flex items-center justify-center md:text-md text-white font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-teal-900 glow-teal h-12 px-10 py-4 md:py-6 rounded-md relative text-lg w-4/5'
                        type='button'
                        onClick={handleNextButtonClick}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='absolute right-5'>
                          <path d='M5 12h14'></path>
                          <path d='m12 5 7 7-7 7'></path>
                        </svg>
                        التالي
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='hidden lg:flex lg:w-1/2 min-h-screen bg-accent p-12  flex-col items-center relative'>
          <div className=' '>
            <img
              alt='logo'
              loading='lazy'
              width='100'
              height='100'
              decoding='async'
              data-nimg='1'
              src={Logo}
              style={{ color: 'transparent' }}
            />
          </div>
          <div className='flex flex-col gap-2 items-end  justify-center w-full h-4/5'>
            <div className=' relative  pl-[2vw]'>
              <img
                alt='logo'
                loading='lazy'
                width='400'
                height='400'
                decoding='async'
                data-nimg='1'
                className=' z-20 relative w-[25vw]'
                srcSet={`${Onboarding2}?w=640&q=75 1x, ${Onboarding2}?w=828&q=75 2x`}
                src={Onboarding2}
                style={{ color: 'transparent', visibility: 'visible' }}
                data-xblocker='passed'
              />
              <img
                alt='logo'
                loading='lazy'
                width='400'
                height='400'
                decoding='async'
                data-nimg='1'
                className=' absolute top-[10vw] left-[18vw] z-10 w-[25vw]'
                srcSet={`${Onboarding1}?w=640&q=75 1x, ${Onboarding1}?w=828&q=75 2x`}
                src={Onboarding1}
                style={{ color: 'transparent', visibility: 'visible' }}
                data-xblocker='passed'
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

Step0.propTypes = {
  selectedAccountType: PropTypes.any.isRequired,
  setSelectedAccountType: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired
}

export default Step0
