import { useState, React, useContext, useEffect, useRef, useMemo } from 'react'
import { useSocket } from './socket'
import ReactMarkdown from 'react-markdown'
import Header from '../../component/layout/header'
import { useNavigate } from 'react-router-dom'
import vector4 from '../../assests/Icons/Vector4.png'
import logo_1 from '../../assests/Landing/logo_1.png'
import logo3 from '../../assests/Landing/logo3.png'
import Send from '../../assests/Icons/send.png'
import personal_picture from '../../assests/personal_picture.webp'
import Logo_white from '../../assests/logo-white.svg'
import { useAppDataStore } from './appdata'
import { getSimpleId } from './utils'
import LoadingText from './loadingtext'
import { AuthContext } from '../../context/AuthContext'
import { getChatHistory } from '../../api/index'
import { usernamefetch, Adal_stream, username, adal_stream } from '../../Route/APIRoute'
const store = useAppDataStore()

const Chatbot = () => {
  const navigate = useNavigate('')
  const objectid = localStorage.getItem('Userid')
  const { socket, state } = useSocket()
  const [isSocketConnected, setIsSocketConnected] = useState(socket.connected)
  const { name, id, setNameToLocalStorage, setIdToLocalStorage } = useAppDataStore()
  const [dashnavbarShow, setDashnavbarShow] = useState(false)
  const [isDash, setIsDash] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [showContent, setShowContent] = useState(true)
  const timRef = useRef(null)
  const messageRef = useRef(null)
  const inputRef = useRef(null)
  const [connected, setConnected] = useState(socket.connected)
  const [isConnecting, setIsConnecting] = useState(false)
  const [isStreaming, setIsStreaming] = useState(false)
  const [userName, setUserName] = useState('')
  const [userId, setUserId] = useState('')
  const [message, setMessage] = useState('')
  const [messageEvents, setMessageEvents] = useState([])
  const [isAIProcessing, setIsAIProcessing] = useState(false)
  const [isDialogShown, setIsDialogShown] = useState(false)
  const [threadId, setThreadId] = useState(null)
  const [avatarUrl, setAvatarUrl] = useState('')
  const textareaRef = useRef(null)
  const { user } = useContext(AuthContext);
  
  const toggleDash = () => {
    setIsDash(!isDash)
  }
  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    })
  }
  const resetTextareaHeight = () => {
    const textarea = textareaRef.current
    if (!textarea) return
    textarea.style.height = 'auto' // Reset height
  }
  const fetchChatHistory = async (userId) => {
    try {
      const history = await getChatHistory(userId);
      setMessageEvents(history);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };
  //useEffect(() => {
  //  scrollToBottom()
  //}, [messageEvents])

  useEffect(() => {
    if (user) {
      fetchChatHistory(user?.id);
    }
  }, [user]);
  const handleKeyDown = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault() // Prevent default newline behavior
      if (message.trim() !== '') {
        // Check if message is not just empty spaces
        handleSend() // Your function to send the data
        setMessage('')
      }
    }
  }
  useEffect(() => {
    setAvatarUrl(user?.avatar);
    setUserName(user?.username);
  }, [user])

  const sendToSocket = user_message => {
    setMessageEvents(prevMessages => [...prevMessages, user_message]) // Adde
    socket.current.emit('message', user_message) // Emit the message via socket
    setMessage('') // Clear the input field
    resetScroll() // Assuming resetScroll is defined to manage scroll position
  }

  const sendToStream = async user_message => {
    setIsAIProcessing(true)
    setMessageEvents(prevEvents => [...prevEvents, user_message])
    setMessage('')
    resetScroll()

    try {
      const response = await fetch(adal_stream, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.token}`
        },
        body: JSON.stringify(user_message)
      })

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem('user');
          window.location.href = '/login';
        } else {
          console.log('Oops, an error occurred', response.status)
          throw new Error('Network response was not ok.')
        }
      }
      const msg_id = getSimpleId()
      let assistant_message = {
        user_id: null,
        name: 'Saudi legal Gpt',
        content: '',
        role: 'assistant',
        id: msg_id,
        created_at: Date.now()
      }

      setMessageEvents(prevEvents => [...prevEvents, assistant_message])
      const reader = response.body.getReader()
      let flag = true

      while (flag) {
        const { done, value } = await reader.read()
        if (done) {
          flag = false
          break
        }
        const text = new TextDecoder().decode(value)

        setMessageEvents(prevEvents =>
          prevEvents.map(item => ({
            ...item,
            content: item.id === msg_id ? item.content + text : item.content
          }))
        )

        resetScroll()
        scrollToBottom()
      }
    } catch (error) {
      console.log(error.name, error.message)
    } finally {
      setIsAIProcessing(false)
    }
  }

  const handleSend = () => {
    setShowContent(false)
    if (isAIProcessing) {
      return
    }
    console.log(Date.now())

    const user_message = {
      user_id: userId,
      name: userName,
      content: message,
      role: 'user',
      id: getSimpleId(),
      created_at: Date.now(),
      thread_id: threadId
    }

    sendToStream(user_message)

    // Reset message input
    scrollToBottom()
    resetTextareaHeight() // Reset the height of the textarea
    resetScroll()
  }
  const handleSubmitName = value => {
    // setIsConnecting(true)
    setUserName(value)
    store.setNameToLocalStorage(value)
    // Call the function from the custom hook

    // if (state.connected) {
    //   // Assuming you have state management for connection status
    //   socket.emit('register', { user_id: id, name: value })
    // } else {
    //   // socket.connect()
    //   console.log('disconnect')
    // }
  }
  const resetScroll = () => {
    if (timRef.current) {
      clearTimeout(timRef.current)
    }

    timRef.current = setTimeout(() => {
      if (messageRef.current) {
        messageRef.current.scrollTop = messageRef.current.scrollHeight
      }
    }, 300)
  }
  const showSystemMessage = (name, stype) => {
    const messageText = stype === 'welcome' ? `Welcome ${name}` : stype === 'disconnect' ? 'You are disconnected from the server' : `${name} has ${stype === 'join' ? 'joined' : 'left'} the discussion`

    const systemMessage = {
      user_id: '',
      name: 'system',
      content: messageText,
      role: 'system',
      id: getSimpleId(),
      created_at: Date.now()
    }

    setMessageEvents(prevMessages => [...prevMessages, systemMessage])
    resetScroll()
  }
  const getBackgroundClass = (role, user_id, currentUserId) => {
    if (role === 'system') {
      return 'system'
    }
    if (role === 'assistant') {
      return 'bot'
    }
    return user_id !== currentUserId ? 'other' : 'user'
  }
  const handleAIOnStart = () => {
    isAIProcessing.value = true
  }
  const handleChange = event => {
    setMessage(event.target.value)
    adjustTextareaHeight()
  }
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current
    if (!textarea) return
    textarea.style.height = 'auto' // Reset height to recalculate
    const maxHeight = 300 // Maximum height with scrollbar
    const newHeight = Math.min(textarea.scrollHeight, maxHeight)
    textarea.style.height = `${newHeight}px`
  }
  const handleAIOnEnd = () => {
    isAIProcessing.value = false
  }
  const messages = useMemo(() => {
    return [...messageEvents].sort((a, b) => {
      if (a.created_at > b.created_at) return 1
      if (a.created_at < b.created_at) return -1
      return 0
    })
  }, [messageEvents])
  useEffect(() => {
    console.log('Userid', userId)
    console.log('name', userName)
    if (!isSocketConnected && userName) {
      socket.connect()
      setIsSocketConnected(true)
      socket.emit('register', { user_id: userId, name: userName })
      socket.on('thread__id', async params => {
        console.log('thread_id', params)
        const thread__id = params
        setThreadId(thread__id)
      })
    }
  }, [userName, isSocketConnected])

  useEffect(() => {
    resetScroll()
  }, [state.messageTrigger])

  useEffect(() => {
    // console.log('system-trigger', state.systemTrigger, state.systemTrigger.data)
    switch (state.systemTrigger.type) {
      case 'welcome':
        setIsDialogShown(false)
        inputRef.current.focus()
        showSystemMessage(userName, state.systemTrigger.type)
        break
      case 'disconnect':
        showSystemMessage(userName, state.systemTrigger.type)
        break
      case 'leave':
      case 'join':
        showSystemMessage(state.systemTrigger.data.name, state.systemTrigger.type)
        break
      case 'ai-start':
        handleAIOnStart()
        break
      case 'ai-end':
        handleAIOnEnd()
        break
      default:
        break
    }
  }, [state.systemTrigger])

  useEffect(() => {
    if (connected) {
      setUserId(prevId => {
        const newId = getSimpleId()
        store.setIdToLocalStorage(newId)
        return newId
      })
      // setUserName(setNameToLocalStorage)
      setNameToLocalStorage(userName)
    } else {
      setUserId(prevId => {
        const newId = getSimpleId()
        store.setIdToLocalStorage(newId)
        return newId
      })
      setIsDialogShown(true)
    }
  }, [connected])
  return (
    <div
      className='h-full overflow-x-hidden bg-[#f6f6f6] bg-no-repeat bg-contain sm:bg-none'
      style={{ backgroundImage: `url(${vector4})`, backgroundPositionY: '100px', backgroundPositionX: '-50px' }}>
      {showContent ? (
        <div className='container md:mt-[200px] mt-[70px] px-0'>
          <div className='flex flex-col md:gap-[160px] gap-[140px] justify-center items-center'>
            <div className='bg-[#224B37] text-white p-6 rounded-lg relative w-full md:w-[830px] h-[370px] md:h-[400px] mt-[-75px] '>
              <button
                className='absolute top-2 right-2 text-white text-[20px] py-1 px-3 rounded'
                onClick={() => {
                  navigate('/person')
                }}>
                رجوع
              </button>
              <div className='flex flex-col items-center justify-center h-full'>
                <div className='mb-4 bg-no-repeat w-[205px] md:w-[260px] h-[102px] md:h-[130px] max-sm:bg-cover' style={{ backgroundImage: `url(${logo3})` }}>
                  {/* <img src={logo1} alt='ADALA Logo' className='h-[130px] w-[260px]' /> */}
                </div>
                <div className='text-center'>
                  <div className='md:text-[32px] text-[20px]'>بدء محادثة مع عدالة AI</div>
                </div>
              </div>
            </div>
            <div className='flex justify-center'>
              <div className='flex justify-center items-center w-[113px] md:w-[165px] md:h-[55px] h-[38px] bg-[#224B37] text-[#FFFFFF] text-[14px] md:text-[20px] rounded-[10px] mb-[50px]'>
                التالي&gt;
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='relative'>
          <Header />
          <div className='container md:px-0'>
            <div className='pt-[30px] overflow-y-auto scrollbar md:text-[16px] text-[10px]'>
              {messages.map(msg => (
                <div className={`flex mt-[20px] ${msg.role === 'assistant' ? 'flex-row-reverse mt-[20px] gap-[5px]' : ''}`} key={msg.id}>
                  {msg.role !== 'system' && (
                    <div className='sender flex flex-col items-center'>
                      <div className='avatar'>
                        {msg.role === 'user' ? (
                          <img
                            width='1000'
                            height='1000'
                            decoding='async'
                            data-nimg='1'
                            className='bg-cover h-12 w-12 rounded-full max-sm:hidden mx-[5px]'
                            src={avatarUrl ? avatarUrl : personal_picture} // Convert the File object to a data URL
                            alt='Selected file' // Add an alt attribute for accessibility
                          />
                        ) : (
                          <div className='max-sm:hidden w-[48px] h-[48px] rounded-full bg-[#224B37] flex justify-center items-center'>
                            <span className='text-white text-[12px]'>Adalasa</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className='flex flex-row-reverse-end'>
                    <div className={`message-contents ${msg.role === 'assistant' ? 'ml-0' : 'mr-1 '}`}>
                      <div
                        className={`${getBackgroundClass(msg.role, msg.user_id)}${
                          msg.role === 'assistant'
                            ? 'flex-col md:py-4 md:px-4 px-2 py-2 bg-accent text-white rounded-bl-xl rounded-r-lg max-w-5xl whitespace-pre-line  overflow-y-auto chat-message-item'
                            : 'flex-col md:py-4 md:px-4 px-2 py-2 justify-end bg-[#224B37] text-white rounded-br-xl rounded-l-xl left-0 max-w-5xl whitespace-pre-line overflow-y-auto'
                        }  `}>
                        <ReactMarkdown>{msg.content}</ReactMarkdown>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* {isAIProcessing && msg.user_id !== userId && (<div className='loading-text'>{<LoadingText />}</div>)} */}
              {isAIProcessing ? (
                <div className='flex justify-end'>
                  <LoadingText />
                </div>
              ) : null}
              <div className='h-[92px]'></div>
            </div>
          </div>
        </div>
      )}
      <div className='fixed w-full bottom-0 z-9'>
        <div className='w-full px-0 container'>
          <div className='bg-[#224B37] text-white md:p-2 p-1 rounded-lg flex items-center space-x-4 pl-[20px] md:pl-[90px] md:pr-[80px]' dir='ltr'>
            <textarea
              ref={textareaRef}
              rows={1}
              className='flex-grow p-4 md:text-[28px] text-[10px] bg-gray-100 text-right rounded-lg focus:outline-none text-black'
              placeholder='مراسلة عدالة'
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={message}
              style={{ overflowY: 'auto' }}
            />
            <button className='md:w-[60px] md:h-[60px] w-[30px] h-[30px]' onClick={handleSend}>
              <img src={Send} alt='send' className='md:ml-[30px] ml-[-5px]' />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chatbot
