import './global.css'
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, BrowserRouter, useLocation } from 'react-router-dom'
import { UserAccountProvider } from './context/UserContext'
import { AuthProvider } from './context/AuthContext'
import { NavbarProvider } from './context/NavbarContext'
import ProtectedRoute from './Route/ProtectedRoute'
import SecretRoute from './Route/SecretRoute'
import Register from './component/Authentication/register'
import Login from './component/Authentication/login'
import Landing from './screens/landing'
import Onboarding from './component/UserAcoount_steps/onboarding'
import Dashboard from './screens/dashboard/dashboard'
import Search from './screens/dashboard/search'
import Lawyer from './screens/dashboard/Asklawyer'
import Myfile from './screens/dashboard/Myfile'
import Comingsoon from './screens/dashboard/Judicalfiles'
import General from './component/Account Settings/general'
import Personal from './component/personal'
import Chatbot from './component/chatbot/chatbot'
import Lawservice from './component/Lawyer_request/Lawservice'
import Lawoffer from './component/Lawyer_request/Lawoffer'
import My_requests from './component/Lawyer_request/My_requests'
import AI_justice from './component/AI_justice'
import Consultation_requests from './component/Lawyer_request/consultation_requests'
import Settingindex from './component/Account Settings/settingindex'
import PageWithLoader from './component/layout/pagewithLoader';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <NavbarProvider>
          <Routes>
            <Route path="/" element={<PageWithLoader Component={Landing} />} />
            <Route path='/register' element={<PageWithLoader Component={Register} />} />
            <Route path='/login' element={<PageWithLoader Component={Login} />} />
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/person" element={<Personal />} />
            </Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/justice" element={<AI_justice />} />
            </Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/chatbot" element={<Chatbot />} />
            </Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/myrequest" element={<My_requests />} />
            </Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/consultation" element={<Consultation_requests />} />
            </Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/lawservice" element={<Lawservice />} />
            </Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/lawoffer" element={<Lawoffer />} />
            </Route>
            
            {/* Previous components */}
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/onboarding" element={<Onboarding />} />
            </Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
            </Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/lawservice" element={<Lawservice />} />
            </Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/search" element={<Search />} />
            </Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/lawyer" element={<Lawyer />} />
            </Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/myfile" element={<Myfile />} />
            </Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/comingsoon" element={<Comingsoon />} />
            </Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/setting" element={<Settingindex />} />
            </Route>
          </Routes>
        </NavbarProvider>
      </Router>
    </AuthProvider>
  );
};

export default App
