import React, { useState, useEffect } from 'react'

const LoadTextureComponent = () => {
  const [data, setData] = useState(new Array(7).fill(0))
  const [startTimer, setStartTimer] = useState(true)

  useEffect(() => {
    let timer

    if (startTimer) {
      let cnt = 0
      timer = setInterval(() => {
        setData(data.map((n, i) => 2 * Math.sin(cnt + 2 * Math.PI * ((i + 1) / 8))))
        cnt++
      }, 100)
    } else {
      clearInterval(timer)
    }

    return () => {
      clearInterval(timer)
    }
  }, [startTimer])

  return (
    <div className='load-texture-container'>
      <div className='inner'>
        {data.map((n, index) => (
          <div key={index} className='item' style={{ transform: `translateY(${n}px)` }}></div>
        ))}
      </div>
    </div>
  )
}

export default LoadTextureComponent
