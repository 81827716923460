import React from 'react'
import personal_picture from '../../assests/personal_picture.webp'
const Subscription = () => {
  return (
    <div
      data-state='active'
      data-orientation='horizontal'
      role='tabpanel'
      aria-labelledby='radix-:rd:-trigger-subscription'
      id='radix-:rd:-content-subscription'
      tabIndex='0'
      className='mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 py-4 px-6'
      dir='rtl'
      style={{}}
    >
      <div className='py-4 px-0 md:px-6 w-full h-full'>
        <div className='flex flex-col gap-4 w-full'>
          <div className='rounded-3xl p-4 bg-white overflow-hidden'>
            <div className='relative w-full overflow-auto'>
              <table className='w-full caption-bottom text-sm' dir='rtl'>
                <thead className='[&amp;_tr]:border-b'>
                  <tr className='border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted'>
                    <th className='h-12 align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0 text-center p-4'>
                      <div className='font-bold text-base'>رقم</div>
                    </th>
                    <th className='h-12 align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0 text-center p-4'>
                      <div className='font-bold text-base'>نوع الاشتراك</div>
                    </th>
                    <th className='h-12 align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0 text-center p-4'>
                      <div className='font-bold text-base'>تاريخ الشراء</div>
                    </th>
                    <th className='h-12 align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0 text-center p-4'>
                      <div className='font-bold text-base'>مدة الاشتراك</div>
                    </th>
                    <th className='h-12 align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0 text-center p-4'>
                      <div className='font-bold text-base'>حالة</div>
                    </th>
                  </tr>
                </thead>
                <tbody className='[&amp;_tr:last-child]:border-0 text-sm text-center scroll'>
                  <tr className='border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted'>
                    <td className='p-4 align-middle [&amp;:has([role=checkbox])]:pr-0 h-24 text-center'></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='w-full'>
            <div className='w-full flex flex-wrap gap-4 items-center justify-center md:justify-between'>
              <div className='flex flex-row flex-wrap justify-center items-center gap-4'>
                <div className='flex items-center gap-2'>
                  <button
                    className='inline-flex items-center justify-center rounded-full text-sm md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-accent-foreground border-2 border-accent-foreground bg-background hover:bg-accent hover:text-accent-foreground md:py-6 h-10 w-10 p-0'
                    disabled=''
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      className='lucide lucide-chevron-right'
                    >
                      <path d='m9 18 6-6-6-6'></path>
                    </svg>
                  </button>
                  {/* Include the rest of your buttons */}
                </div>
                <div className='flex items-center justify-center text-sm font-medium gap-2'>
                  <div>صفحة 1 ل 0</div>
                </div>
              </div>
              <div className='text-center md:text-right text-sm text-accent'>
                <div>0 ل 0 الصف (الصفوف) المحدد.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Subscription
