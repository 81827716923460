import React, { useState, useEffect } from 'react'
import { io } from 'socket.io-client'
import APP_HOST from '../../Route/envVariables'

export const useSocket = () => {
  const [state, setState] = useState({
    id: '',
    connected: false,
    messageEvents: [],
    messageTrigger: [],
    connectTrigger: [],
    systemTrigger: [],
    threadId: null // Add threadId state
  })

  // Defining the URL using environment variables

  const url = APP_HOST;
  // const test = process.env.REACT_APP_SERVER_IPADDRESS
  // Initialize socket
  const socket = io(url, { autoConnect: false })
  // const socket = io(url, { autoConnect: false })
  useEffect(() => {
    // Set up all socket event listeners
    const setupSocket = () => {
      socket.on('connect', () => {
        console.log('connect', socket.id, new Date().toLocaleTimeString())
        setState(prevState => ({
          ...prevState,
          id: socket.id,
          connected: true,
          connectTrigger: [Date.now()]
        }))
      })

      socket.on('welcome', () => {
        console.log('welcome', new Date().toLocaleTimeString())
        setState(prevState => ({
          ...prevState,
          systemTrigger: [{ type: 'welcome', iat: Date.now() }]
        }))
      })

      socket.on('message-list', data => {
        console.log('message-list', data, new Date().toLocaleTimeString())
        setState(prevState => ({
          ...prevState,
          messageEvents: [...prevState.messageEvents, ...data],
          messageTrigger: [Date.now()]
        }))
      })

      socket.on('join', data => {
        console.log('join', data, new Date().toLocaleTimeString())
        setState(prevState => ({
          ...prevState,
          systemTrigger: [{ type: 'join', data, iat: Date.now() }]
        }))
      })

      socket.on('leave', data => {
        console.log('leave', data, new Date().toLocaleTimeString())
        setState(prevState => ({
          ...prevState,
          systemTrigger: [{ type: 'leave', data, iat: Date.now() }]
        }))
      })

      socket.on('disconnect', () => {
        console.log('disconnect', new Date().toLocaleTimeString())
        setState(prevState => ({
          ...prevState,
          connected: false,
          systemTrigger: [{ type: 'disconnect', iat: Date.now() }]
        }))
      })

      socket.on('message', data => {
        console.log('message', data, new Date().toLocaleTimeString())
        setState(prevState => ({
          ...prevState,
          messageEvents: [...prevState.messageEvents, data],
          messageTrigger: [Date.now()]
        }))
      })

      socket.on('ai-start', () => {
        console.log('ai-start', new Date().toLocaleTimeString())
        setState(prevState => ({
          ...prevState,
          systemTrigger: [{ type: 'ai-start', iat: Date.now() }]
        }))
      })

      socket.on('ai-end', () => {
        console.log('ai-end', new Date().toLocaleTimeString())
        setState(prevState => ({
          ...prevState,
          systemTrigger: [{ type: 'ai-end', iat: Date.now() }]
        }))
      })
    }

    setupSocket()

    // Cleanup function to remove socket event listeners when the component unmounts
    return () => {
      socket.off('connect')
      socket.off('welcome')
      socket.off('message-list')
      socket.off('join')
      socket.off('leave')
      socket.off('disconnect')
      socket.off('message')
      socket.off('ai-start')
      socket.off('ai-end')
    }
  }, []) // Empty dependency array to ensure this effect runs only once after the initial rendering

  return { socket, state }
}
