import { React, useState } from 'react'
import Header from '../layout/header'
import Footer from '../layout/footer'
import { useNavbar } from '../../context/NavbarContext'
import logo from '../../assests/Law_request/logo_offer.png'
import ArrowDown from '../../assests/Landing/allow-down.png'
import Arrow2 from '../../assests/Law_request/Arrow2.svg'
import Star from '../../assests/Law_request/Star.png'
import Vector from '../../assests/Law_request/Vector.png'
const Lawyer = () => {
  const [open, setOpen] = useState({})
  const [openmobile, setOpenmobile] = useState({})
  const { navbarShow, setNavbarShow } = useNavbar()
  return (
    <div className='h-full overflow-x-hidden bg-[#f6f6f6]'>
      <Header />
      <div className='container md:px-0'>
        <div className='w-full md:mt-[80px] mt-[30px] mb-[25px]'>
          <div className='flex justify-center items-center h-[25px] w-[85px] bg-[#224B37] rounded-[20px] text-white mb-[10px]'>رجوع</div>
          <div className='md:text-[32px] text-[18px] text-[#232323]'>الخدمات&gt;الافراد &gt; السجل &gt;العروض </div>
        </div>
        <div className=' flex justify-center items-center mb-[20px] md:mb-[60px]'>
          <img src={logo} alt='logo' className='md:h-[336px] md:w-[308px] h-[97px] w-[90px]' />
        </div>
        <div className='mb-[100px]'>
          <div key={1} className='w-full bg-white md:rounded-[20px] rounded-[10px] mb-[20px]'>
            <div className='w-full h-[133px] md:h-[250px] text-[18px] flex justify-center items-center'>
              <div className='w-full flex flex-row px-[15px] md:px-[48px] items-center'>
                <div className='w-[93%] h-full '>
                  <div className='h-[50%] flex justify-between items-center text-[10px] md:text-[19px]'>
                    <div className='md:w-[120px] w-[70px] flex flex-col md:gap-2'>
                      <span className='text-[#909090] text-center'>المحامي</span>
                      <span className='text-black text-center'>عبد الرحمن</span>
                      <div></div>
                    </div>
                    <div className='md:w-[180px] flex flex-col gap-1 md:gap-2 max-sm:w-[70px]'>
                      <div className='flex flex-col justify-center items-center'>
                        <span className='text-[#909090] text-center max-sm:gap-1'>التخصص</span>
                        <span className='text-black'>احوال شخصية</span>
                      </div>
                    </div>
                    <div className='flex flex-col gap-1 md:gap-2 items-center max-sm:w-[90px]'>
                      <span className='w-[40px] text-[#909090]'>السعر</span>
                      <div className='md:w-[88px] w-[41px] h-[16px] md:h-[36px] rounded-[38px] bg-[#224B37] text-white flex justify-center items-center'>50$</div>
                    </div>
                  </div>
                  <div className='h-[50%] flex justify-between items-center text-[10px] md:text-[19px] mt-[10px] md:mt-[20px]'>
                    <div className='md:w-[120px] flex flex-col md:gap-2 w-[70px]'>
                      <span className='text-[#909090] text-center'>تاريخ الرخصة</span>
                      <span className='text-black text-center'>2024/1/1</span>
                      <div></div>
                    </div>
                    <div className='flex flex-col gap-2 text-center'>
                      <div>
                        <span className='w-[60px] text-[#909090]'>التقييم</span>
                      </div>
                      <div className='md:w-[180px] flex flex-row justify-center items-center'>
                        <div className='flex flex-row'>
                          <img src={Star} alt='Star' className='max-sm:w-[15px] max-sm:h-[15px]' />
                          <img src={Star} alt='Star' className='max-sm:w-[15px] max-sm:h-[15px]'/>
                          <img src={Star} alt='Star' className='max-sm:w-[15px] max-sm:h-[15px]'/>
                          <img src={Star} alt='Star' className='max-sm:w-[15px] max-sm:h-[15px]'/>
                          <img src={Star} alt='Star' className='max-sm:w-[15px] max-sm:h-[15px]'/>
                        </div>
                        <span className='mr-[10px]'>10</span>
                        <div></div>
                      </div>
                    </div>
                    <div className='w-[80px] flex flex-col gap-2 items-center'>
                      <span className='text-[#909090] text-center'>المدينة</span>
                      <span className='text-black text-center'>الرياض</span>
                    </div>
                  </div>
                </div>
                <button
                  className={`w-[7%] h-full flex justify-end items-center`}
                  onClick={() => {
                    setOpen(values => ({ ...values, 1: values['1'] ? false : true }))
                  }}>
                  {open[1] ? (
                    <img src={ArrowDown} alt='ArrowDown' className='max-sm:w-[20px] max-sm:h-[12px]'/>
                  ) : (
                    <img src={Arrow2} alt='Arrow2' className='max-sm:w-[16px] max-sm:h-[20px]' />
                  )}
                </button>
              </div>
            </div>
            <div className={`transition-all px-[15px] md:px-[48px] duration-300 overflow-hidden ${open[1] ? 'max-h-screen' : 'max-h-0'}`}>
              <textarea className='w-full h-[130px] md:h-[224px] border border-green-700 p-[18px] text-[10px] md:text-[19px] md:rounded-[15px] rounded-[8px]' placeholder='تفاصيل المحامي'></textarea>
              <div className='flex justify-center mt-[10px] md:mt-[35px] gap-[1rem] md:gap-[10rem] pb-[20px] md:pb-[48px]'>
                <button className='w-[305px] max-sm:text-[14px] h-[36px] md:h-[68px] bg-gradient-to-r from-[#2CB584] to-[#3C9977] border-none rounded-[8px] text-white flex justify-center items-center'>قبول </button>
                <button className='w-[305px] max-sm:text-[14px] h-[36px] md:h-[68px] border border-[#3A9E7A] rounded-[8px] flex justify-center items-center text-[#3A9E7A] gap-[0.8rem]'>
                  <span>تواصل</span>
                  <div>
                    <img src={Vector} alt='Vector' className='max-sm:w-[20px] max-sm:h-[20px]'/>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div key={2} className='w-full bg-white md:rounded-[20px] rounded-[10px] mb-[20px]'>
            <div className='w-full h-[133px] md:h-[250px] text-[18px] flex justify-center items-center'>
              <div className='w-full flex flex-row px-[15px] md:px-[48px] items-center'>
                <div className='w-[93%] h-full '>
                  <div className='h-[50%] flex justify-between items-center text-[10px] md:text-[19px]'>
                    <div className='md:w-[120px] w-[70px] flex flex-col md:gap-2'>
                      <span className='text-[#909090] text-center'>المحامي</span>
                      <span className='text-black text-center'>عبد الرحمن</span>
                      <div></div>
                    </div>
                    <div className='md:w-[180px] flex flex-col gap-1 md:gap-2 max-sm:w-[70px]'>
                      <div className='flex flex-col justify-center items-center'>
                        <span className='text-[#909090] text-center max-sm:gap-1'>التخصص</span>
                        <span className='text-black'>احوال شخصية</span>
                      </div>
                    </div>
                    <div className='flex flex-col gap-1 md:gap-2 items-center max-sm:w-[90px]'>
                      <span className='w-[40px] text-[#909090]'>السعر</span>
                      <div className='md:w-[88px] w-[41px] h-[16px] md:h-[36px] rounded-[38px] bg-[#224B37] text-white flex justify-center items-center'>50$</div>
                    </div>
                  </div>
                  <div className='h-[50%] flex justify-between items-center text-[10px] md:text-[19px] mt-[10px] md:mt-[20px]'>
                    <div className='md:w-[120px] flex flex-col md:gap-2 w-[70px]'>
                      <span className='text-[#909090] text-center'>تاريخ الرخصة</span>
                      <span className='text-black text-center'>2024/1/1</span>
                      <div></div>
                    </div>
                    <div className='flex flex-col gap-2 text-center'>
                      <div>
                        <span className='w-[60px] text-[#909090]'>التقييم</span>
                      </div>
                      <div className='md:w-[180px] flex flex-row justify-center items-center'>
                        <div className='flex flex-row'>
                          <img src={Star} alt='Star' className='max-sm:w-[15px] max-sm:h-[15px]' />
                          <img src={Star} alt='Star' className='max-sm:w-[15px] max-sm:h-[15px]'/>
                          <img src={Star} alt='Star' className='max-sm:w-[15px] max-sm:h-[15px]'/>
                          <img src={Star} alt='Star' className='max-sm:w-[15px] max-sm:h-[15px]'/>
                          <img src={Star} alt='Star' className='max-sm:w-[15px] max-sm:h-[15px]'/>
                        </div>
                        <span className='mr-[10px]'>10</span>
                        <div></div>
                      </div>
                    </div>
                    <div className='w-[80px] flex flex-col gap-2 items-center'>
                      <span className='text-[#909090] text-center'>المدينة</span>
                      <span className='text-black text-center'>الرياض</span>
                    </div>
                  </div>
                </div>
                <button
                  className={`w-[7%] h-full flex justify-end items-center`}
                  onClick={() => {
                    setOpen(values => ({ ...values, 2: values['2'] ? false : true }))
                  }}>
                  {open[2] ? (
                    <img src={ArrowDown} alt='ArrowDown' className='max-sm:w-[20px] max-sm:h-[12px]'/>
                  ) : (
                    <img src={Arrow2} alt='Arrow2' className='max-sm:w-[16px] max-sm:h-[20px]' />
                  )}
                </button>
              </div>
            </div>
            <div className={`transition-all px-[15px] md:px-[48px] duration-300 overflow-hidden ${open[2] ? 'max-h-screen' : 'max-h-0'}`}>
              <textarea className='w-full h-[130px] md:h-[224px] border border-green-700 p-[18px] text-[10px] md:text-[19px] md:rounded-[15px] rounded-[8px]' placeholder='تفاصيل المحامي'></textarea>
              <div className='flex justify-center mt-[10px] md:mt-[35px] gap-[1rem] md:gap-[10rem] pb-[20px] md:pb-[48px]'>
                <button className='w-[305px] max-sm:text-[14px] h-[36px] md:h-[68px] bg-gradient-to-r from-[#2CB584] to-[#3C9977] border-none rounded-[8px] text-white flex justify-center items-center'>قبول </button>
                <button className='w-[305px] max-sm:text-[14px] h-[36px] md:h-[68px] border border-[#3A9E7A] rounded-[8px] flex justify-center items-center text-[#3A9E7A] gap-[0.8rem]'>
                  <span>تواصل</span>
                  <div>
                    <img src={Vector} alt='Vector' className='max-sm:w-[20px] max-sm:h-[20px]'/>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div key={3} className='w-full bg-white md:rounded-[20px] rounded-[10px] mb-[20px]'>
            <div className='w-full h-[133px] md:h-[250px] text-[18px] flex justify-center items-center'>
              <div className='w-full flex flex-row px-[15px] md:px-[48px] items-center'>
                <div className='w-[93%] h-full '>
                  <div className='h-[50%] flex justify-between items-center text-[10px] md:text-[19px]'>
                    <div className='md:w-[120px] w-[70px] flex flex-col md:gap-2'>
                      <span className='text-[#909090] text-center'>المحامي</span>
                      <span className='text-black text-center'>عبد الرحمن</span>
                      <div></div>
                    </div>
                    <div className='md:w-[180px] flex flex-col gap-1 md:gap-2 max-sm:w-[70px]'>
                      <div className='flex flex-col justify-center items-center'>
                        <span className='text-[#909090] text-center max-sm:gap-1'>التخصص</span>
                        <span className='text-black'>احوال شخصية</span>
                      </div>
                    </div>
                    <div className='flex flex-col gap-1 md:gap-2 items-center max-sm:w-[90px]'>
                      <span className='w-[40px] text-[#909090]'>السعر</span>
                      <div className='md:w-[88px] w-[41px] h-[16px] md:h-[36px] rounded-[38px] bg-[#224B37] text-white flex justify-center items-center'>50$</div>
                    </div>
                  </div>
                  <div className='h-[50%] flex justify-between items-center text-[10px] md:text-[19px] mt-[10px] md:mt-[20px]'>
                    <div className='md:w-[120px] flex flex-col md:gap-2 w-[70px]'>
                      <span className='text-[#909090] text-center'>تاريخ الرخصة</span>
                      <span className='text-black text-center'>2024/1/1</span>
                      <div></div>
                    </div>
                    <div className='flex flex-col gap-2 text-center'>
                      <div>
                        <span className='w-[60px] text-[#909090]'>التقييم</span>
                      </div>
                      <div className='md:w-[180px] flex flex-row justify-center items-center'>
                        <div className='flex flex-row'>
                          <img src={Star} alt='Star' className='max-sm:w-[15px] max-sm:h-[15px]' />
                          <img src={Star} alt='Star' className='max-sm:w-[15px] max-sm:h-[15px]'/>
                          <img src={Star} alt='Star' className='max-sm:w-[15px] max-sm:h-[15px]'/>
                          <img src={Star} alt='Star' className='max-sm:w-[15px] max-sm:h-[15px]'/>
                          <img src={Star} alt='Star' className='max-sm:w-[15px] max-sm:h-[15px]'/>
                        </div>
                        <span className='mr-[10px]'>10</span>
                        <div></div>
                      </div>
                    </div>
                    <div className='w-[80px] flex flex-col gap-2 items-center'>
                      <span className='text-[#909090] text-center'>المدينة</span>
                      <span className='text-black text-center'>الرياض</span>
                    </div>
                  </div>
                </div>
                <button
                  className={`w-[7%] h-full flex justify-end items-center`}
                  onClick={() => {
                    setOpen(values => ({ ...values, 3: values['3'] ? false : true }))
                  }}>
                  {open[3] ? (
                    <img src={ArrowDown} alt='ArrowDown' className='max-sm:w-[20px] max-sm:h-[12px]'/>
                  ) : (
                    <img src={Arrow2} alt='Arrow2' className='max-sm:w-[16px] max-sm:h-[20px]' />
                  )}
                </button>
              </div>
            </div>
            <div className={`transition-all px-[15px] md:px-[48px] duration-300 overflow-hidden ${open[3] ? 'max-h-screen' : 'max-h-0'}`}>
              <textarea className='w-full h-[130px] md:h-[224px] border border-green-700 p-[18px] text-[10px] md:text-[19px] md:rounded-[15px] rounded-[8px]' placeholder='تفاصيل المحامي'></textarea>
              <div className='flex justify-center mt-[10px] md:mt-[35px] gap-[1rem] md:gap-[10rem] pb-[20px] md:pb-[48px]'>
                <button className='w-[305px] max-sm:text-[14px] h-[36px] md:h-[68px] bg-gradient-to-r from-[#2CB584] to-[#3C9977] border-none rounded-[8px] text-white flex justify-center items-center'>قبول </button>
                <button className='w-[305px] max-sm:text-[14px] h-[36px] md:h-[68px] border border-[#3A9E7A] rounded-[8px] flex justify-center items-center text-[#3A9E7A] gap-[0.8rem]'>
                  <span>تواصل</span>
                  <div>
                    <img src={Vector} alt='Vector' className='max-sm:w-[20px] max-sm:h-[20px]'/>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default Lawyer
