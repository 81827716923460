import { React, useState } from 'react'
import Header from '../../component/layout/header'
import Footer from '../../component/layout/footer'
import { useNavbar } from '../../context/NavbarContext'
import Arrow1 from '../../assests/Icons/Arrow1.png'
import ArrowDown from '../../assests/Landing/allow-down.png'
const My_requests = () => {
  const [open, setOpen] = useState({})
  const { navbarShow, setNavbarShow } = useNavbar()
  return (
    <div className='h-[100%] overflow-x-hidden bg-[#f6f6f6] scrollbar'>
      <Header />
      {!navbarShow && (
        <>
          <div className='container md:px-0 mb-[80px]'>
            <div className='mt-[80px] md:mb-[75px] mb-[25px] max-sm:mt-[50px]'>
              <button className='flex justify-center items-center h-[25px] focus:text-green-700 max-sm:h-[18px] w-[85px] max-sm:w-[60px] text-[11px] bg-[#224B37] rounded-[20px] text-white mb-[10px]'>
                رجوع
              </button>
              <div className='text-[32px] max-md:text-[20px] md:text-[#232323] sm:text-[#000000]'>الخدمات&gt; قضاياي </div>
            </div>
            <div key={1} className={`rounded-[10px] overflow-hidden mb-[25px] ${open[1] ? 'gradient-border' : ''}`}>
              <div className='md:py-[40px] py-[15px] md:px-[25px] px-[15px] bg-white w-full rounded-[10px]' dir='ltr'> 
                <div className={`header-custom flex items-center justify-between ${open[1] ? '' : 'expanded'}`}>
                  <div className='flex items-center justify-between md:w-[350px] w-[120px]'>
                    <button
                      onClick={() => {
                        setOpen(values => ({
                          ...values,
                          1: values['1'] ? false : true
                        }))
                      }}>
                      <img src={Arrow1} alt='Arrow1' />
                    </button>
                    <div className='flex items-center max-sm:flex-col-reverse'>
                      <span className='bg-[#B08D0E] text-[#FFBA0A] rounded-full px-6 py-1 max-sm:text-[10px] md:mr-[25px] text-[20px]'>جديد</span>
                      <span className='text-[#939393] text-[18px] md:ml-2 max-sm:text-[10px] max-sm:mb-2'>الحالة</span>
                    </div>
                  </div>
                  <div className='flex items-center max-sm:flex-col'>
                    <span className='text-[18px] text-[#909090] md:mr-[25px] max-sm:text-[10px] max-sm:mb-2'>المحامي</span>
                    <span className='font-semibold text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px] max-sm:pt-[3px]'>عبد الرحمن</span>
                  </div>
                  <div className='flex items-center max-sm:flex-col'>
                    <span className='text-[#909090] text-[18px] md:mr-[25px] max-sm:text-[10px] max-sm:mb-2'>التفاصيل</span>
                    <span className='font-semibold text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px] max-sm:pt-[3px]'>استشارة</span>
                  </div>  
                </div>
                <div className={`content toggle-section ${open[1] ? 'expanded' : ''}`}>
                  <div className='flex relative items-center justify-between mb-4' dir='ltr'>
                    <div className='flex items-center justify-between w-[120px] md:w-[300px]'>
                      <button className='max-sm:w-[31px]'
                      onClick={() => {
                        setOpen(values => ({
                          ...values,
                          1: values['1'] ? false : true
                        }))
                      }}>
                      <img src={ArrowDown} alt='ArrowDown' />
                      </button>
                      <div className='flex flex-col items-center'>
                        <span className='text-[#939393] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>الحالة</span>
                        <span className='bg-[#B08D0E] text-[#FFBA0A] text-[18px] rounded-full px-6 py-1 max-sm:text-[10px]'>جديد</span>
                      </div>
                    </div>

                    <div className='flex flex-col md:text-center text-right w-[70px] md:w-[300px]'>
                      <span className='text-[#909090] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>المحامي</span>
                      <span className='font-semibold text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px]'>عبد الرحمن</span>
                    </div>

                    <div className='flex flex-col md:text-center text-right w-[70px] md:w-[300px]'>
                      <span className='text-[#868686] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>التفاصيل</span>
                      <span className='font-semibold text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px]'>استشارة</span>
                    </div>
                  </div>
                  <div className='text-[#868686] text-center text-[18px] md:mt-[60px] mt-[20px] max-sm:text-[13px] max-sm:text-black'>تفاصيل الإستشارة</div>
                  <div className='grid md:grid-cols-2 grid-cols-1 md:gap-[3rem] gap-[1rem] flex md:mt-[50px] mt-[20px]'>
                    <div className='md:text-center text-right'>
                      <span className='text-[#828282] text-[18px] max-sm:text-[12px]'>السؤال</span>
                        <textarea disabled={true} className='h-[96px] text-[18px] w-full pt-[10px] px-[15px] bg-[#f6f6f6] rounded-[13px] text-right mt-[10px] max-sm:text-[12px]' placeholder='تفاصيل السؤال'></textarea>
                    </div>
                    <div className='md:text-center text-right'>
                      <span className='text-[#828282] text-[18px] max-sm:text-[12px]'>الجواب</span>
                      <textarea disabled={true} className='h-[96px] w-full text-[18px] pt-[10px] px-[15px] bg-[#f6f6f6] rounded-[13px] text-right mt-[10px] max-sm:text-[12px]' placeholder='تفاصيل الجواب'></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div key={2} className={`rounded-[10px] overflow-hidden mb-[25px] ${open[2] ? 'gradient-border' : ''}`}>
              <div className='md:py-[40px] py-[15px] md:px-[25px] px-[15px] bg-white w-full rounded-[10px]' dir='ltr'> 
                <div className={`header-custom flex items-center justify-between ${open[2] ? '' : 'expanded'}`}>
                  <div className='flex items-center justify-between md:w-[350px] w-[120px]'>
                    <button
                      onClick={() => {
                        setOpen(values => ({
                          ...values,
                          2: values['2'] ? false : true
                        }))
                      }}>
                      <img src={Arrow1} alt='Arrow1' />
                    </button>
                    <div className='flex items-center max-sm:flex-col-reverse'>
                      <span className='bg-[#224B37] text-[#4EBE87] rounded-full px-6 py-1 max-sm:text-[10px] md:mr-[25px] text-[20px]'>جديد</span>
                      <span className='text-[#939393] text-[18px] md:ml-2 max-sm:text-[10px] max-sm:mb-2'>الحالة</span>
                    </div>
                  </div>
                  <div className='flex items-center max-sm:flex-col'>
                    <span className='text-[18px] text-[#909090] md:mr-[25px] max-sm:text-[10px] max-sm:mb-2'>المحامي</span>
                    <span className='font-semibold text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px] max-sm:pt-[3px]'>عبد الرحمن</span>
                  </div>
                  <div className='flex items-center max-sm:flex-col'>
                    <span className='text-[#909090] text-[18px] md:mr-[25px] max-sm:text-[10px] max-sm:mb-2'>التفاصيل</span>
                    <span className='font-semibold text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px] max-sm:pt-[3px]'>استشارة</span>
                  </div>  
                </div>
                <div className={`content toggle-section ${open[2] ? 'expanded' : ''}`}>
                  <div className='flex relative items-center justify-between mb-4' dir='ltr'>
                    <div className='flex items-center justify-between w-[120px] md:w-[300px]'>
                      <button className='max-sm:w-[31px]'
                      onClick={() => {
                        setOpen(values => ({
                          ...values,
                          2: values['2'] ? false : true
                        }))
                      }}>
                      <img src={ArrowDown} alt='ArrowDown' />
                      </button>
                      <div className='flex flex-col items-center'>
                        <span className='text-[#939393] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>الحالة</span>
                        <span className='bg-[#224B37] text-[#4EBE87] text-[18px] rounded-full px-6 py-1 max-sm:text-[10px]'>جديد</span>
                      </div>
                    </div>

                    <div className='flex flex-col md:text-center text-right w-[70px] md:w-[300px]'>
                      <span className='text-[#909090] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>المحامي</span>
                      <span className='font-semibold text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px]'>عبد الرحمن</span>
                    </div>

                    <div className='flex flex-col md:text-center text-right w-[70px] md:w-[300px]'>
                      <span className='text-[#868686] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>التفاصيل</span>
                      <span className='font-semibold text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px]'>استشارة</span>
                    </div>
                  </div>
                  <div className='text-[#868686] text-center text-[18px] md:mt-[60px] mt-[20px] max-sm:text-[13px] max-sm:text-black'>تفاصيل الإستشارة</div>
                  <div className='grid md:grid-cols-2 grid-cols-1 md:gap-[3rem] gap-[1rem] flex md:mt-[50px] mt-[20px]'>
                    <div className='md:text-center text-right'>
                      <span className='text-[#828282] text-[18px] max-sm:text-[12px]'>السؤال</span>
                        <textarea disabled={true} className='h-[96px] text-[18px] w-full pt-[10px] px-[15px] bg-[#f6f6f6] rounded-[13px] text-right mt-[10px] max-sm:text-[12px]' placeholder='تفاصيل السؤال'></textarea>
                    </div>
                    <div className='md:text-center text-right'>
                      <span className='text-[#828282] text-[18px] max-sm:text-[12px]'>الجواب</span>
                      <textarea disabled={true} className='h-[96px] w-full text-[18px] pt-[10px] px-[15px] bg-[#f6f6f6] rounded-[13px] text-right mt-[10px] max-sm:text-[12px]' placeholder='تفاصيل الجواب'></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div key={3} className={`rounded-[10px] overflow-hidden mb-[25px] ${open[3] ? 'gradient-border' : ''}`}>
              <div className='md:py-[40px] py-[15px] md:px-[25px] px-[15px] bg-white w-full rounded-[10px]' dir='ltr'> 
                <div className={`header-custom flex items-center justify-between ${open[3] ? '' : 'expanded'}`}>
                  <div className='flex items-center justify-between md:w-[350px] w-[120px]'>
                    <button
                      onClick={() => {
                        setOpen(values => ({
                          ...values,
                          3: values['3'] ? false : true
                        }))
                      }}>
                      <img src={Arrow1} alt='Arrow1' />
                    </button>
                    <div className='flex items-center max-sm:flex-col-reverse'>
                      <span className='bg-[#224B37] text-[#4EBE87] rounded-full px-6 py-1 max-sm:text-[10px] md:mr-[25px] text-[20px]'>جديد</span>
                      <span className='text-[#939393] text-[18px] md:ml-2 max-sm:text-[10px] max-sm:mb-2'>الحالة</span>
                    </div>
                  </div>
                  <div className='flex items-center max-sm:flex-col'>
                    <span className='text-[18px] text-[#909090] md:mr-[25px] max-sm:text-[10px] max-sm:mb-2'>المحامي</span>
                    <span className='font-semibold text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px] max-sm:pt-[3px]'>عبد الرحمن</span>
                  </div>
                  <div className='flex items-center max-sm:flex-col'>
                    <span className='text-[#909090] text-[18px] md:mr-[25px] max-sm:text-[10px] max-sm:mb-2'>التفاصيل</span>
                    <span className='font-semibold text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px] max-sm:pt-[3px]'>استشارة</span>
                  </div>  
                </div>
                <div className={`content toggle-section ${open[3] ? 'expanded' : ''}`}>
                  <div className='flex relative items-center justify-between mb-4' dir='ltr'>
                    <div className='flex items-center justify-between w-[120px] md:w-[300px]'>
                      <button className='max-sm:w-[31px]'
                      onClick={() => {
                        setOpen(values => ({
                          ...values,
                          3: values['3'] ? false : true
                        }))
                      }}>
                      <img src={ArrowDown} alt='ArrowDown' />
                      </button>
                      <div className='flex flex-col items-center'>
                        <span className='text-[#939393] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>الحالة</span>
                        <span className='bg-[#224B37] text-[#4EBE87] text-[18px] rounded-full px-6 py-1 max-sm:text-[10px]'>جديد</span>
                      </div>
                    </div>

                    <div className='flex flex-col md:text-center text-right w-[70px] md:w-[300px]'>
                      <span className='text-[#909090] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>المحامي</span>
                      <span className='font-semibold text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px]'>عبد الرحمن</span>
                    </div>

                    <div className='flex flex-col md:text-center text-right w-[70px] md:w-[300px]'>
                      <span className='text-[#868686] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>التفاصيل</span>
                      <span className='font-semibold text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px]'>استشارة</span>
                    </div>
                  </div>
                  <div className='text-[#868686] text-center text-[18px] md:mt-[60px] mt-[20px] max-sm:text-[13px] max-sm:text-black'>تفاصيل الإستشارة</div>
                  <div className='grid md:grid-cols-2 grid-cols-1 md:gap-[3rem] gap-[1rem] flex md:mt-[50px] mt-[20px]'>
                    <div className='md:text-center text-right'>
                      <span className='text-[#828282] text-[18px] max-sm:text-[12px]'>السؤال</span>
                        <textarea disabled={true} className='h-[96px] text-[18px] w-full pt-[10px] px-[15px] bg-[#f6f6f6] rounded-[13px] text-right mt-[10px] max-sm:text-[12px]' placeholder='تفاصيل السؤال'></textarea>
                    </div>
                    <div className='md:text-center text-right'>
                      <span className='text-[#828282] text-[18px] max-sm:text-[12px]'>الجواب</span>
                      <textarea disabled={true} className='h-[96px] w-full text-[18px] pt-[10px] px-[15px] bg-[#f6f6f6] rounded-[13px] text-right mt-[10px] max-sm:text-[12px]' placeholder='تفاصيل الجواب'></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div key={4} className={`rounded-[10px] overflow-hidden mb-[25px] ${open[4] ? 'gradient-border' : ''}`}>
              <div className='md:py-[40px] py-[15px] md:px-[25px] px-[15px] bg-white w-full rounded-[10px]' dir='ltr'> 
                <div className={`header-custom flex items-center justify-between ${open[4] ? '' : 'expanded'}`}>
                  <div className='flex items-center justify-between md:w-[350px] w-[120px]'>
                    <button
                      onClick={() => {
                        setOpen(values => ({
                          ...values,
                          4: values['4'] ? false : true
                        }))
                      }}>
                      <img src={Arrow1} alt='Arrow1' />
                    </button>
                    <div className='flex items-center max-sm:flex-col-reverse'>
                      <span className='bg-[#B08D0E] text-[#FFBA0A] rounded-full px-6 py-1 max-sm:text-[10px] md:mr-[25px] text-[20px]'>جديد</span>
                      <span className='text-[#939393] text-[18px] md:ml-2 max-sm:text-[10px] max-sm:mb-2'>الحالة</span>
                    </div>
                  </div>
                  <div className='flex items-center max-sm:flex-col'>
                    <span className='text-[18px] text-[#909090] md:mr-[25px] max-sm:text-[10px] max-sm:mb-2'>المحامي</span>
                    <span className='font-semibold text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px] max-sm:pt-[3px]'>عبد الرحمن</span>
                  </div>
                  <div className='flex items-center max-sm:flex-col'>
                    <span className='text-[#909090] text-[18px] md:mr-[25px] max-sm:text-[10px] max-sm:mb-2'>التفاصيل</span>
                    <span className='font-semibold text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px] max-sm:pt-[3px]'>استشارة</span>
                  </div>  
                </div>
                <div className={`content toggle-section ${open[4] ? 'expanded' : ''}`}>
                  <div className='flex relative items-center justify-between mb-4' dir='ltr'>
                    <div className='flex items-center justify-between w-[120px] md:w-[300px]'>
                      <button className='max-sm:w-[31px]'
                      onClick={() => {
                        setOpen(values => ({
                          ...values,
                          4: values['4'] ? false : true
                        }))
                      }}>
                      <img src={ArrowDown} alt='ArrowDown' />
                      </button>
                      <div className='flex flex-col items-center'>
                        <span className='text-[#939393] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>الحالة</span>
                        <span className='bg-[#B08D0E] text-[#FFBA0A] text-[18px] rounded-full px-6 py-1 max-sm:text-[10px]'>جديد</span>
                      </div>
                    </div>

                    <div className='flex flex-col md:text-center text-right w-[70px] md:w-[300px]'>
                      <span className='text-[#909090] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>المحامي</span>
                      <span className='font-semibold text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px]'>عبد الرحمن</span>
                    </div>

                    <div className='flex flex-col md:text-center text-right w-[70px] md:w-[300px]'>
                      <span className='text-[#868686] text-[18px] ml-2 md:mb-[30px] mb-2 max-sm:text-[10px]'>التفاصيل</span>
                      <span className='font-semibold text-[18px] text-black max-sm:text-[10px] max-sm:h-[23px]'>استشارة</span>
                    </div>
                  </div>
                  <div className='text-[#868686] text-center text-[18px] md:mt-[60px] mt-[20px] max-sm:text-[13px] max-sm:text-black'>تفاصيل الإستشارة</div>
                  <div className='grid md:grid-cols-2 grid-cols-1 md:gap-[3rem] gap-[1rem] flex md:mt-[50px] mt-[20px]'>
                    <div className='md:text-center text-right'>
                      <span className='text-[#828282] text-[18px] max-sm:text-[12px]'>السؤال</span>
                        <textarea disabled={true} className='h-[96px] text-[18px] w-full pt-[10px] px-[15px] bg-[#f6f6f6] rounded-[13px] text-right mt-[10px] max-sm:text-[12px]' placeholder='تفاصيل السؤال'></textarea>
                    </div>
                    <div className='md:text-center text-right'>
                      <span className='text-[#828282] text-[18px] max-sm:text-[12px]'>الجواب</span>
                      <textarea disabled={true} className='h-[96px] w-full text-[18px] pt-[10px] px-[15px] bg-[#f6f6f6] rounded-[13px] text-right mt-[10px] max-sm:text-[12px]' placeholder='تفاصيل الجواب'></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  )
}
export default My_requests
