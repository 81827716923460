import Logo from '../../assests/logo-white.svg'
import { React } from 'react'
import logo1 from '../../assests/Landing/logo1.png'
import facebook from '../../assests/Landing/facebook_mobile.png'
import twitter from '../../assests/Landing//TwitterX_mobile.png'
import likedin from '../../assests/Landing/likedin_mobile.png'

function Footer() {
  return (
    // <footer className='bg-[#224B37] w-full h-[354px] px-3'>
    //   <div className='mx-auto'></div>
    // </footer>

    <footer className='bg-[#224B37] relative text-white md:py-85 max-sm:py-0'>
      <div className='w-[1300px] max-sm:hidden mx-auto  grid grid-cols-1 md:grid-cols-4 gap-[4rem] '>
        {/* Logo and Navigation Links */}
        <div className='text-center md:text-left flex justify-center'>
          <div className='hidden md:block '>
            <a href='/' className='block text-[20px] mb-3'>
              الرئيسية
            </a>
            <a href='/about' className='block text-[20px] mb-3'>
              عن عدالة
            </a>
            <a href='/team' className='block text-[20px] mb-3'>
              الفريق
            </a>
            <a href='/news' className='block text-[20px] mb-3'>
              الأخبار
            </a>
          </div>
        </div>
        {/* Twitter  Facebook Instagram*/}
        <div className='text-center md:text-left h-[180px] flex flex-col justify-end'>
          <p className='text-[20px] mb-4'>Twitter Facebook Instagram</p>
        </div>

        {/* Contact Information */}
        <div className='text-center md:text-left'>
          <div className='text-[20px] mb-2' dir='rtl'>
            <span>810,</span>
            <span>Bay Area</span>
          </div>
          <p className='text-[20px] mb-8'>Jeddah, CA 94158</p>
          <p className='text-[20px] mb-2'>Email : Adala@gmail.com</p>
          <p className='text-[20px]'>Call: 1-100-000-0000</p>
        </div>
        {/* Subscription Form */}
        <div className='text-center md:text-left items-start '>
          <p className='mb-1 text-right text-[20px]'>اشترك في النشرة الإخبارية</p>
          <div className='flex flex-col space-y-2'>
            <input type='email' className='py-2 px-4 text-black text-left border focus:outline-none focus:border-green-600 text-[15px] h-[45px]' placeholder='Email Address' />
            <button className='bg-gray-400 bg-opacity-40 text-white py-2 px-4 h-[45px] shadow-md text-[15px] border'>Subscribe Now</button>
          </div>
        </div>
      </div>
      <div className='flex md:hidden justify-center items-center p-[10px]'>
        <img src={logo1} alt='logo1' className='w-[93px] h-[47px]'/>
      </div>
      <div className='mt-4 md:hidden text-center flex grid grid-cols-3 '>
        <a href='/' className='block mb-2 text-[13px]'>
          الرئيسية
        </a>
        <a href='/about' className='block mb-2 text-[13px]'>
          عن عدالة
        </a>
        <a href='/team' className='block mb-2 text-[13px]'>
          الفريق
        </a>
      </div>
      <div className='mt-2 md:hidden text-center flex flex-col '>
        <a href='/news' className='block mb-2 text-[13px]'>
          الأخبار
        </a>
      </div>
      <div className='md:hidden flex flex-row  gap-[3rem] justify-center pt-4 items-center' dir='ltr'>
        <button>
          <img src={twitter} alt='twitter' />
        </button>
        <button>
          <img src={facebook} alt='facebook' />
        </button>
        <button>
          <img src={likedin} alt='likedin' />
        </button>
      </div>
      <div className='mt-4 md:hidden text-center pb-[20px]' dir='ltr'>
        <p className='mt-4 text-[13px]'>2024© جميع الحقوق محفوظة</p>
      </div>
    </footer>
  )
}
export default Footer
