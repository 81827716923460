import { React, useState, useContext } from 'react'
import Logo from '../../../assests/onboarding/logo(1).svg'
import useWindowSize from '../../../component/windowssize'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Logo1 from '../../../assests/logo-white.svg'
import pic_Step1 from '../../../assests/onboarding/step1.webp'
import pic_Step2 from '../../../assests/onboarding/step2.webp'
import Svg_7 from '../../../assests/svgviewer7.png'
import { UserContext } from '../../../context/UserContext'
import PropTypes from 'prop-types'
import { userAccount } from '../../../Route/APIRoute'
import { ThreeCircles } from 'react-loader-spinner'

const Step5 = ({ setStep, secondAccount, setSecondAccount }) => {
  const [check, setCheck] = useState()
  const { setHasUserAccount } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const { width } = useWindowSize()
  const toastStyles = {
    success: {
      background: 'green'
    },
    error: {
      background: 'red'
    }
  }
  const handleValidation = () => {
    if (!check) {
      return false // Return false to indicate validation failure
    } else {
      return true // Return true to indicate validation success
    }
  }

  const handleNextButtonClick = async event => {
    event.preventDefault()
    const isValid = handleValidation()
    if (isValid) {
      // Proceed to next step if validation is successful
      setIsLoading(true)
      setTimeout(async () => {
        try {
          const response = await fetch(userAccount, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(secondAccount)
          })

          const data = await response.json()
          console.log('===datat=====', data)

          if (response.status === 200) {
            localStorage.setItem('UserName', data.User.name)
            setHasUserAccount(true)
          } else {
            console.log('error')
          }
        } catch (error) {
          console.log(error)
        } finally {
          setIsLoading(false)
          setStep(prev => prev + 1)
        }
      }, 3000)
    } else toast.warning('يرجى التحقق والضغط على العصا.')
  }

  return (
    <div className='flex flex-row'>
      <ToastContainer
        className='custom-toast-container'
        toastClassName='custom-toast'
        bodyClassName='custom-toast-body'
        closeButtonClassName='custom-toast-close-button'
        progressClassName='custom-toast-progress-bar'
        position={width >= 768 ? 'top-right' : 'top-center'}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isLoading ? (
        <div className='fixed inset-0 flex items-center justify-center'>
          <ThreeCircles visible={true} height='200' width='200' color='#25726a' ariaLabel='three-circles-loading' wrapperStyle={{}} wrapperClass='' />
        </div>
      ) : (
        <>
          {' '}
          <div className='w-full lg:w-1/2  '>
            <div className='container py-6 lg:py-8 text-center inline-block lg:hidden hidden md:block'>
              <img alt='logo' loading='lazy' width='100' height='100' decoding='async' data-nimg='1' className='inline-flex' src={pic_Step1} style={{ color: 'transparent' }} />
            </div>
            <div className='flex items-center justify-center px-4 lg:px-0 min-h-[80vh] lg:min-h-screen'>
              <div className='max-w-xl w-full flex  justify-center'>
                <div className='rounded-lg text-card-foreground w-full shadow-none border-0 bg-inherit'>
                  <div className='flex flex-col space-y-1.5 p-6'></div>
                  <div className='p-6 pt-0'>
                    <div className=' flex flex-col justify-center items-center w-full'>
                      <div className='h-80'>
                        <div className='dotlottie-container main  ' lang='en'>
                          <div
                            data-name='data'
                            role='figure'
                            className='animation '
                            style={{
                              position: 'relative',
                              width: '100%',
                              height: '100%',
                              backgroundColor: 'transparent'
                            }}>
                            <img src={Svg_7} className='w-[320px] h-[320px]'></img>
                          </div>
                        </div>
                      </div>
                      <p className=' text-primary text-lg md:text-xl lg:text-2xl text-center md:px-16'>ابدأ رحلتك مع عادل أول مساعد قانوني بالذكاء الاصطناعي..</p>
                      <form className='w-full'>
                        <div className='space-y-2 flex flex-row items-start gap-2 py-4 md:px-16'>
                          <input
                            aria-hidden='true'
                            type='checkbox'
                            value='on'
                            className='peer h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground my-2'
                            onChange={e => {
                              setCheck(e.target.value)
                            }}
                          />
                          <div className='space-y-1 leading-none'>
                            <label className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-color2' htmlFor=':r1e:-form-item'>
                              أوافق على الشروط و الأحكام
                            </label>
                            <p id=':r1e:-form-item-description' className='text-sm text-muted-foreground text-color2'>
                              <span className=''>نت توافق على</span>
                              <a className=' underline text-primary' href='/terms-and-conditions'>
                                شروط الخدمة وسياسة
                              </a>{' '}
                              و{' '}
                              <a className=' underline text-primary' href='/privacy-policy'>
                                سياسة الخصوصية
                              </a>
                              .
                            </p>
                          </div>
                        </div>
                        <div className='flex justify-center relative'>
                          <button
                            className='inline-flex items-center justify-center md:text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-teal-900 glow-teal h-12 px-10 py-4 md:py-6 rounded-md relative text-lg w-full md:w-4/5 mt-6 text-white'
                            type='button'
                            onClick={handleNextButtonClick}>
                            البدء
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className=' absolute left-5'>
                              <path d='m12 19-7-7 7-7'></path>
                              <path d='M19 12H5'></path>
                            </svg>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='hidden lg:flex lg:w-1/2 min-h-screen bg-accent p-12  flex-col items-center relative'>
            <div className=' '>
              <img alt='logo' loading='lazy' width='100' height='100' decoding='async' data-nimg='1' src={Logo1} style={{ color: 'transparent' }} />
            </div>
            <div className='flex flex-col gap-2 items-end  justify-center w-full h-4/5'>
              <div className=' relative  pl-[2vw]'>
                <img
                  alt='logo'
                  loading='lazy'
                  width='400'
                  height='400'
                  decoding='async'
                  data-nimg='1'
                  className='z-20 relative w-[25vw]'
                  src={pic_Step1}
                  style={{ color: 'transparent', visibility: 'visible' }}
                  data-xblocker='passed'
                />
                <img
                  alt='logo'
                  loading='lazy'
                  width='400'
                  height='400'
                  decoding='async'
                  data-nimg='1'
                  className='absolute top-[10vw] left-[18vw] z-10 w-[25vw]'
                  src={pic_Step2}
                  style={{ color: 'transparent', visibility: 'visible' }}
                  data-xblocker='passed'
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
Step5.propTypes = {
  setStep: PropTypes.func.isRequired,
  secondAccount: PropTypes.any.isRequired,
  setSecondAccount: PropTypes.func.isRequired
}
export default Step5
