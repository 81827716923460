import { React, useState, useContext } from 'react'
import Step0 from './individual/step0'
import Step1 from './individual/step1'
import Step2 from './individual/step2'
import Step3 from './individual/step3'
import Step4 from './individual/step4'
import Step5 from './individual/step5'
import Dashboard from '../../screens/dashboard/dashboard'
const Onboarding = () => {
  const [selectedAccountType, setSelectedAccountType] = useState('')
  const [step, setStep] = useState(1)
  const [selectedUsertype, setSelectedUsertype] = useState('')
  const userid = localStorage.getItem('Userid')
  const [secondAccount, setSecondAccount] = useState({
    selectedUsertype: '',
    email: '',
    nickname: '',
    name: '',
    userjob: '',
    userid: userid
  })
  const handleSelectAccountType = type => {
    setSelectedAccountType(type)
  }

  return (
    <>
      {step === 1 && <Step0 selectedAccountType={selectedAccountType} setSelectedAccountType={setSelectedAccountType} setStep={setStep} />}
      {step === 2 && (
        <Step1
          selectedUsertype={selectedUsertype}
          setSelectedUsertype={setSelectedUsertype}
          setStep={setStep}
          selectedAccountType={selectedAccountType}
          setSelectedAccountType={setSelectedAccountType}
          setSecondAccount={setSecondAccount}
        />
      )}
      {step === 3 && <Step2 setStep={setStep} setSecondAccount={setSecondAccount} />}
      {step === 4 && <Step3 setStep={setStep} setSecondAccount={setSecondAccount} />}
      {step === 5 && <Step4 setStep={setStep} setSecondAccount={setSecondAccount} />}
      {step === 6 && <Step5 setStep={setStep} secondAccount={secondAccount} setSecondAccount={setSecondAccount} />}
      {step === 7 && <Dashboard setStep={setStep} />}
    </>
  )
}
export default Onboarding
